import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentGateways, fetchPricings } from '../../api/pricings'
import { Modal, CircularProgress, makeStyles, Typography } from '@material-ui/core'

import { ALERT_TYPES, CHECKOUT_STEPS, USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import { setPlan, setVideoId } from '../../redux/modules/checkout'
import { Plans, Step, PaymentMethods, PaymentCheckout, Greetings } from './components'
import { fetchAndUpdateOrder } from '../../redux/modules/myContent'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import JazzCashErrors from '../../lib/jazzCashErrors.json'
import { showAlert } from '../../redux/modules/alertHandler'
import errorHandler from '../../lib/errorHandler'
import { useLocation, useHistory } from 'react-router-dom'

export const Checkout = ({
  videoId,
  closeCheckoutModal,
  checkoutStatus,
  checkoutSource,
  accessToken = false
}) => {
  const [step, setStep] = useState(CHECKOUT_STEPS.SELECT_PLAN)
  const { countryCode } = useSelector((state) => state.location)
  const [plans, setPlans] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const { promoCode } = useSelector((state) => state.checkout)
  const { login } = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (checkoutStatus === '000') setStep(CHECKOUT_STEPS.POST_CHECKOUT)
    else if (JazzCashErrors[checkoutStatus]) {
      if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('false')
      else {
        setStep(CHECKOUT_STEPS.SELECT_PLAN)
        dispatch(
          showAlert({
            type: ALERT_TYPES.ERROR,
            message: JazzCashErrors[checkoutStatus],
            isVisible: true
          })
        )
        history.replace(location.pathname)
      }
    }
  }, [checkoutStatus, history, location, dispatch, setStep])

  useEffect(() => {
    setPlansLoading(true)
    if (countryCode && videoId) {
      const filter = {
        video_id: {
          eq: videoId
        },
        country_code: {
          eq: countryCode
        },
        status: {
          eq: true
        }
      }
      ;(async () => {
        const response = await fetchPricings(filter, accessToken, login)
        setPlans(response)
        setPlansLoading(false)
      })()
    }
  }, [countryCode, videoId, accessToken, login, setPlansLoading, setPlans])

  useEffect(() => {
    dispatch(setVideoId(videoId))
  }, [videoId, dispatch])

  useEffect(() => {
    if (plans.length > 0) {
      let selectedPlan = plans[0]
      plans.forEach((item) => {
        if (item.price < selectedPlan.price) selectedPlan = item
      })
      dispatch(setPlan(selectedPlan))
      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.SELECT_PLAN, {
          video_id: videoId,
          country: selectedPlan.country,
          planId: selectedPlan.planId,
          planName: selectedPlan.planName,
          price: selectedPlan.price,
          currency: selectedPlan.currency,
          promocode: promoCode
        })
      )
    }
  }, [plans, videoId, promoCode, dispatch])

  useEffect(() => {
    if (countryCode) {
      setPaymentMethodsLoading(true)
      ;(async () => {
        const filter = {
          country_code: {
            eq: countryCode
          },
          disabled: {
            eq: false
          }
        }
        try {
          const {
            data: {
              listPaymentGateways: { items }
            }
          } = await fetchPaymentGateways(filter, accessToken)
          setPaymentMethods(items.length > 0 ? items : [])
          setPaymentMethodsLoading(false)
        } catch (err) {
          errorHandler.report(err)
          setPaymentMethodsLoading(false)
        }
      })()
    }
  }, [countryCode, accessToken, setPaymentMethodsLoading, setPaymentMethods])

  useEffect(() => {
    if (step === CHECKOUT_STEPS.POST_CHECKOUT && videoId) {
      dispatch(fetchAndUpdateOrder(videoId))
    }
  }, [step, videoId, dispatch])

  useEffect(() => {
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.START_CHECKOUT, { video_id: videoId, country: countryCode })
    )
  }, [videoId, countryCode, dispatch])

  const handleStep = (value) => setStep(value)

  switch (step) {
    case CHECKOUT_STEPS.SELECT_PLAN:
      return (
        <Step step={step} handleStep={handleStep} closeCheckoutModal={closeCheckoutModal}>
          <Plans
            loading={plansLoading}
            plans={plans}
            handleStep={handleStep}
            videoId={videoId}
            login={login}
            accessToken={accessToken}
          />
        </Step>
      )
    case CHECKOUT_STEPS.SELECT_PAYMENT_METHOD:
      return (
        <Step step={step} handleStep={handleStep} closeCheckoutModal={closeCheckoutModal}>
          <PaymentMethods
            setProcessing={setProcessing}
            loading={paymentMethodsLoading}
            paymentMethods={paymentMethods}
            handleStep={handleStep}
            videoId={videoId}
          />
          <ProcessingModal processing={processing} />
        </Step>
      )
    case CHECKOUT_STEPS.CHECKOUT:
      return (
        <Step step={step} handleStep={handleStep} closeCheckoutModal={closeCheckoutModal}>
          <PaymentCheckout
            setProcessing={setProcessing}
            handleStep={handleStep}
            plans={plans}
            videoId={videoId}
            accessToken={accessToken}
          />
          <ProcessingModal
            processing={processing}
            message="Processing. Please DO NOT close window..."
          />
        </Step>
      )
    case CHECKOUT_STEPS.POST_CHECKOUT:
      return (
        <Step step={step} handleStep={handleStep} closeCheckoutModal={closeCheckoutModal}>
          <Greetings
            videoId={videoId}
            closeCheckoutModal={closeCheckoutModal}
            checkoutSource={checkoutSource}
          />
        </Step>
      )
    default:
      return null
  }
}

const ProcessingModal = ({ processing, message = 'Loading. Please wait...' }) => {
  const classes = useStyles()
  return (
    <Modal
      open={processing}
      className={classes.modalContainer}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.loadingContainer}>
        <CircularProgress style={{ color: 'white' }} />
        <Typography style={{ marginTop: '10px' }}>{message}</Typography>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex'
  },
  loadingContainer: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexDirection: 'column'
  }
}))
