import React, { useEffect, useState } from 'react'
import { Grid, LinearProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { isMobileOnly } from 'react-device-detect'
import Countdown from 'react-countdown'
import { useDispatch } from 'react-redux'

import { Button } from '../../../components/Button'
import {
  useContentViewBoxStyles,
  useExpirationDetailStyles,
  useReleaseDetailStyles
} from './styles'
import { playTrailer } from '../../../redux/modules/trailerPlayer'
import { playVideo } from '../../../redux/modules/videoPlayer'
import { setWatchOnMobile } from '../../../redux/modules/globalModals'
import clsx from 'clsx'
import { color } from '../../../styles/color'

export const ContentViewBox = ({
  video,
  expired,
  expireAt,
  released,
  started,
  id: orderId,
  last_timestamp,
  video_plan
}) => {
  const { id: videoId, main_title, sub_title, thumbnail_sm, release_date, duration } = video
  const [deviceNotSupported, setDeviceNotSupported] = useState(null)
  const classes = useContentViewBoxStyles()

  const history = useHistory()
  function handlePreview() {
    history.push(`/preview/${videoId}`)
  }

  useEffect(() => {
    if (video_plan) {
      const {
        plan: {
          device_types: { items }
        }
      } = video_plan

      const filtered = items.filter(
        (item) => item.device_types.id === process.env.REACT_APP_DEVICE_TYPE_ID
      )
      if (filtered.length > 0) setDeviceNotSupported(false)
      else setDeviceNotSupported(true)
    } else setDeviceNotSupported(false)
  }, [video_plan, setDeviceNotSupported])

  const watchedPercentage = (last_timestamp / (duration / 1000)) * 100
  return (
    <div
      key={videoId}
      className={clsx(
        classes.movieBannerContainer,
        expired && released ? classes.expiredBorder : classes.simpleBorder
      )}
      style={{
        backgroundImage: ` 
        linear-gradient(to top, 
          ${color.primaryColor},
          ${color.primaryColor} ,
          ${color.primaryColor} ,
          ${color.primaryColordock} ,
          transparent, transparent),
            url(${thumbnail_sm})`
      }}>
      <Grid item md={12} className={classes.leftcontainer} direction="column">
        <Tags deviceNotSupported={deviceNotSupported} released={released} expired={expired} />
        <Typography className={classes.mainTitle}>{main_title}</Typography>
        <Typography className={classes.subTitle}>{sub_title}</Typography>

        <div style={{ padding: '6px 0px' }}>
          {started && last_timestamp && watchedPercentage > 0 && watchedPercentage < 99 && (
            <LinearProgress
              color="primary"
              variant="determinate"
              value={watchedPercentage}
              className={classes.progressBar}
            />
          )}
        </div>

        <>
          <PlayButton
            started={started}
            expired={expired}
            released={released}
            video={video}
            orderId={orderId}
            last_timestamp={last_timestamp}
            duration={duration}
            deviceNotSupported={deviceNotSupported}
          />

          <Button title="Open" onClick={handlePreview} className={classes.previewButton} hover />
        </>

        <div className={classes.expiryReleaseContainer}>
          {released ? (
            <ExpirationDetail expired={expired} expireAt={expireAt} />
          ) : (
            <ReleaseDetail release_date={release_date} />
          )}
        </div>
      </Grid>
    </div>
  )
}

const ReleaseDetail = ({ release_date }) => {
  const classes = useReleaseDetailStyles()

  const renderer = ({ days, hours, minutes, _, completed }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Typography className={classes.releaseDate}>
          {days > 0 ? `${days} D  :  ${hours} H  :  ${minutes} M` : `${hours} H  :  ${minutes} M`}
        </Typography>
      )
    }
  }
  return (
    <div className={classes.mainContainer}>
      <Countdown date={new Date(release_date)} renderer={renderer} />
    </div>
  )
}

const ExpirationDetail = ({ expired, expireAt }) => {
  const classes = useExpirationDetailStyles()

  const renderer = ({ days, hours, minutes, _, completed }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Typography className={classes.expirationDate}>
          {days > 0 ? `${days}d : ${hours}hr : ${minutes}m` : `${hours}hr : ${minutes}m`}
        </Typography>
      )
    }
  }

  return (
    <div className={classes.expiryTagContainer}>
      {expired ? (
        <Typography className={classes.movieexpired}>
          You no longer have access to this content
        </Typography>
      ) : (
        <Typography className={classes.movieexpires}>
          <span className={classes.expiresInText}>Expires in </span>
          <Countdown date={new Date(expireAt)} renderer={renderer} />
        </Typography>
      )}
    </div>
  )
}

const PlayButton = ({
  started,
  expired,
  released,
  video,
  orderId,
  last_timestamp,
  duration,
  deviceNotSupported
}) => {
  const { trailers } = video
  const classes = useContentViewBoxStyles()
  const dispatch = useDispatch()

  const handlePlayTrailer = () => {
    if (!trailers || !trailers.items || trailers.items.length === 0) return

    const filteredTrailers = trailers.items.filter((item) => item.is_main)
    const payload = {
      isPlayerModalVisible: true,
      trailer: filteredTrailers.length > 0 ? filteredTrailers[0] : trailers.items[0]
    }
    dispatch(playTrailer(payload))
  }

  const handlePlayVideo = () => {
    if (isMobileOnly) {
      dispatch(setWatchOnMobile(true))
      return
    }
    const payload = {
      isPlayerModalVisible: true,
      video,
      orderId
    }
    dispatch(playVideo(payload))
  }

  const watchedPercentage = (last_timestamp / (duration / 1000)) * 100

  if (!released || expired)
    return (
      <Button
        title="Play Trailer"
        onClick={handlePlayTrailer}
        className={classes.playButton}
        hover
      />
    )
  if (started && watchedPercentage > 0 && watchedPercentage < 99)
    return (
      <Button
        title="Resume"
        onClick={handlePlayVideo}
        className={classes.resumebutton}
        disabled={deviceNotSupported}
        hover
      />
    )

  return (
    <Button
      title="Play"
      role="presentation"
      onClick={handlePlayVideo}
      className={classes.playButton}
      disabled={deviceNotSupported}
      hover
    />
  )
}

const Tags = ({ deviceNotSupported, released, expired }) => {
  const classes = useContentViewBoxStyles()
  return (
    <>
      {deviceNotSupported && released && (
        <div className={classes.tag}>
          <Typography className={clsx(classes.tagText, classes.notSupported)}>
            Not Supported
          </Typography>
        </div>
      )}

      {expired && released && (
        <div className={classes.tag}>
          <Typography className={clsx(classes.tagText, classes.expired)}>Expired</Typography>
        </div>
      )}

      {!released && (
        <div className={classes.tag}>
          <Typography className={clsx(classes.tagText, classes.comingSoon)}>Coming Soon</Typography>
        </div>
      )}
    </>
  )
}
