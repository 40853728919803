import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../styles/color'

export const usePlansListStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      height: '85vh'
    }
  },
  plansContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '0px 30px'
  },
  promoCodeText: {
    color: 'white',
    fontSize: 17,
    fontWeight: '500',
    fontFamily: 'Rubik',
    cursor: 'pointer'
  },
  promoCodeApplied: {
    color: color.green,
    cursor: 'default'
  },
  promoCodeTextContainer: {
    padding: '20px 0px 20px 0px',
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    padding: '0px 20px 20px 20px',
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    color: 'white',
    width: 240,
    fontWeight: '600',
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: 'white'
    }
  }
}))

export const usePlanItemStyles = makeStyles(() => ({
  mainContainer: {
    paddingLeft: 15,
    paddingRight: 15
  }
}))

export const useMobileViewPlanItemStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    flex: 1,
    width: '100%'
  },
  planContainer: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '5px',
    marginBottom: 20,
    cursor: 'pointer'
  },
  unSelectedPlanBorder: {
    backgroundColor: color.unSelectedPlanColor
  },
  selectedPlanBorder: {
    border: `1px solid ${color.buttonBlue}`,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  closedPlan: {
    height: '35px',
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  closedPlanName: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    fontWeight: '500',
    color: color.white
  },
  withoutCouponText: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: '500',
    color: color.white
  },
  couponAppliedPrice: {
    padding: '0px 10px 0px 10px',
    textDecoration: 'line-through'
  },
  mobilePlanName: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    height: '35px',
    backgroundColor: color.buttonBlue
  },
  planNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    color: color.white,
    fontWeight: '600'
  },
  planNameContainerSelected: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.white,
    fontWeight: '600',
    height: '50px'
  },
  mobilePriceContainer: {
    fontWeight: '700',
    fontSize: '25px',
    color: color.white,
    textAlign: 'center',
    margin: '10px 0px 10px 0px'
  },
  mobilePriceWithPromoContainer: {
    fontWeight: '500',
    fontSize: '20px',
    textAlign: 'center',
    margin: '10px 0px 10px 0px',
    textDecoration: 'line-through',
    color: color.white
  },
  mobileQualityContainerSelected: {
    color: '#ffffff',
    fontWeight: '600',
    fontSize: '19px',
    textAlign: 'center',
    padding: '10px 0px 10px 0px'
  },
  watchOnDevices: {
    color: '#ffffff',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    padding: '10px 0px 10px 0px'
  },
  allowDownloadText: {
    fontWeight: '500',
    fontSize: '12px',
    paddingRight: '10px',
    color: color.grey
  },
  rentalTerms: {
    fontWeight: '600',
    fontSize: '10px',
    paddingTop: '5px',
    color: color.white,
    textTransform: 'uppercase',
    textDecoration: 'underline'
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkIcon: {
    color: color.green,
    fontSize: 14
  },
  closeIcon: {
    color: color.red,
    fontSize: 14
  },
  paddingBottom: {
    paddingBottom: 15
  }
}))

export const useDesktopViewPlanItemStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    flex: 1,
    width: '260px',
    marginBottom: 20
  },
  planContainer: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '10px',
    margin: '0px 30px 0px 0px',
    cursor: 'pointer',
    border: `1px solid ${color.grey}`
  },
  selectedBorder: {
    border: `1px solid ${color.buttonBlue}`
  },
  planNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    color: '#ffffff',
    fontWeight: '600',
    textTransform: 'uppercase',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px'
  },
  planNameSelected: {
    backgroundColor: color.buttonBlue
  },
  priceContainer: {
    fontWeight: '600',
    fontSize: '25px',
    color: '#ffffff',
    textAlign: 'center',
    margin: '20px 0px 10px 0px'
  },
  priceContainerWithPromo: {
    textDecoration: 'line-through',
    fontSize: '18px',
    color: '#ffffff',
    textAlign: 'center',
    margin: '20px 0px 10px 0px',
    fontWeight: '600'
  },
  planDetailContainer: {
    padding: 20
  },
  qualityContainer: {
    fontWeight: '500',
    fontSize: '17px',
    textAlign: 'center',
    padding: '20px 0px 10px 0px',
    color: color.grey
  },
  allowDownloadText: {
    fontWeight: '500',
    fontSize: '12px',
    paddingRight: '10px',
    color: color.grey
  },
  rentalTerms: {
    fontWeight: '600',
    fontSize: '10px',
    paddingTop: '5px',
    color: color.white,
    textTransform: 'uppercase',
    textDecoration: 'underline'
  },
  qualityContainerSelected: {
    color: color.white
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkIcon: {
    color: color.green,
    fontSize: 14
  },
  closeIcon: {
    color: color.red,
    fontSize: 14
  }
}))

export const usePromoCodeModalStyles = makeStyles(() => ({
  modalMainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(30px)'
  },
  couponContainer: {
    outline: 'none',
    backgroundColor: color.primaryColor,
    width: '550px',
    padding: '50px 0px 50px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '5px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: color.white,
    fontSize: '17px',
    fontWeight: '600',
    paddingBottom: '10px'
  },
  input: {
    color: color.white,
    border: 'none',
    borderRadius: 4,
    height: 36,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: '0.8em'
  },
  textFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    width: '250px',
    flexDirection: 'column'
  },
  errorMessage: {
    color: color.red,
    fontSize: '13px',
    paddingTop: '10px'
  },
  applyButton: {
    width: '100%',
    color: 'white',
    backgroundColor: color.buttonBlue,
    '&:hover': {
      color: 'white',
      backgroundColor: color.buttonBlue
    }
  }
}))
