import { makeStyles } from '@material-ui/core'
import { color } from '../../../../styles/color'

export const verificationStyles = makeStyles((theme) => ({
  formItemContainer: {
    padding: '0px 5px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    color: color.white,
    border: `1px solid ${color.white}`,
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: '0.8em'
  },
  initialFormContainer: {
    justifyContent: 'center',
    display: 'flex'
  },

  verificationCodeContainer: {
    fontSize: 14,
    Color: 'white',
    padding: '15px 5px',
    textAlign: 'right',
    width: '100%'
  },
  resendText: {
    cursor: 'pointer',
    '&:active': {
      color: 'lightgray'
    }
  },
  buttonsContainer: {
    marginTop: 30,
    width: '100%'
  }
}))
