import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { CHECKOUT_STEPS } from '../../../../lib/constant'
import { Header } from '../header'
import { useStyles } from './styles'

export const Step = ({ children, step, handleStep, closeCheckoutModal }) => {
  const classes = useStyles()
  const [title, setTitle] = useState('')
  const { paymentGateway } = useSelector((state) => state.checkout)

  useEffect(() => {
    if (step === CHECKOUT_STEPS.SELECT_PLAN) setTitle('Choose a plan')
    else if (step === CHECKOUT_STEPS.SELECT_PAYMENT_METHOD) setTitle('Select Payment method')
    else if (step === CHECKOUT_STEPS.CHECKOUT && paymentGateway) {
      if (paymentGateway.name === 'STRIPE') setTitle('Pay with Card')
      else if (paymentGateway.name === 'PAYPAL') setTitle('Pay with PayPal')
      else if (paymentGateway.name === 'EASY_PAISA') setTitle('Pay with EasyPaisa')
      else if (paymentGateway.name === 'JAZZ_CASH') setTitle('Pay with JazzCash')
      else if (paymentGateway.name === 'CRYPTO') setTitle('Pay with Crypto')
      else setTitle('')
    }
  }, [step, paymentGateway, setTitle])

  return (
    <div className={classes.mainContainer}>
      <Header
        title={title}
        step={step}
        handleStep={handleStep}
        closeCheckoutModal={closeCheckoutModal}
      />
      {children}
    </div>
  )
}
