import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { useLoadingStyles } from './styles'

export const Loading = () => {
  const classes = useLoadingStyles()
  return (
    <div className={classes.loadingMainContainer}>
      <CircularProgress className={classes.loader} size={30} />
    </div>
  )
}
