import React from 'react'
import moment from 'moment'
import { useMediaPredicate } from 'react-media-hook'
import clsx from 'clsx'

import { TRANSACTION_HISTORY_SOLUMNS } from '../../../../../lib/constant'
import { useStyles } from './styles'

export const TransactionTable = ({ transactions }) => {
  const classes = useStyles()
  const lessThan400 = useMediaPredicate('(max-width: 400px)')

  const getRef = (value) =>
    `${value.substring(0, 3)} ... ${value[value.length - 3]}${value[value.length - 2]}${
      value[value.length - 1]
    }`
  return (
    <div className={classes.historyTableContainer}>
      <table className={classes.historyTable}>
        {TRANSACTION_HISTORY_SOLUMNS.map((column) => (
          <th
            key={column.id}
            className={
              column.name === 'Total'
                ? clsx(classes.tableHeader, classes.total)
                : classes.tableHeader
            }>
            {column.name}
          </th>
        ))}
        {transactions.map(
          ({
            ref_no,
            is_gift,
            payment_total,
            country: { currency },
            purchased_at,
            video: { title },
            video_plan: {
              plan: { title: planTitle }
            },
            payment_gateway: { name },
            additional_attributes
          }) => {
            const friendDetail = is_gift ? JSON.parse(additional_attributes).friendDetail : {}
            return (
              <tr className={classes.tableRow}>
                <td className={classes.tableColumn}>{lessThan400 ? getRef(ref_no) : ref_no}</td>
                <td className={classes.tableColumn}>{title}</td>
                <td className={classes.tableColumn}>{is_gift ? friendDetail.name : 'Self'}</td>
                <td className={classes.tableColumn}>{name}</td>
                <td className={clsx(classes.tableColumn, classes.uppercase)}>{planTitle}</td>
                <td className={classes.tableColumn}>{moment(purchased_at).format('L')}</td>
                <td className={clsx(classes.tableColumn, classes.total)}>
                  {currency} {payment_total}
                </td>
              </tr>
            )
          }
        )}
      </table>
    </div>
  )
}
