import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { color } from '../../../styles/color'

export const Tabs = ({ setSelectedTab, selectedTab }) => {
  const classes = useStyles()
  return (
    <div className={classes.tabContainer}>
      <div className={classes.mainOuterContainer}>
        <TabButton id={0} name="Active" setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
        <div className={classes.verticalDivider} />
        <TabButton
          id={1}
          name="Expired"
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
        <div className={classes.verticalDivider} />
        <TabButton id={2} name="All" setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      </div>
    </div>
  )
}

const TabButton = ({ name, id, setSelectedTab, selectedTab }) => {
  const classes = useStyles()

  const handleClick = () => setSelectedTab(id)
  return (
    <div
      key={id}
      role="presentation"
      onClick={handleClick}
      className={id === selectedTab ? classes.selectedTab : classes.tab}>
      <Typography className={classes.tabText}>{name}</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '40px 0px 20px 0px'
  },
  mainOuterContainer: {
    border: `1px solid ${color.buttonBlue}`,
    borderRadius: 5,
    height: 40,
    width: 350,
    display: 'flex',
    padding: 5,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  selectedTab: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#26299C',
    borderRadius: 5,
    cursor: 'pointer'
  },
  tabText: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  verticalDivider: {
    border: '1px solid #26299C',
    margin: 5
  },
  tab: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))
