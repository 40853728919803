import { API } from 'aws-amplify'
import { getToken } from '../redux/modules/user'

export const updateRatings = async (body, dispatch) => {
  const authToken = await dispatch(getToken())

  const params = {
    body,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('rateVideo', '/rate-video', params)
}
