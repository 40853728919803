import { makeStyles } from '@material-ui/styles'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  button: {
    padding: '4px 4vh 0px 4vh',
    margin: '13px 0px 0px 0px',
    color: 'white',
    float: 'left',
    textTransform: 'uppercase',
    fontSize: 13,
    cursor: 'pointer',
    '&:active': {
      color: 'lightgray'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: '4px 5px 0px 5px'
    }
  },
  footerContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backdropFilter: 'blur(30px)',
    background: 'rgba(0, 0, 0, 0.2)',
    flexDirection: 'column'
  },
  footerTabs: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flxe'
  },
  footer: {
    backgroundColor: color.backgroundPurple
  },
  copyrightText: {
    color: 'gray',
    fontSize: 14,
    fontWeight: '300',
    padding: 10
  },
  brandingText: {
    color: 'gray',
    fontSize: 14,
    fontWeight: '300',
    paddingTop: 5
  },
  brandingLink: {
    color: '#fed136',
    fontWeight: '600'
  },
  storeButtonContainer: {
    padding: '0px 5px'
  },
  storeButtonInnerContainer: {
    display: 'flex'
  }
}))
