import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useStyles } from './styles'
import { AvailableDevices, FAQ, WatchingWays, WatchSteps } from './components'
import { Footer, HelmetMetaData } from '../../components'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import { USER_ACTIVITY_ACTIONS } from '../../lib/constant'

export const HowToWatch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: 'how to watch', path: '/how-to-watch' })
    )
  }, [dispatch])

  return (
    <>
      <HelmetMetaData
        title={'Mintrio - How to watch'}
        description={'How to watch on mintrio'}
        hashtag={'#howtowatch'}
      />
      <div className={classes.mainContainer}>
        <div className={classes.containerContent}>
          <div className={classes.gridContainer}>
            <Grid item xs={12} md={7} xl={7} lg={7}>
              <WatchSteps />
              <WatchingWays />
              <AvailableDevices />
              <FAQ />
            </Grid>
            <div className={classes.footerContainer}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
