import { API } from 'aws-amplify'

import { detectDeviceType } from '../../lib/utils'
import { listDeviceTypes } from '../../graphql/queries'
import errorHandler from '../../lib/errorHandler'

// constants
const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE'

// actions
export const setDeviceType = (payload) => ({
  type: SET_DEVICE_TYPE,
  payload
})

export const getDeviceDetail = () => async (dispatch) => {
  const deviceTitle = detectDeviceType()
  try {
    const {
      data: {
        listDeviceTypes: { items: deviceTypes }
      }
    } = await API.graphql({
      query: listDeviceTypes,
      variables: {
        filter: {
          and: [{ title: { eq: deviceTitle } }, { status: { eq: true } }]
        }
      }
    })

    if (deviceTypes.length > 0) {
      dispatch(setDeviceType(deviceTypes[0]))
    }
  } catch (err) {
    errorHandler.report(err)
  }
}

// thunks

// reducer
const initialState = {
  id: '00000'
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_DEVICE_TYPE:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

export default reducer
