import { API } from 'aws-amplify'
import moment from 'moment'

import { getToken } from '../redux/modules/user'
import { getOrdersByUser } from '../graphql/queries'

export const orderDetail = async (userId, videoId, countryCode, dispatch) => {
  try {
    const authToken = await dispatch(getToken())
    let body = {
      userId,
      countryCode
    }
    if (videoId) body['videoId'] = videoId

    const params = {
      body,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    return await API.post('order', '/items', params)
  } catch (err) {
    throw err
  }
}

export const getSignedUrl = async (videoId, countryCode, userId, deviceTypeId, dispatch) => {
  const authToken = await dispatch(getToken())

  const params = {
    body: {
      userId,
      videoId,
      countryCode,
      deviceTypeId
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('getSignedUrl', '/get-signed-url', params)
}

export const fetchTransactions = async (userId) => {
  try {
    const {
      data: { getOrdersByUser: orders }
    } = await API.graphql({
      query: getOrdersByUser,
      variables: { user_id: userId }
    })

    let items = orders.items

    if (items)
      items.sort((first, second) =>
        moment(first.purchased_at).isBefore(second.purchased_at) ? 1 : -1
      )

    return items || []
  } catch (err) {
    throw err
  }
}
