import React, { useEffect, useState } from 'react'
import { Typography, LinearProgress } from '@material-ui/core'
import { Brightness1 as Brightness1Icon } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Button } from '../../../components/Button'
import { movieDetailStyles, useGenreListStyles } from './styles'
import { playTrailer } from '../../../redux/modules/trailerPlayer'
import { playVideo } from '../../../redux/modules/videoPlayer'
import { CountDownTimer } from './CountDownTimer'
import { RatingButtons } from './ratingButtons'
import { isMobile } from 'react-device-detect'
import { setWatchOnMobile } from '../../../redux/modules/globalModals'
import { logo } from '../../../assets'
import { ShareMenu } from './shareMenu'
import { EVENT_CATEGORIES } from '../../../lib/constant'
import { WatchOptions } from './'
import { fetchPricingForBanner } from '../../../api/pricings'

export const MovieDetail = ({
  item,
  orderDetail,
  imageUrl,
  vidId,
  openCheckoutModal,
  accessToken = false
}) => {
  const classes = movieDetailStyles()
  const [deviceNotSupported, setDeviceNotSupported] = useState(null)
  const [duration_mins, setDurationMins] = useState('')
  const [prices, setPrices] = useState([])
  const [minPrice, setMinPrice] = useState('')
  const [contains, setContains] = useState(false)

  const { login } = useSelector((state) => state.user)

  const {
    id: videoId,
    main_title,
    sub_title,
    title,
    long_description,
    release_date,
    language,
    genres: { items: genreList },
    audio_tracks,
    supported_captions,
    trailers
  } = item

  const year = moment(release_date).format('YYYY')
  //let contains = trailers?.items?.some((e) => e.type === 'CONTENT_PREVIEW')
  const { countryCode } = useSelector((state) => state.location)

  useEffect(() => {
    if (countryCode && videoId) {
      const filter = {
        video_id: {
          eq: videoId
        },
        country_code: {
          eq: countryCode
        },
        status: {
          eq: true
        }
      }
      ;(async () => {
        const response = await fetchPricingForBanner(filter, accessToken, login)

        setPrices(response)
      })()
    }
  }, [countryCode, videoId, login, setPrices, accessToken])

  useEffect(() => {
    if (prices.length) {
      let min = Math.min.apply(
        Math,
        prices.map(function (o) {
          return o.price
        })
      )
      setMinPrice(min)
      setContains(trailers?.items?.some((e) => e.type === 'CONTENT_PREVIEW'))
      let objectPreview = trailers?.items?.find((e) => e.type === 'CONTENT_PREVIEW')
      if (objectPreview) {
        let duration_object = JSON.parse(objectPreview?.additional_attributes)['duration']
        let duration_min =
          parseInt(duration_object['hours']) * 60 +
          parseInt(duration_object['minutes']) +
          Math.ceil(parseInt(duration_object['seconds']) / 60)
        setDurationMins(duration_min)
      }
    }
  }, [prices, trailers, setContains, setDurationMins])

  const isPurchased = orderDetail
    ? orderDetail.purchased && !orderDetail.expired
      ? true
      : false
    : false

  useEffect(() => {
    if (orderDetail && orderDetail.video_plan) {
      const {
        video_plan: {
          plan: {
            device_types: { items }
          }
        }
      } = orderDetail

      const filtered = items.filter(
        (item) => item.device_types.id === process.env.REACT_APP_DEVICE_TYPE_ID
      )
      if (filtered.length > 0) setDeviceNotSupported(false)
      else setDeviceNotSupported(true)
    } else setDeviceNotSupported(false)
  }, [orderDetail, setDeviceNotSupported])

  const notReleased = moment().isBefore(release_date)
  const showCountDown =
    notReleased || (orderDetail && orderDetail.purchased && !orderDetail.expired)
  const showRatingButtons = login && orderDetail && orderDetail.purchased && !orderDetail.expired

  return (
    <div className={classes.mainContainer}>
      <div className={classes.bannerMessageContainer}>
        {contains && !isPurchased && (
          <Typography className={classes.banner}>
            {login ? '' : 'sign in to'}{' '}
            {minPrice === 0 ? 'Watch for Free' : `watch ${duration_mins} mins free`}
          </Typography>
        )}
      </div>
      <Typography className={classes.mainTitle}>{main_title}</Typography>
      <Typography className={classes.subTitle}>{sub_title}</Typography>
      <GenreList genreList={genreList} />
      <Typography className={classes.longDescription}>{long_description}</Typography>
      {supported_captions?.items?.length > 0 && (
        <Typography className={classes.captionAudio}>
          Supported Captions:{' '}
          {supported_captions?.items?.map((aud, i) => (
            <span className={classes.languageSpan} key={i}>
              {aud?.language}
              {supported_captions?.items?.length !== i + 1 ? `, ` : ''}
            </span>
          ))}
        </Typography>
      )}
      <Typography className={classes.captionAudio}>
        Supported Audios:{' '}
        {audio_tracks?.items?.map((aud, i) => (
          <span className={classes.languageSpan} key={i}>
            {aud?.language}
            {audio_tracks?.items?.length !== i + 1 ? `, ` : ''}
          </span>
        ))}
        {!audio_tracks?.items?.length > 0 && (
          <span className={classes.languageSpan}>{language}</span>
        )}
      </Typography>
      <div className={classes.movieButtonsOutercontainer}>
        <div className={classes.movieButtonsTagsContainer}>
          <PlayResumeButtons
            video={item}
            orderDetail={orderDetail}
            deviceNotSupported={deviceNotSupported}
            vidId={vidId}
            openCheckoutModal={openCheckoutModal}
          />
          {showRatingButtons && <RatingButtons orderDetail={orderDetail} videoId={videoId} />}
          <MovieDetailFooter year={year} video={item} />
          {showCountDown && (
            <CountDownTimer notReleased={notReleased} video={item} orderDetail={orderDetail} />
          )}
        </div>
      </div>
      {deviceNotSupported && (
        <div className={classes.deviceNotSupportedTagContainer}>
          <Typography className={classes.deviceNotSupportedTag}>
            Your plan does not support this device
          </Typography>
        </div>
      )}

      <ShareMenu description={long_description} title={title} imageUrl={imageUrl} />
    </div>
  )
}

const PlayResumeButtons = ({
  video,
  orderDetail,
  deviceNotSupported,
  vidId,
  openCheckoutModal
}) => {
  const { duration, trailers } = video
  const classes = movieDetailStyles()

  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const dispatch = useDispatch()

  const purchaseValid = orderDetail
    ? orderDetail.purchased && !orderDetail.expired
      ? true
      : false
    : false

  const startedWatching = orderDetail ? (orderDetail.started ? true : false) : false

  const watchedPercentage = orderDetail ? (orderDetail.last_timestamp / (duration / 1000)) * 100 : 0

  const handlePlayTrailer = () => {
    if (!trailers || !trailers.items || trailers.items.length === 0) return

    const filteredTrailers = trailers.items.filter((item) => item.is_main)
    const payload = {
      isPlayerModalVisible: true,
      trailer: filteredTrailers.length > 0 ? filteredTrailers[0] : trailers.items[0]
    }
    dispatch(playTrailer(payload))

    window.gtag('event', 'watch_trailer', {
      event_label: 'Trailer ID',
      content_type: payload.trailer.type,
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: payload.trailer.id,
      parent_video_id: video.id,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const handlePlayPreviewTrailer = () => {
    if (!trailers || !trailers.items || trailers.items.length === 0) return
    let objectPreview = trailers?.items?.find((e) => e.type === 'CONTENT_PREVIEW')
    const payload = {
      isPlayerModalVisible: true,
      trailer: objectPreview
    }
    dispatch(playTrailer(payload))

    window.gtag('event', 'watch_trailer', {
      event_label: 'Trailer ID',
      content_type: payload.trailer.type,
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: payload.trailer.id,
      parent_video_id: video.id,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const handlePlayVideo = () => {
    if (isMobile) {
      dispatch(setWatchOnMobile(true))
      return
    }
    const payload = {
      isPlayerModalVisible: true,
      video,
      orderId: orderDetail.id || ''
    }
    dispatch(playVideo(payload))

    window.gtag('event', 'watch_video', {
      event_label: 'Video ID',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: payload.video.id,
      device_type_id: deviceTypeId,
      ip_address: locationData.query,
      plan_id: orderDetail.video_plan.plan_id,
      plan_name: orderDetail.video_plan.plan.title
    })
  }

  const notReleased = moment().isBefore(video.release_date)

  return (
    <div>
      <div className={classes.headerButtons}>
        {purchaseValid && startedWatching && watchedPercentage > 0 && watchedPercentage < 99 && (
          <>
            <Button
              title="Resume Movie"
              className={classes.resumeButton}
              hover
              onClick={handlePlayVideo}
              disabled={deviceNotSupported}
            />
            <LinearProgress
              color="primary"
              variant="determinate"
              value={watchedPercentage}
              className={classes.progressBar}
            />
          </>
        )}
        {purchaseValid &&
          (!startedWatching || watchedPercentage === 0 || watchedPercentage > 99) &&
          !notReleased && (
            <Button
              title="Play Movie"
              disabled={deviceNotSupported}
              onClick={handlePlayVideo}
              className={classes.playButton}
              hover
            />
          )}

        {((!purchaseValid && !startedWatching) || notReleased) && (
          <>
            <Button
              title="Watch Trailer"
              onClick={handlePlayTrailer}
              className={classes.playButton}
              hover
            />
            {(!orderDetail || (orderDetail && (!orderDetail.purchased || orderDetail.expired))) && (
              <WatchOptions
                video={video}
                openCheckoutModal={openCheckoutModal}
                videoId={vidId}
                handlePlayPreviewTrailer={handlePlayPreviewTrailer}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const MovieDetailFooter = ({ year, video }) => {
  const classes = movieDetailStyles()
  const { duration, is_orignal, content_rating } = video

  const hours = Math.floor(duration / (1000 * 60 * 60))
  const minutes = Math.floor(duration / (1000 * 60)) % 60
  return (
    <div className={classes.detailFooterContainer}>
      <div className={classes.durationTag}>
        <Typography className={classes.durationRatingsText}>{`${hours}H ${minutes} M`}</Typography>
      </div>
      <TagWrapper>
        <Typography className={classes.otherTag}>{year}</Typography>
      </TagWrapper>
      <TagWrapper>
        <Typography className={classes.otherTag}>
          {content_rating?.items[0]?.content_rating?.title}
        </Typography>
      </TagWrapper>
      <TagWrapper>
        <Typography className={classes.otherTag}>UHD</Typography>
      </TagWrapper>
      <TagWrapper>
        <Typography className={classes.otherTag}>CC</Typography>
      </TagWrapper>
      {is_orignal && (
        <TagWrapper>
          <img alt="Mintrio" src={logo} className={classes.mintrioTag} />
        </TagWrapper>
      )}
    </div>
  )
}

const TagWrapper = ({ children }) => {
  const classes = movieDetailStyles()
  return <div className={classes.tagWrapper}>{children}</div>
}

const GenreList = ({ genreList }) => {
  const classes = useGenreListStyles()

  return (
    <div className={classes.listContainer}>
      {genreList.map((item, index) => {
        return (
          <div key={index} className={classes.genreItem}>
            <Typography className={classes.title}>{item.genre.title}</Typography>
            {index !== genreList.length - 1 && <Brightness1Icon className={classes.dotIcon} />}
          </div>
        )
      })}
    </div>
  )
}
