import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { registrationStyles } from './styles'
import {
  //  GENDER_OPTIONS,
  ALERT_TYPES,
  EVENT_CATEGORIES
} from '../../../../lib/constant'
import { registerNewUser, setRegistrationData } from '../../../../redux/modules/user'
import { showAlert } from '../../../../redux/modules/alertHandler'
import { validateRegistrationForm } from '../../../../lib/utils'
import {
  //  DateInput,
  GridWRapper,
  TextInput,
  //  SelectInput,
  Button
} from '../../../../components'
import errorHandler from '../../../../lib/errorHandler'

export const RegistrationDetail = ({ handleBack }) => {
  const classes = registrationStyles()

  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const { registration } = useSelector((state) => state.user)
  const { email, firstName, lastName, password } = registration

  const dispatch = useDispatch()
  const history = useHistory()

  const handleChange = ({ target: { name, value } }) =>
    dispatch(setRegistrationData({ [name]: value }))

  useEffect(() => {
    if (validateRegistrationForm(registration)) setIsValid(true)
    else setIsValid(false)
  }, [registration])

  const handleSignUp = async (event) => {
    if (event) event.preventDefault()

    if (loading) return

    try {
      setLoading(true)
      const emailAddress = email
      await dispatch(registerNewUser())
      history.push(`/email-verification/${emailAddress}`)
      setLoading(false)

      window.gtag('event', 'entered_signup_email', {
        event_label: 'Email Address',
        event_category: EVENT_CATEGORIES.SIGN_UP,
        event_value: emailAddress
      })
    } catch (err) {
      errorHandler.report(err)
      setLoading(false)
      dispatch(
        showAlert({
          message: err.message || 'Something went wrong please try again',
          type: ALERT_TYPES.ERROR,
          isVisible: true
        })
      )
    }
  }

  return (
    <div style={{ paddingTop: '150px' }}>
      <Typography color="secondary" className={classes.h2} variant="h2" align="center">
        {'COMPLETE YOUR PROFILE'}
      </Typography>
      <form onSubmit={handleSignUp} className={classes.initialFormContainer}>
        <Grid container md={3} lg={3} xs={10} sm={6}>
          <GridWRapper>
            <TextInput
              name="firstName"
              value={firstName}
              placeholder="First Name"
              className={classes.input}
              onChange={handleChange}
            />
          </GridWRapper>
          <GridWRapper>
            <TextInput
              name="lastName"
              value={lastName}
              placeholder="Last Name"
              className={classes.input}
              onChange={handleChange}
            />
          </GridWRapper>
          {/* <GridWRapper>
            <SelectInput
              value={gender}
              name="gender"
              label={'Gender'}
              menus={GENDER_OPTIONS}
              onChange={handleChange}
            />
          </GridWRapper>

          <GridWRapper>
            <DateInput
              onChange={handleChange}
              value={dob}
              id="dob"
              name="dob"
              placeholder="Date of birth"
              className={classes.input}
            />
          </GridWRapper> */}

          <GridWRapper>
            <TextInput
              name="password"
              placeholder="Password*"
              type="password"
              value={password}
              className={classes.input}
              onChange={handleChange}
            />
          </GridWRapper>
          <GridWRapper>
            <div className={classes.buttonsContainer}>
              <Button
                title="Back"
                color="primary"
                hover
                onClick={handleBack}
                style={{ width: '50%', height: 36 }}
              />
              <div style={{ paddingLeft: 10, flex: 1 }}>
                <Button
                  title="Continue"
                  color="primary"
                  hover
                  loading={loading}
                  disabled={!isValid}
                  onClick={handleSignUp}
                  style={{ width: '100%', height: 36 }}
                  type="submit"
                />
              </div>
            </div>
          </GridWRapper>
        </Grid>
      </form>
    </div>
  )
}
