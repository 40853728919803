import { Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { PaymentMethodItem } from '../paymentMethodItem'
import { useStyles } from './styles'
export const PaymentMethodsList = ({ paymentMethods, handleStep, setProcessing }) => {
  const classes = useStyles()
  const { videoId } = useSelector((state) => state.checkout)

  return (
    <Grid container justify="center" className={classes.mainContainer}>
      <Grid md={6} lg={4} xl={4} sm={6} xs={10}>
        {paymentMethods.map((item) => {
          return (
            <PaymentMethodItem
              handleStep={handleStep}
              videoId={videoId}
              key={item.id}
              paymentMethod={item}
              setProcessing={setProcessing}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}
