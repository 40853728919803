import React, { useState } from 'react'

import { InitialForm, RegistrationDetail } from './components'
import { AuthenticationPagesWrapper, HelmetMetaData } from '../../../components'
import { useHistory } from 'react-router-dom'

export const SignUp = () => {
  const [showRegistrationDetail, setShowRegistrationDetail] = useState(false)

  const history = useHistory()
  const handleBack = () => {
    history.push('/sign-up')
    setShowRegistrationDetail(false)
  }
  return (
    <>
      <HelmetMetaData
        title={'Mintrio - Sign Up'}
        description={'Sign Up to mintrio'}
        hashtag={'#mintrio-signup'}
      />
      <AuthenticationPagesWrapper>
        {showRegistrationDetail ? (
          <RegistrationDetail handleBack={handleBack} />
        ) : (
          <InitialForm setShowRegistrationDetail={setShowRegistrationDetail} />
        )}
      </AuthenticationPagesWrapper>
    </>
  )
}
