import { makeStyles } from '@material-ui/core'

import { homeBackground } from '../../assets'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mintrioBannerContainer: {
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage || homeBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto'
  },
  comingSoonBannerContainer: {
    backgroundColor: color.backgroundPurple,
    height: '100vh'
  },
  blurback: {
    backdropFilter: 'blur(10px)'
  },
  videoOverlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
