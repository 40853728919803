import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  thFooterContainer: {
    padding: '2% 0px 2% 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'white',
    width: 170,
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: 'white'
    }
  }
}))
