import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ComingSoonBanner, MovieBanner, MintrioBanner, LoaderSkeleton } from './components'
import { fetchVideos } from '../../redux/modules/videos'
import { useStyles } from './styles'
import { useLocation } from 'react-router'
import { Footer, HelmetMetaData } from '../../components'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import { USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import videojs from 'video.js'

export const Home = () => {
  const mintrioBannerRef = useRef()
  const discoverRef = useRef()
  const { videos, nextToken, loading } = useSelector((state) => state.videos)
  const check = useSelector((state) => state.landingPage)
  const {
    background: { type, url }
  } = check
  const videoRef = useRef(null)
  const classes = useStyles({ backgroundImage: type === 'image' ? url : null })
  const dispatch = useDispatch()
  const location = useLocation()

  const fetchData = () => dispatch(fetchVideos())

  const scrollToDiscover = () => discoverRef.current.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    if (location.hash === '#discover' && discoverRef.current)
      discoverRef.current.scrollIntoView({ behavior: 'smooth' })
    else if ((location.pathname === '/' || location.hash === '#') && mintrioBannerRef.current)
      mintrioBannerRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [location])

  useEffect(() => {
    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: 'discover', path: '/' }))
  }, [dispatch])

  useEffect(() => {
    if (videoRef && videoRef.current && type === 'video')
      videojs(videoRef.current, {
        autoplay: true,
        controls: false,
        muted: true,
        preload: 'none',
        loop: true,
        sources: [
          {
            src: url,
            type: 'application/x-mpegURL',
            overrideNative: true
          }
        ]
      })
  }, [videoRef, type, url])

  return (
    <>
      <HelmetMetaData title={'Mintrio'} description={'Mintrio'} hashtag={'#discover'} />
      {type === 'video' ? (
        <>
          <div style={{ height: '100vh', width: '100%' }} data-vjs-player>
            <video ref={videoRef} className="video-js background" />
          </div>
          <div className={classes.videoOverlayContainer}>
            <MintrioBanner loading={loading} onClick={scrollToDiscover} />
          </div>
        </>
      ) : (
        <div ref={mintrioBannerRef} className={`${classes.mintrioBannerContainer}`}>
          <MintrioBanner loading={loading} onClick={scrollToDiscover} />
        </div>
      )}

      <div id="discover" ref={discoverRef}>
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={videos.length}
          next={fetchData}
          hasMore={nextToken}>
          {videos.map((movie) => (
            <MovieBanner key={movie.id} {...movie} />
          ))}
        </InfiniteScroll>
      </div>

      {loading ? (
        <LoaderSkeleton />
      ) : (
        <div className={`${classes.comingSoonBannerContainer}`}>
          <ComingSoonBanner />
        </div>
      )}

      <Footer />
    </>
  )
}
