import { Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Countdown from 'react-countdown'
import { color } from '../../../styles/color'
import moment from 'moment'
export const CountDownTimer = ({ notReleased, video, orderDetail }) => {
  const classes = useStyles()

  const date = notReleased ? video.release_date : orderDetail.expireAt

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <Typography className={classes.countDown}>
          {`${days}D : ${hours}H : ${minutes}M`}
        </Typography>
      )
    }
  }
  return (
    <div>
      {moment(video.release_date).subtract(90, 'days').isSameOrBefore(new Date()) ? (
        <div
          className={clsx(
            classes.timerContainer,
            notReleased ? classes.streamingIn : classes.expiresIn
          )}>
          <Typography
            className={classes.timerTitle}
            style={{ color: notReleased ? color.green : color.red }}>
            {notReleased ? 'Streaming In' : 'Expires in'}
          </Typography>
          <Countdown date={new Date(date)} renderer={renderer} />
        </div>
      ) : (
        <div className={clsx(classes.timerContainer, classes.streamingIn)}>
          <Typography
            className={classes.comingsoonTitle}
            style={{ color: notReleased ? color.green : color.red }}>
            {'COMING SOON'}
          </Typography>
        </div>
      )}
    </div>
  )
}

export const useStyles = makeStyles((theme) => ({
  timerContainer: {
    marginTop: 25,
    height: 30,
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  streamingIn: {
    background: 'rgba(14, 168, 21, 0.6)'
  },
  expiresIn: {
    background: 'rgba(255, 0, 0, 0.24)'
  },
  timerTitle: {
    fontSize: 9,
    fontWeight: '600',
    textTransform: 'uppercase',
    paddingRight: 10,
    paddingTop: 2
  },
  comingsoonTitle: {
    fontSize: 12,
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: '3px',
    paddingTop: 2,
    color: 'white'
  },
  countDown: {
    color: 'white',
    fontSize: 10,
    fontWeight: '400',
    textTransform: 'uppercase'
  }
}))
