import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  mainContainer: {
    outline: 'none',
    flex: 1,
    height: '100vh',
    position: 'relative'
  },
  modalContainer: {
    backgroundColor: 'black'
  }
}))
