import { getToken } from './user'
import { API } from 'aws-amplify'
import { verifyPromoCode } from '../../api/checkout'
// constants
const SET_PLAN = 'SET_PLAN'
const SET_PROMO_CODE = 'SET_PROMO_CODE'
const SET_PAYMENT_GATEWAY = 'SET_PAYMENT_GATEWAY'
const RESET_CHECKOUT = 'RESET_CHECKOUT'
const SET_VIDEO_ID = 'SET_VIDEO_ID'

export const resetCheckout = () => {
  return {
    type: RESET_CHECKOUT,
    payload: { paymentGateway: null, plan: null, promoCode: null }
  }
}

export const setPlan = (plan) => {
  return {
    type: SET_PLAN,
    payload: { plan }
  }
}

export const setVideoId = (videoId) => {
  return {
    type: SET_VIDEO_ID,
    payload: { videoId }
  }
}

export const setPaymentGateway = (paymentGateway) => {
  return {
    type: SET_PAYMENT_GATEWAY,
    payload: { paymentGateway }
  }
}

export const setPromoCode = (promoCode) => {
  return {
    type: SET_PROMO_CODE,
    payload: { promoCode }
  }
}

// create stripe payment method first
// verify promo code again
// call checkout api
export const checkoutStripe =
  (accessToken, { firstName, lastName }, stripe, cardElement, video_id) =>
  async (dispatch, getState) => {
    const {
      checkout: {
        paymentGateway,
        plan: { videoPlanId: video_plan_id, planId: plan_id },
        promoCode
      },
      location: { countryCode: country_code },
      user: { userId: user_id, email }
    } = getState()

    const billingDetails = {
      name: `${firstName} ${lastName}`,
      email
    }

    try {
      const authToken = accessToken || (await dispatch(getToken()))

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails
      })

      let isValid = false

      if (promoCode) {
        const response = await verifyPromoCode(
          authToken,
          {
            code: promoCode.code,
            video_id,
            user_id,
            plan_id,
            country_code
          },
          dispatch
        )
        if (!response.isValid) {
          dispatch(setPromoCode(null))
          throw new Error('Promo code is no longer valid')
        }

        isValid = response.isValid
      }

      const params = {
        body: {
          payment_method: paymentMethodReq.paymentMethod.id,
          payment_gateway_id: paymentGateway.id,
          user_id,
          video_plan_id,
          video_id,
          country_code,
          isGift: false,
          promoCodeId: isValid && promoCode ? promoCode.id : null,
          additional_attributes: JSON.stringify({})
        },
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }

      await API.post('checkout', '/stripe', params)
    } catch (error) {
      console.error('order error', error)
      throw error
    }
  }

export const gratisCheckout = (accessToken) => async (dispatch, getState) => {
  const {
    checkout: {
      plan: { videoPlanId: video_plan_id, planId: plan_id },
      promoCode,
      videoId
    },
    location: { countryCode: country_code },
    user: { userId: user_id }
  } = getState()

  try {
    const authToken = accessToken || (await dispatch(getToken()))

    let isValid = false

    if (promoCode) {
      const response = await verifyPromoCode(
        authToken,
        {
          code: promoCode.code,
          video_id: videoId,
          user_id,
          plan_id,
          country_code
        },
        dispatch
      )
      if (!response.isValid) {
        dispatch(setPromoCode(null))
        throw new Error('Promo code is no longer valid')
      }

      isValid = response.isValid
    }

    const params = {
      body: {
        // TODO: gratis payment gateway
        // add it to env
        payment_gateway_id: 'ef8d10d0-d2cd-4ddf-815e-d5ce1b30576f',
        user_id,
        video_plan_id,
        video_id: videoId,
        country_code,
        isGift: false,
        promoCodeId: isValid && promoCode ? promoCode.id : null,
        additional_attributes: JSON.stringify({})
      },
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }

    await API.post('checkout', '/gratis', params)
  } catch (error) {
    console.error('order error', error)
    throw error
  }
}

// thunks

// reducer
const initialState = {
  videoId: null,
  paymentGateway: null,
  plan: null,
  promoCode: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_PAYMENT_GATEWAY: {
      return {
        ...state,
        paymentGateway: payload.paymentGateway
      }
    }
    case SET_VIDEO_ID: {
      return {
        ...state,
        videoId: payload.videoId
      }
    }
    case SET_PLAN: {
      return {
        ...state,
        plan: payload.plan
      }
    }
    case SET_PROMO_CODE: {
      return {
        ...state,
        promoCode: payload.promoCode
      }
    }
    case RESET_CHECKOUT: {
      return {
        ...payload
      }
    }
    default:
      return state
  }
}

export default reducer
