import { combineReducers } from 'redux'

import user from './modules/user'
import videos from './modules/videos'
import myContent from './modules/myContent'
import trailerPlayer from './modules/trailerPlayer'
import location from './modules/location'
import deviceType from './modules/deviceType'
import videoPlayer from './modules/videoPlayer'
import viewLogs from './modules/viewLogs'
import globalModals from './modules/globalModals'
import checkout from './modules/checkout'
import alertHandler from './modules/alertHandler'
import userActivityLogs from './modules/userActivityLogs'
import userPreferences from './modules/userPreferences'
import { landingPage } from './modules/landingPage'

const reducers = combineReducers({
  user,
  videos,
  myContent,
  trailerPlayer,
  location,
  deviceType,
  videoPlayer,
  viewLogs,
  globalModals,
  checkout,
  alertHandler,
  userActivityLogs,
  userPreferences,
  landingPage
})

export const rootReducer = (state, action) => {
  // Clear persistant storage on logout
  // Dispatch RESET_APP on logout
  if (action.type === 'RESET_APP') {
    state = { location: state.location }
  }

  return reducers(state, action)
}
