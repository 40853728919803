import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import { useStyles } from './styles'

export const TransactionAccordion = ({ transactions }) => {
  const classes = useStyles()
  return (
    <div className={classes.rootContainer}>
      {transactions.map((transaction) => {
        return <TransactionItem transaction={transaction} />
      })}
    </div>
  )
}

const TransactionItem = ({ transaction }) => {
  const classes = useStyles()

  const {
    ref_no,
    is_gift,
    payment_total,
    country: { currency, name: countryName },
    purchased_at,
    video: { title },
    video_plan: {
      plan: { title: planTitle }
    },
    payment_gateway: { name }
  } = transaction
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandableIcon} />}
        aria-controls="panel1a-content"
        className={classes.accordionSummary}
        id="panel1a-header">
        <Typography className={classes.headerTitle}>{title}</Typography>
        <Typography className={classes.headerDate}>{moment(purchased_at).format('L')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        <Typography className={classes.item}>
          <span>Plan:</span> {planTitle}
        </Typography>
        <Typography className={classes.item}>
          <span>Payment Gateway:</span> {name}
        </Typography>
        <Typography className={classes.item}>
          <span>Ref. No.:</span> {ref_no}
        </Typography>
        <Typography className={classes.item}>
          <span>Country:</span> {countryName}
        </Typography>
        <Typography className={classes.item}>
          <span>Amount:</span> {currency} {payment_total}
        </Typography>
        <Typography className={classes.item}>
          <span>Is Gift:</span> {is_gift ? 'Yes' : 'No'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
