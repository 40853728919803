import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  gridContainer: {
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 30
    }
  },
  inputField: {
    color: 'white',
    border: 'none',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: '15px',
    height: 35,
    backgroundColor: 'rgba(234,234,234,0.2)',
    backdropFilter: 'blur(30px)',
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  stripInputContainer: {
    border: 'none',
    width: '100%',
    borderRadius: 4,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '12px',
    marginBottom: '15px',
    backgroundColor: 'rgba(234,234,234,0.2)',
    backdropFilter: 'blur(30px)',
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  redBorder: {
    border: '1px solid red'
  },
  footerContainer: {
    height: 120,
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    color: 'white',
    width: 240,
    height: 40,
    fontWeight: '600',
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: 'white'
    }
  }
}))
