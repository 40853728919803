import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '../../lib/utils'
import { color } from '../../styles/color'
import { Checkout } from '../checkout'
import { setMobileCheckoutData, setUserData } from '../../redux/modules/user'
import { useHistory } from 'react-router-dom'

export const MobileCheckout = () => {
  const accessToken = useQuery().get('access-token')
  const videoId = useQuery().get('video-id')
  const checkoutStatus = useQuery().get('checkout-status')
  const userId = useQuery().get('user-id')
  const email = useQuery().get('email')
  const checkoutSource = useQuery().get('checkout-source')
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (userId && email) dispatch(setUserData({ userId, email }))
  }, [userId, email, dispatch])

  useEffect(() => {
    if (!accessToken || !videoId || !userId || !email) {
      if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('false')
      else history.replace('/')
    } else {
      dispatch(setMobileCheckoutData({ accessToken, videoId, userId, email }))
    }
  }, [accessToken, videoId, userId, email, history, dispatch])

  // if token is null
  // call exit event to leave from the webview
  return (
    <div style={{ backgroundColor: color.backgroundPurple }}>
      <Checkout
        accessToken={accessToken}
        checkoutStatus={checkoutStatus}
        checkoutSource={checkoutSource}
        videoId={videoId}
        closeCheckoutModal={() => {}}
      />
    </div>
  )
}
