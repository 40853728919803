import axios from 'axios'
import { GEO_IP_LOOKUP_PROD_URL } from '../../lib/constant'

// constants
const SET_LOCATION = 'SET_LOCATION'

const setLocation = (payload) => ({
  type: SET_LOCATION,
  payload
})

// thunks
export const fetchLocation = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${`${GEO_IP_LOOKUP_PROD_URL}/?key=${process.env.REACT_APP_GEO_IP_LOOKUP_KEY}`}`
    )
    dispatch(
      setLocation({
        ...response.data,
        countryCode: response.data.country_code,
        query: response.data.ip
      })
    )
  } catch (error) {
    throw error
  }
}

// reducer
const initialState = { countryCode: null }

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_LOCATION: {
      return {
        ...state,
        ...payload
      }
    }
    default:
      return state
  }
}

export default reducer
