import { makeStyles } from '@material-ui/core'
import { AppleStore, PlayStore } from '../../assets'

export const useStyles = makeStyles((theme) => ({
  storeButton: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    border: '1px solid white',
    borderRadius: 5,
    padding: '0px 20px',
    display: 'flex',
    cursor: 'pointer',
    background: ({ store }) =>
      store === 'ios' ? `url(${AppleStore}) no-repeat` : `url(${PlayStore}) no-repeat`,
    backgroundSize: 'contain',
    '&:active': {
      border: '1px solid gray'
    }
  }
}))
