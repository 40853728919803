import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    padding: '40px 0px'
  },
  gridContainer: {
    padding: '0px 10px'
  }
}))
