import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import validator from 'validator'
import { Auth } from 'aws-amplify'

import { useStyles } from './styles'
import { Button, TextInput, SocialAuthButtons, DividerWithText } from '../../../../components'
import { setRegistrationData } from '../../../../redux/modules/user'
import { showAlert } from '../../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../../lib/constant'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const InitialForm = ({ setShowRegistrationDetail }) => {
  const classes = useStyles()
  let query = useQuery()
  const redirectPage = query.get('redirectPage')

  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const {
    registration: { email }
  } = useSelector((state) => state.user)
  const history = useHistory()

  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value } }) =>
    dispatch(setRegistrationData({ [name]: value }))

  useEffect(() => {
    if (validator.isEmail(email.trim().toLowerCase())) setIsValid(true)
    else setIsValid(false)
  }, [email])

  const handleSignUp = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await Auth.signIn(email.trim().toLowerCase(), '123')
    } catch (err) {
      setLoading(false)
      // User not exist navigate to sign up detail
      if (err.code === 'UserNotFoundException') setShowRegistrationDetail(true)
      // User exists navigate to sign in
      else if (err.code === 'NotAuthorizedException')
        dispatch(
          showAlert({
            message: 'This email already exists',
            isVisible: true,
            type: ALERT_TYPES.ERROR
          })
        )
      // Else show error
      else
        dispatch(
          showAlert({
            message: 'Something went wrong please try again.',
            isVisible: true,
            type: ALERT_TYPES.ERROR
          })
        )
    }
  }

  return (
    <div>
      <form onSubmit={handleSignUp} className={classes.initialFormContainer}>
        <Grid container md={5} lg={5} xs={10} sm={10}>
          <Grid item lg={12} md={12} xs={12} sm={12} className={classes.formHeaderContainer}>
            <Typography color="secondary" variant="h2" align="center" className={classes.h2}>
              Ready to watch?
            </Typography>
            <Typography
              color="secondary"
              variant="subtitle1"
              align="center"
              className={classes.subtitle1}>
              Enter your email to create your account
            </Typography>
          </Grid>

          <Grid
            item
            lg={8}
            md={8}
            xs={12}
            sm={8}
            className={classes.inputContainer}
            style={{ paddingRight: '0px', marginTop: '-1.5px' }}>
            <TextInput
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={handleChange}
              className={classes.input}
              style={{ padding: '5px 10px' }}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={4} className={classes.inputContainer}>
            <Button
              title="Sign Up"
              color="primary"
              hover
              disabled={!isValid}
              onClick={handleSignUp}
              style={{ width: '100%', height: 36 }}
              type="submit"
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
      <div className={classes.centeredContainer}>
        <Grid item lg={4} md={6} xs={10} sm={6} className={classes.inputContainer}>
          <Typography color="secondary" align="center" className={classes.alreadyHaveAccount}>
            Already have an account?
            <span
              role="presentation"
              onClick={() =>
                history.push(redirectPage ? `/sign-in?redirectPage=${redirectPage}` : '/sign-in')
              }
              className={classes.signInAnchor}>
              <b>SIGN IN</b>
            </span>
          </Typography>

          <DividerWithText>OR</DividerWithText>
          <SocialAuthButtons redirectPage={redirectPage} />
        </Grid>
      </div>
    </div>
  )
}
