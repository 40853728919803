import React from 'react'

import { useStyles } from './styles'
import { GridContainer } from '../GridContainer'

export const AuthenticationPagesWrapper = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.backgroundImage}`}>
      <GridContainer>
        <>{children}</>
      </GridContainer>
    </div>
  )
}
