//constants
const PLAY_TRAILER = 'PLAY_TRAILER'
const RESET_TRAILER = 'RESET_TRAILER'

export const playTrailer = (payload) => ({
  type: PLAY_TRAILER,
  payload
})

export const closeTrailerPlayer = () => ({
  type: RESET_TRAILER
})

//reducer
const initialState = {
  isPlayerModalVisible: false,
  trailer: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case PLAY_TRAILER:
      return {
        ...payload
      }
    case RESET_TRAILER:
      return {
        isPlayerModalVisible: false,
        trailer: null
      }

    default:
      return state
  }
}

export default reducer
