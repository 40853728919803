import { Button } from '../../../Button'
import { Typography } from '@material-ui/core'

export const RentButton = ({ symbol, price, handleRedirect }) => {
  return (
    <>
      <Typography
        color="secondary"
        className="titleRent"
        align="center"
        style={{ fontWeight: 'bold' }}
      >
        {'RENT NOW TO CONTINUE'}
      </Typography>
      <Button
        className="rent-button"
        title={'Rent Now From ' + `${symbol + ' '}${price}`}
        onClick={handleRedirect}
        hover
        type="submit"
      />
    </>
  )
}
