import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  errorContainer: {
    color: 'red',
    textAlign: 'center',
    fontSize: 12,
    paddingTop: 10,
    width: '100%',
    flex: 1
  }
}))
