import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

export const Container = ({ children, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'rgba(43, 42, 66, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    padding: '30px 40px',
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0px',
      padding: '20px 20px'
    }
  },
  title: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {}
  }
}))
