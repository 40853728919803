import { makeStyles } from '@material-ui/core'
import { color } from '../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  movieBannerContainer: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  parentContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'flex-start'
    }
  },
  imageBanner: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '40vh'
    }
  },
  downArrowContainer: {
    cursor: 'pointer',
    bottom: 0,
    position: 'absolute',
    color: color.white,
    left: '50%',
    '-webkit-transform': 'translateX(-50%)',
    transform: 'translateX(-50%)'
  },
  container: {
    display: 'flex'
  },
  leftcontainer: {
    display: 'flex',
    backgroundColor: color.newdock,
    backdropFilter: 'blur(10px)',
    padding: 20,
    textAlign: 'center',
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
      justifyContent: 'flex-start',
      padding: 10,
      borderBottom: `1px solid ${color.greypurple}`,
      backgroundColor: color.backgroundPurple
      // backdropFilter: 'none'
    }
  },
  h1: {
    fontSize: ({ titleFontSize }) =>
      parseFloat(titleFontSize) <= 0 ? `5.9375em` : `${titleFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: 400,
    textAlign: 'center',
    color: ({ titleColor }) => titleColor,
    backgroundColor: ({ titleBackgroundColor }) => titleBackgroundColor,
    padding: '0px 10px',
    borderRadius: 5,
    backdropFilter: ({ titleBackgroundBlurriness }) => `blur(${titleBackgroundBlurriness}px)`,
    [theme.breakpoints.down('sm')]: {
      fontSize: ({ titleFontSize }) =>
        parseFloat(titleFontSize) <= 0 ? `2.8em` : `${titleFontSize}em`,
      maxWidth: '90%',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ titleFontSize }) =>
        parseFloat(titleFontSize) <= 0 ? `2.8em` : `${titleFontSize}em`
    }
  },
  h2: {
    fontSize: ({ subTitleFontSize }) =>
      parseFloat(subTitleFontSize) <= 0 ? '2.7em' : `${subTitleFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: '400',
    textAlign: 'center',
    color: ({ subTitleColor }) => subTitleColor,
    backgroundColor: ({ subTitleBackgroundColor }) => subTitleBackgroundColor,
    padding: '0px 10px',
    paddingTop: '0px !important',
    marginTop: 20,
    borderRadius: 5,
    backdropFilter: ({ subTitleBackgroundBlurriness }) => `blur(${subTitleBackgroundBlurriness}px)`,
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ subTitleFontSize }) =>
        parseFloat(subTitleFontSize) <= 0 ? '1.4em' : `${subTitleFontSize}em`
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: ({ subTitleFontSize }) =>
        parseFloat(subTitleFontSize) <= 0 ? '1.4em' : `${subTitleFontSize}em`
    }
  },
  subtitle1: {
    fontSize: '25px',
    fontWeight: 400,
    color: color.readPurple,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  contentLibraryText: {
    fontSize: ({ bannerFontSize }) =>
      parseFloat(bannerFontSize) <= 0 ? '1.875em' : `${bannerFontSize}em`,
    fontFamily: 'Rubik',
    fontWeight: 300,
    marginTop: '20px',
    color: '#c2c2c2',
    color: ({ bannerColor }) => bannerColor,
    backgroundColor: ({ bannerBackgroundColor }) => bannerBackgroundColor,
    padding: '0px 10px',
    borderRadius: 5,
    backdropFilter: ({ bannerBackgroundBlurriness }) => `blur(${bannerBackgroundBlurriness}px)`,
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ bannerFontSize }) =>
        parseFloat(bannerFontSize) <= 0 ? '1.3em' : `${bannerFontSize}em`,
      textAlign: 'center'
    }
  },
  bannerMessageContainer: {
    position: 'absolute',
    top: 60,
    left: 20,
    right: 20,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 10
    }
  },
  bannerPriceContainer: {
    position: 'absolute',
    bottom: 0,
    maxWidth: '100%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 0,
      marginTop: '-50px',
      height: '30px'
    }
  },
  bannerPrice: {
    color: color.white,
    fontSize: 20,
    fontWeight: '400',
    fontFamily: 'Rubik',
    backgroundColor: '#3A7248',
    padding: '5px 10px 5px 10px',
    width: '480px',
    [theme.breakpoints.down('xl')]: {
      width: '1000px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      borderRadius: 3,
      fontWeight: 450,
      padding: '3px 10px 3px 10px',
      width: 'auto',
      display: 'inline-block',
      height: '27px'
    }
  },
  banner: {
    color: color.white,
    fontSize: '14px',
    fontWeight: 450,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    backgroundColor: color.greypurple,
    borderRadius: 5,
    padding: '5px 10px 5px 10px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 10,
      padding: '3px 10px 3px 10px'
    }
  },
  letterSpaced: {
    letterSpacing: '-1px',
    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0px'
    }
  },
  moviefname: {
    color: color.white,
    fontSize: '50px',
    fontWeight: 700,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      marginTop: 25
    }
  },
  movieSubTitle: {
    color: color.white,
    fontSize: '36px',
    fontWeight: 600,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  movielname: {
    color: color.white,
    fontSize: '25px',
    fontWeight: 400,
    paddingBottom: '35px',
    fontFamily: 'Rubik',
    textTransform: 'uppercase'
  },
  mintrioOriginal: {
    position: 'absolute',
    bottom: '50px',
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      bottom: '0px',
      position: 'relative',
      paddingBottom: 20
    }
  },
  shortdisc: {
    color: color.white,
    fontSize: '18px',
    fontWeight: 400,
    paddingBottom: '20px',
    fontFamily: 'Rubik',
    [theme.breakpoints.down('xs')]: {
      fontSize: '17px'
    }
  },
  button: {
    width: '260px',
    backgroundColor: color.buttonBlue,
    color: color.white,
    fontWeight: '400',
    fontSize: '17px',
    borderRadius: 6,
    fontFamily: 'Rubik',
    [theme.breakpoints.down('sm')]: {
      fontWeight: '400',
      fontSize: '14',
      width: '200px'
    }
  },
  transparentbutton: {
    marginTop: 20,
    width: '260px',
    backgroundColor: 'transparent',
    border: `2px solid white`,
    color: color.white,
    fontWeight: '400',
    fontSize: '17px',
    borderRadius: 6,
    fontFamily: 'Rubik',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 10,
      fontWeight: '400',
      fontSize: '14',
      width: '200px'
    }
  },
  comingSoonText: {
    fontSize: '40px',
    fontWeight: 600,
    marginTop: '0.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px'
    }
  },
  loaderContainer: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  circularProgress: {
    color: 'white'
  }
}))

export const useLoadingSkeletonStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    backgroundColor: color.primaryColor
  },
  leftcontainer: {
    display: 'flex',
    backgroundColor: 'rgba(55, 55, 80, 1)',
    backdropFilter: 'blur(30px)',
    padding: 20,
    textAlign: 'center',
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  bannerMessageContainer: {
    position: 'absolute',
    top: 60,
    left: 20,
    right: 20,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerSkeleton: {
    height: 10,
    width: 200
  },
  titleSkeleton: {
    width: 290,
    [theme.breakpoints.down('xs')]: {
      width: 230
    }
  },
  subTitleSkeleton: {
    width: 320,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 280
    }
  },
  buttonSkeleton: {
    width: '200px',
    marginTop: 20
  }
}))

export const useCountDownStyles = makeStyles((theme) => ({
  countDownContainer: {
    padding: '0px 0px'
  },
  streaming: {
    borderBottom: `2px solid ${color.buttonBlue}`,
    color: 'white',
    paddingBottom: '2px',
    fontWeight: '600',
    fontSize: '14px',
    letterSpacing: '4px',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px'
    }
  },
  comingsoon: {
    borderBottom: `1.5px solid ${color.buttonBlue}`,
    borderTop: `1.5px solid ${color.buttonBlue}`,
    color: color.white,
    fontWeight: '600',
    fontSize: '26px',
    letterSpacing: '8px',
    padding: '10px 10px',
    margin: '5% 5%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      fontWeight: '900',
      margin: '15% 0'
    }
  },

  timerMain: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Roboto, sans-serif',
    paddingBottom: 10
  },
  timerSub: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    fontFamily: 'Roboto, sans-serif',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  time: {
    color: 'white',
    fontSize: '35px',
    fontWeight: '600',
    letterSpacing: '12px',
    fontFamily: 'Roboto, sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  timeTitle: {
    color: 'white',
    fontSize: '12px',
    paddingTop: '10px',
    fontWeight: '600',
    letterSpacing: '5px',
    fontFamily: 'Roboto, sans-serif'
  }
}))
