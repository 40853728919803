import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'

import { Container } from './container'

export const SearchResult = ({ data }) => {
  const classes = useStyles()
  const [selectedQuestion, setSelectedQuestion] = useState('')

  const handleQuestion = (id) => {
    if (selectedQuestion === id) setSelectedQuestion('')
    else setSelectedQuestion(id)
  }
  return (
    <div className={classes.searchContainer}>
      <Container title={'Search Result'}>
        {data.length > 0 ? (
          <div className={classes.mainContainer}>
            {data.map(({ id, question, answer }) => {
              return (
                <div key={id} className={classes.questionContainer}>
                  <div className={classes.question}>
                    <Typography className={classes.questionText}>{question}</Typography>
                    <div>
                      <IconButton size={'small'} onClick={() => handleQuestion(id)}>
                        {id === selectedQuestion ? (
                          <RemoveIcon className={classes.icon} />
                        ) : (
                          <AddIcon className={classes.icon} />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  <Collapse in={id === selectedQuestion}>
                    <Typography className={classes.answer}>{answer}</Typography>
                  </Collapse>
                </div>
              )
            })}
          </div>
        ) : (
          <Typography className={classes.emptyList}>No Results Found :(</Typography>
        )}
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    padding: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  questionText: {
    color: 'white',
    flex: 1,
    textTransform: 'uppercase',
    fontWeight: '300',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  white: {
    color: 'white'
  },
  gray: {
    color: 'gray'
  },
  questionContainer: {
    backgroundColor: '#17162E',
    borderRadius: 5,
    margin: '10px 0px',
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  question: {
    display: 'flex'
  },
  icon: {
    color: 'white',
    fontSize: 20
  },
  answer: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '300',
    fontSize: 13,
    paddingTop: 10
  },
  emptyList: {
    color: 'white',
    textAlign: 'center',
    paddingTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
}))
