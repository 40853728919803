import { isMobile } from 'react-device-detect'

export const createUIContainer = (player, closePlayer) => {
  // seek interval in seconds
  const interval = 10

  let rewindButton = new window.bitmovin.playerui.Button({
    cssClass: 'ui-rewindbutton bmpui-ui-button'
  })
  rewindButton.onClick.subscribe(function () {
    player.seek(Math.max(0, player.getCurrentTime() - interval))
  })

  let forwardButton = new window.bitmovin.playerui.Button({
    cssClass: 'ui-forwardbutton bmpui-ui-button'
  })

  forwardButton.onClick.subscribe(function () {
    player.seek(Math.min(player.getDuration(), player.getCurrentTime() + interval))
  })

  let subtitleOverlay = new window.bitmovin.playerui.SubtitleOverlay()

  let mainSettingsPanelPage = new window.bitmovin.playerui.SettingsPanelPage({
    components: [
      new window.bitmovin.playerui.SettingsPanelItem(
        'Video Quality',
        new window.bitmovin.playerui.VideoQualitySelectBox()
      ),
      new window.bitmovin.playerui.SettingsPanelItem(
        'Speed',
        new window.bitmovin.playerui.PlaybackSpeedSelectBox()
      ),
      // new window.bitmovin.playerui.SettingsPanelItem(
      //   'Audio Quality',
      //   new window.bitmovin.playerui.AudioQualitySelectBox()
      // ),
      new window.bitmovin.playerui.SettingsPanelItem(
        'Audio Track',
        new window.bitmovin.playerui.AudioTrackSelectBox()
      )
    ]
  })

  let settingsPanel = new window.bitmovin.playerui.SettingsPanel({
    components: [mainSettingsPanelPage],
    hidden: true
  })

  let subtitleSettingsPanelPage = new window.bitmovin.playerui.SubtitleSettingsPanelPage({
    settingsPanel: settingsPanel,
    overlay: subtitleOverlay
  })

  const subtitleSelectBox = new window.bitmovin.playerui.SubtitleSelectBox()

  let subtitleSettingsOpenButton = new window.bitmovin.playerui.SettingsPanelPageOpenButton({
    targetPage: subtitleSettingsPanelPage,
    container: settingsPanel,
    ariaLabel: 'Subtitles',
    text: 'open'
  })

  mainSettingsPanelPage.addComponent(
    new window.bitmovin.playerui.SettingsPanelItem(
      new window.bitmovin.playerui.SubtitleSettingsLabel({
        text: 'Subtitles',
        opener: subtitleSettingsOpenButton
      }),
      subtitleSelectBox,
      {
        role: 'menubar'
      }
    )
  )

  settingsPanel.addComponent(subtitleSettingsPanelPage)

  let controlBar = new window.bitmovin.playerui.ControlBar({
    components: [
      settingsPanel,
      new window.bitmovin.playerui.Container({
        components: [
          new window.bitmovin.playerui.PlaybackTimeLabel({
            timeLabelMode: window.bitmovin.playerui.PlaybackTimeLabelMode.CurrentTime,
            hideInLivePlayback: true
          }),
          new window.bitmovin.playerui.SeekBar({
            label: new window.bitmovin.playerui.SeekBarLabel()
          }),
          new window.bitmovin.playerui.PlaybackTimeLabel({
            timeLabelMode: window.bitmovin.playerui.PlaybackTimeLabelMode.TotalTime,
            cssClasses: ['text-right']
          })
        ],
        cssClasses: ['controlbar-top']
      }),
      new window.bitmovin.playerui.Container({
        components: [
          new window.bitmovin.playerui.PlaybackToggleButton(),
          rewindButton,
          forwardButton,
          new window.bitmovin.playerui.VolumeToggleButton(),
          new window.bitmovin.playerui.VolumeSlider(),
          new window.bitmovin.playerui.Spacer(),
          new window.bitmovin.playerui.PictureInPictureToggleButton(),
          new window.bitmovin.playerui.AirPlayToggleButton(),
          new window.bitmovin.playerui.CastToggleButton(),
          new window.bitmovin.playerui.VRToggleButton(),
          new window.bitmovin.playerui.SettingsToggleButton({ settingsPanel: settingsPanel }),
          new window.bitmovin.playerui.FullscreenToggleButton()
        ],
        cssClasses: ['controlbar-bottom']
      })
    ]
  })

  let backButton = new window.bitmovin.playerui.Button({ cssClass: 'ui-back bmpui-ui-button' })
  backButton.onClick.subscribe(() => {
    player.destroy()
    closePlayer()
  })

  let titleBar = new window.bitmovin.playerui.TitleBar({
    components: [backButton, new window.bitmovin.playerui.TitleBar()]
  })

  return new window.bitmovin.playerui.UIContainer({
    components: [
      subtitleOverlay,
      new window.bitmovin.playerui.BufferingOverlay(),
      new window.bitmovin.playerui.PlaybackToggleOverlay(),
      new window.bitmovin.playerui.CastStatusOverlay(),
      controlBar,
      isMobile ? new window.bitmovin.playerui.TitleBar() : titleBar,
      new window.bitmovin.playerui.RecommendationOverlay(),
      new window.bitmovin.playerui.Watermark(),
      new window.bitmovin.playerui.ErrorMessageOverlay()
    ]
  })
}
