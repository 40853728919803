import { API, Auth } from 'aws-amplify'

import { getLandingPageData } from '../../graphql/queries'
import errorHandler from '../../lib/errorHandler'
import { detectDeviceType } from '../../lib/utils'
import { getToken } from './user'
// constants
const SET_DATA = 'SET_DATA'

const setData = (payload) => {
  return {
    type: SET_DATA,
    payload
  }
}

export const fetchLandingPageData = () => async (dispatch, getState) => {
  const {
    user: { login }
  } = getState()

  // refresh token if expired
  if (login) await dispatch(getToken())

  let session
  try {
    try {
      session = await Auth.currentSession()
    } catch (err) {
      session = null
    }
    const {
      data: { getLandingPageData: response }
    } = await API.graphql({
      query: getLandingPageData,
      variables: {
        device_name: detectDeviceType().toLowerCase()
      },
      authMode: session ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
    })

    if (response) dispatch(setData(response))
  } catch (err) {
    console.log('Error is: ', err)
    errorHandler.report(err)
  }
}

// reducer
const initialState = {
  device_name: 'desktop',
  title: {
    text: 'All Mintrio Originals.',
    font_size: '0',
    color: '#ffffff',
    background_color: '',
    background_opacity: '',
    background_blurriness: '0'
  },
  sub_title: {
    text: 'Your own digital theater.',
    font_size: '0',
    color: '#fffff',
    background_color: '',
    background_opacity: '',
    background_blurriness: '0'
  },
  banner: {
    text: 'Checkout our content library now.',
    font_size: '0',
    color: '#c2c2c2',
    background_color: '',
    background_opacity: '',
    background_blurriness: '0'
  },
  social_icons: {
    bottom_offset: '90',
    color: '#ffffff',
    background_color: '',
    background_opacity: '',
    background_blurriness: '0',
    disabled: true
  },
  background: {
    type: 'image',
    url: 'https://thumbnails-and-captions220515-production.s3.us-west-2.amazonaws.com/public/other-artifacts/mintrio_default_landing.jpg'
  }
}

export const landingPage = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_DATA: {
      return {
        ...payload
      }
    }

    default:
      return state
  }
}
