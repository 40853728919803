import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

export const Container = ({ children, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    margin: '50px 0px',
    background: 'rgba(43, 42, 66, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  content: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: 5
    }
  }
}))
