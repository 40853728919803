import React from 'react'

import { Typography } from '@material-ui/core'
import { useEmptyContainerStyles } from './styles'

export const EmptyList = ({ text }) => {
  const classes = useEmptyContainerStyles()
  return (
    <div className={classes.emptyListContainer}>
      <Typography className={classes.emptyListText}>{text}</Typography>
    </div>
  )
}
