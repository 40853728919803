import { makeStyles } from '@material-ui/core'

import { homeBackground } from '../../assets'

export const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: `url(${homeBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto',
    paddingTop: 50
  }
}))
