import { isSafari } from 'react-device-detect'
import ChromePlayer from './ChromePlayer'
import SafariPlayer from './safariPlayer'

const BitmovinPlayer = (props) => {
  if (isSafari) return <SafariPlayer {...props} />

  return <ChromePlayer {...props} />
}

export default BitmovinPlayer
