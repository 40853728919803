import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  socialAuthButtonsContainer: {
    display: 'flex',
    marginTop: 15,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center'
  },
  googleAuthButton: {
    flex: 1,
    backgroundColor: '#29292999',
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    height: '42px'
  },
  facebookAuthButton: {
    flex: 1,
    backgroundColor: '#29292999',
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    height: '42px',
    color: 'white'
  },
  startIcon: {
    position: 'absolute',
    left: '1rem',
    paddingLeft: '7px'
  },
  appleIcon: {
    color: 'white',
    height: 25,
    width: 25
  }
}))
