import validator from 'validator'
import { isMobileOnly, isTablet, isDesktop, isSmartTV } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

export const validateRegistrationForm = ({ firstName, lastName, gender, dob, password }) => {
  if (validator.isEmpty(firstName)) return false
  if (validator.isEmpty(lastName)) return false
  // if (gender === 'Gender') return false
  // if (validator.isEmpty(dob)) return false
  if (validator.isEmpty(password) || password.length < 8) return false

  return true
}

export const validateUserMissingDetail = (gender, dob, firstName, lastName) => {
  if (validator.isEmpty(firstName)) return false
  if (validator.isEmpty(lastName)) return false
  // if (gender === 'Gender') return false
  // if (validator.isEmpty(dob)) return false

  return true
}

export const validateLoginForm = (email, password) => {
  if (!validator.isEmail(email.trim().toLowerCase())) return false
  if (validator.isEmpty(password) || password.length < 8) return false

  return true
}

export const validateVerificationCode = (code) => {
  if (code.length < 6 || !validator.isNumeric(code)) return false

  return true
}

export const validateResetPasswordForm = (email, code, password) => {
  if (!validator.isEmail(email.trim().toLowerCase())) return false
  if (code.length < 6 || !validator.isNumeric(code)) return false
  if (validator.isEmpty(password) || password.length < 8) return false

  return true
}

export const detectDeviceType = () => {
  if (isMobileOnly) return 'Mobile'
  if (isTablet) return 'Tablet'
  if (isSmartTV) return 'TV'
  if (isDesktop) return 'Desktop'
}

export const validateEmail = (email) => {
  if (!validator.isEmail(email.trim().toLowerCase())) return false
  return true
}

export const validatePassword = (password) => {
  if (validator.isEmpty(password) || password.length < 8) return false
  return true
}

export const useQuery = () => new URLSearchParams(useLocation().search)

// hash color and opacity to rgba
export const hexToRGBA = (color, opacity) => {
  var value = color.match(/[A-Za-z0-9]{2}/g)
  value = value.map(function (v) {
    return parseInt(v, 16)
  })
  value = [...value, opacity]
  return 'rgba(' + value.join(',') + ')'
}
