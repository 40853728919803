import { makeStyles } from '@material-ui/styles'
import { color } from '../../../styles/color'

export const usePaneStyles = makeStyles((theme) => ({
  notificationMenuRoot: {
    marginTop: 35,
    boxShadow: 'none',
    maxHeight: 400,
    overflow: 'auto',
    '& .MuiPaper-root': {
      borderRadius: '0px',
      width: 270,
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customMenuContainer: {
    backgroundColor: 'white',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid lightgray'
  },
  customMenuItem: {
    padding: '3px 10px',
    backgroundColor: '#ffffff',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: color.buttonBlue,
      '& $menuTitle': {
        color: 'white'
      },
      '& $menuListIcon': {
        color: 'white'
      },
      '& $notificationTitle': {
        color: 'white'
      }
    }
  },
  menuListIconContainer: {
    paddingRight: '10px',
    paddingLeft: '0px'
  },
  menuListIcon: {
    color: '#141af5',
    fontSize: 10
  },
  notificationTitleContainer: {
    paddingRight: '5px'
  },
  notificationTitle: {
    fontSize: '11px',
    fontWeight: '600'
  },
  notificationTime: {
    color: 'gray',
    fontSize: '8px',
    fontWeight: '600',
    paddingTop: '3px',
    paddingBottom: '3px'
  },
  prfileAvatar: {
    backgroundColor: 'rgba(64, 155, 206, 0.4)',
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(64, 155, 206, 0.4)'
    }
  },
  nameAlphabet: {
    fontFamily: 'Pacifico',
    fontSize: 15,
    textTransform: 'uppercase'
  },
  menuRoot: {
    marginTop: 35,
    boxShadow: 'none',
    '& .MuiPaper-root': {
      width: 140,
      borderRadius: '0px',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  menuTitle: {
    fontWeight: '600',
    fontSize: '11px',
    padding: '5px 0px',
    color: '#000000',
    textTransform: 'uppercase'
  },
  topArrow: {
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: `10px solid white`
  },
  dropDownMenuName: {
    backgroundColor: 'lightgray',
    fontWeight: 600,
    fontSize: 11,
    paddingRight: 30,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 10,
    textAlign: 'left',
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgray',
    pointerEvents: 'none'
  }
}))

export const useSideDrawerStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    width: window.innerWidth,
    backgroundColor: color.primaryColor
  },
  linkText: {
    fontSize: 20,
    padding: '7px 15px',
    color: 'white'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  navLinksText: {
    fontSize: '0.8em',
    fontWeight: 'bold'
  },
  menuIconContainer: {
    height: 60,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 15
  },
  menuIcon: {
    color: 'white'
  },
  fixedMenu: {
    color: 'white'
  },
  drawerFooter: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.primaryColor
  },
  mintrioText: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '600'
  }
}))

export const useBackToTopStyles = makeStyles((theme) => ({
  backToTopIcon: {
    position: 'fixed',
    bottom: '60px',
    right: '100px',
    zIndex: '99',
    [theme.breakpoints.down('sm')]: {
      bottom: '80px',
      right: '40px'
    },
    [theme.breakpoints.down('xs')]: {
      bottom: '120px',
      right: '40px'
    }
  }
}))
