import { makeStyles, Button as MaterialButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { EVENT_CATEGORIES, ALERT_TYPES } from '../../../lib/constant'
import { fetchPricingForBanner } from '../../../api/pricings'
import { setMinimumPrice, setSymbol } from '../../../redux/modules/videos'
import { Container } from '.'
import { Button } from '../../../components/Button'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { color } from '../../../styles/color'
import { Alert } from '../../../components/alert'
import { showAlert } from '../../../redux/modules/alertHandler'

const RowButton = ({
  heading,
  content,
  price,
  onPress,
  backgroundColor,
  borderWidth,
  borderColor,
  paddingVertical,
  marginRight
}) => {
  let classes = useStyles()
  return (
    <MaterialButton
      onClick={onPress}
      style={{ backgroundColor, borderWidth, borderColor, paddingVertical, marginRight }}
      className={classes.buttonTrailer}
    >
      <span className={classes.parentSpan}>
        <span className={classes.headingText}>{heading}</span>
        {!isEmpty(content) && (
          <span className={classes.contentText}>
            {'\n '}
            {content}
          </span>
        )}
      </span>
      {!isEmpty(price) ? (
        <span className={classes.priceText}>{price}</span>
      ) : (
        <span className={classes.priceText}>-</span>
      )}
    </MaterialButton>
  )
}

export const WatchOptions = ({ openCheckoutModal, videoId, video, handlePlayPreviewTrailer }) => {
  const classes = useStyles()
  const { login } = useSelector((state) => state.user)
  const { price, symbol } = useSelector((state) => state.videos)
  const { countryCode } = useSelector((state) => state.location)
  const location = useLocation()
  const history = useHistory()

  const [prices, setPrices] = useState([])
  const [duration_mins, setDurationMins] = useState('')
  const [contains, setContains] = useState(false)
  const { trailers } = video
  const dispatch = useDispatch()
  const data = useParams()

  useEffect(() => {
    if (location.state && location.state.watchPreviewFlag) handlePlayPreviewTrailer()
  }, [location, handlePlayPreviewTrailer])

  useEffect(() => {
    let { videoId } = data
    if (!price || !symbol) {
      let accessToken = false
      if (countryCode && data) {
        const filter = {
          video_id: {
            eq: videoId
          },
          country_code: {
            eq: countryCode
          },
          status: {
            eq: true
          }
        }
        ;(async () => {
          const response = await fetchPricingForBanner(filter, accessToken, login)
          setPrices(response)
          dispatch(setSymbol({ symbol: response[0]?.country?.currency_symbol }))
        })()
      }
    }
  }, [price, symbol, countryCode, videoId, login, dispatch, data])

  useEffect(() => {
    if (prices.length) {
      let min = Math.min.apply(
        Math,
        prices.map(function (o) {
          return o.price
        })
      )

      // Reformat price if it is larger than 1000
      if (min > 9999) {
        min = min / 10000
        // Only display float if decimal is not going to followed by a 0 (e.g. 2.0)
        if (parseInt(min) !== parseFloat(min) && parseInt(min) != min.toFixed(1)) {
          min = min.toFixed(1)
        } else {
          min = parseInt(min)
        }
        min = `${min}K`
      }
      dispatch(setMinimumPrice({ price: min }))
      setContains(trailers?.items?.some((e) => e.type === 'CONTENT_PREVIEW'))
    }
  }, [prices, dispatch, trailers])

  useEffect(() => {
    let objectPreview = trailers?.items?.find((e) => e.type === 'CONTENT_PREVIEW')
    if (objectPreview) {
      let duration_object = JSON.parse(objectPreview?.additional_attributes)['duration']
      let duration_min =
        parseInt(duration_object['hours']) * 60 +
        parseInt(duration_object['minutes']) +
        Math.ceil(parseInt(duration_object['seconds']) / 60)
      setDurationMins(duration_min)
    }
  }, [setDurationMins, trailers])

  const handleRentNow = () => {
    openCheckoutModal()
    if (!login) {
      window.gtag('event', 'plan_view_attempt', {
        event_label: 'Video ID',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: videoId
      })
      window.fbq('trackCustom', 'plan_view_attempt', {
        event_label: 'Video ID',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: videoId
      })
    } else {
      window.gtag('event', 'plan_view', {
        event_label: 'Video ID',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: videoId
      })
      window.fbq('trackCustom', 'plan_view', {
        event_label: 'Video ID',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: videoId
      })
    }
  }

  const handleSignIn = () => {
    history.push(`/sign-in?redirectPage=${location.pathname}`, {
      checkoutModalVisibility: false,
      watchPreviewFlag: true
    })
  }

  const handleWatchNow = () => {
    if (!login) {
      dispatch(
        showAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Login to view FREE content',
          isVisible: true
        })
      )
      handleSignIn()
    } else {
      handlePlayPreviewTrailer()
    }
  }

  return (
    <>
      {!contains ? (
        <Button
          title={`${
            price === 0
              ? 'Watch For Free'
              : !!price
              ? `Rent Now From ${symbol?.length === 1 ? `${symbol}` : `${symbol + ' '}`}${price}`
              : 'Rent Now'
          }`}
          style={{
            backgroundColor: '#3A7248',
            color: 'white',
            width: '100%',
            border: '1px solid #3A7248'
          }}
          onClick={handleRentNow}
          hover
          className={classes.button}
        />
      ) : (
        <div style={{ display: 'flex' }}>
          {price === 0 ? (
            <Button
              title={'Watch for Free'}
              onClick={handleRentNow}
              style={{
                backgroundColor: '#3A7248',
                color: 'white',
                width: '100%',
                border: '1px solid #3A7248'
              }}
              className={classes.button}
            />
          ) : (
            <>
              <RowButton
                content={`${duration_mins} Min`}
                heading={'Watch Free'}
                price={`${
                  !!symbol ? `${symbol?.length === 1 ? `${symbol}` : `${symbol + ' '}`}0` : 'Free'
                }`}
                backgroundColor={'#3A7248'}
                borderColor={'#3A7248'}
                marginRight={'8px'}
                onPress={handleWatchNow}
              />
              <RowButton
                content={'From'}
                heading={'Rent Now'}
                price={`${
                  !!symbol
                    ? `${symbol?.length === 1 ? `${symbol}` : `${symbol + ' '}`}${price}`
                    : ''
                }`}
                onPress={handleRentNow}
                backgroundColor={'transparent'}
              />
            </>
          )}
        </div>
      )}
      <Alert />
    </>
  )
}

export const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex'
  },
  button: {
    width: 180,
    backgroundColor: 'rgba(40, 45, 219, 0.6)',
    border: '1px solid #3237E5',
    borderRadius: '3px',
    height: 40,
    color: 'white',
    fontSize: 12,
    fontWeight: '600'
  },
  buttonTrailer: {
    width: 170,
    backgroundColor: 'transparent',
    border: '1px solid white',
    borderRadius: '3px',
    height: 40,
    fontWeight: '600',
    color: color.white,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 0
  },
  parentSpan: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 10,
    padding: 0,
    height: '10px',
    verticalAlign: 'top'
  },
  contentText: {
    textTransform: 'none',
    fontSize: 10,
    padding: 0,
    verticalAlign: 'top'
  },
  priceText: {
    color: color.white,
    fontSize: 20
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flex: 1,
    borderRadius: 8,
    alignItems: 'center',
    maxWidth: '80%'
  },
  buttonHover: {
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: color.white
    },
    fontWeight: '600',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
}))
