import { API, Auth } from 'aws-amplify'

import { listVideosByStatus, getVideo } from '../../api/video'
import {
  listVideosByStatus as loggedInUserListVideos,
  getVideo as loggedInGetVideo
} from '../../graphql/queries'
import { getToken } from './user'

// constants
const SET_VIDEOS = 'SET_VIDEOS'
const SET_MIN_PRICE = 'SET_MIN_PRICE'
const SET_SYMBOL = 'SET_SYMBOL'
const RESET_VIDEOS = 'RESET_VIDEOS'
const SET_LOADING = 'SET_LOADING'
const SET_TOKEN = 'SET_TOKEN'

// actions
export const setVideos = (payload) => ({
  type: SET_VIDEOS,
  payload
})

export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload
})

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
})

export const resetVideos = (payload) => ({
  type: RESET_VIDEOS,
  payload
})

export const setMinimumPrice = (payload) => ({
  type: SET_MIN_PRICE,
  payload
})

export const setSymbol = (payload) => ({
  type: SET_SYMBOL,
  payload
})

export const fetchVideos = () => async (dispatch, getState) => {
  const {
    videos: { nextToken: token },
    user: { login }
  } = getState()

  // refresh token if expired
  if (login) await dispatch(getToken())

  if (token === 'start' || token) {
    dispatch(setToken({ nextToken: null }))
    dispatch(setLoading({ loading: true }))
    try {
      let session
      try {
        session = await Auth.currentSession()
      } catch (err) {
        session = null
      }

      const response = await API.graphql({
        query: session ? loggedInUserListVideos : listVideosByStatus,
        authMode: session ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
        variables: {
          statusValue: 'ENABLED',
          nextToken: token === 'start' ? null : token,
          limit: 10
        }
      })

      const {
        data: {
          listVideosByStatus: { items, nextToken }
        }
      } = response

      // sorting videos on release date
      items.sort(
        (first, second) => Date.parse(first.release_date) - Date.parse(second.release_date)
      )

      dispatch(setVideos({ videos: items || [], nextToken, loading: false }))
    } catch (error) {
      dispatch(setLoading({ loading: false }))
      throw error
    }
  }
}

export const getVideoById = (videoId) => async (dispatch, getState) => {
  const {
    videos: { videos },
    user: { login },
    myContent: { orders }
  } = getState()
  const filteredVideos = videos.filter(({ id }) => id === videoId)
  const filteredOrders = orders.filter((order) => order.video_id === videoId)
  if (filteredVideos.length > 0) return filteredVideos[0]
  if (filteredOrders.length > 0) return filteredOrders[0].video

  try {
    const {
      data: { getVideo: video }
    } = await API.graphql({
      query: login ? loggedInGetVideo : getVideo,
      authMode: login ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
      variables: { id: videoId }
    })

    if (!video) throw new Error('Video not exists')

    return video
  } catch (error) {
    throw error
  }
}

// reducer
const initialState = {
  videos: [],
  nextToken: 'start',
  loading: false,
  price: '',
  symbol: ''
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_VIDEOS:
      return {
        videos: [...state.videos, ...payload.videos],
        loading: payload.loading,
        nextToken: payload.nextToken
      }
    case SET_LOADING:
      return {
        ...state,
        loading: payload.loading
      }
    case SET_TOKEN:
      return {
        ...state,
        nextToken: payload.nextToken
      }
    case RESET_VIDEOS:
      return {
        ...payload
      }
    case SET_MIN_PRICE:
      return {
        ...state,
        price: payload.price
      }
    case SET_SYMBOL:
      return {
        ...state,
        symbol: payload.symbol
      }
    default:
      return state
  }
}

export default reducer
