import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core'
// import Amplify from 'aws-amplify'
import 'typeface-rubik'

import './index.css'
import { App } from './app/App'
import reportWebVitals from './reportWebVitals'
import { theme } from './styles/theme'
// import awsconfig from './aws-exports'
import './initAmplify'

require('dotenv').config()

// Amplify.configure(awsconfig)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
