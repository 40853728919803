import { makeStyles } from '@material-ui/styles'
import { color } from '../.././../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'center',
    margin: '30px 10px 10px 10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    backgroundColor: color.buttonPrimaryColor,
    color: color.white,
    '&:hover': {
      backgroundColor: color.buttonBlue
    }
  },
  selected: {
    backgroundColor: color.buttonBlue
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  displayText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  inputField: {
    color: 'white',
    border: 'none',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    margin: '0px 5px 15px 0px',
    height: 35,
    backgroundColor: 'rgba(234,234,234,0.2)',
    backdropFilter: 'blur(30px)',
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  codeInput: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flag: {
    height: 17,
    border: '1px solid gray',
    borderRadius: 3
  },
  codeText: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerContainer: {
    height: 120,
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    color: 'white',
    width: 240,
    height: 40,
    fontWeight: '600',
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: 'white'
    }
  }
}))
