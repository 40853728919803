import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../../styles/color'

export const useStyles = makeStyles(() => ({
  cryptoMainContainer: {
    marginTop: 30
  },
  cryptoButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'rgb(0,44,116)',
    margin: '30px 10px 10px 10px',
    borderRadius: 5
  },
  openInBrowserContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    width: '100%'
  },
  button: {
    color: 'white',
    backgroundColor: color.buttonBlue,
    width: '100%',
    '&:hover': {
      backgroundColor: color.buttonBlue
    },
    height: 40
  },
  notSupportedText: {
    color: 'white',
    fontSize: 14,
    paddingTop: 10
  }
}))
