import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postLogoutUser } from '../redux/modules/user'

export const withAuthenticator = (ProtectedRoutes, AuthRoutes) => (props) => {
  const { login } = useSelector((state) => state.user)
  const [authenticated, setAuthenticated] = useState(login)

  const dispatch = useDispatch()

  //   check auth state
  async function checkAuthState() {
    try {
      // if authenticated allow user
      await Auth.currentAuthenticatedUser()
      setAuthenticated(true)
    } catch (err) {
      // if not authenticated but redux have the login true logout user and clear state
      if (login) dispatch(postLogoutUser())
      setAuthenticated(false)
    }
  }
  useEffect(() => {
    checkAuthState()
  })
  if (authenticated) return <ProtectedRoutes {...props} />

  return <AuthRoutes {...props} />
}
