import { createMuiTheme } from '@material-ui/core'

import { color } from './color'

const defaultTheme = createMuiTheme({
  palette: {
    secondary: {
      main: color.white
    },
    primary: {
      main: color.buttonRed
    }
  }
})

const { breakpoints } = defaultTheme

export const theme = createMuiTheme({
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '4rem',
        [breakpoints.down('sm')]: {
          fontSize: '1.5em',
          fontWeight: 'bold'
        },
        [breakpoints.down('xs')]: {
          fontSize: '2em',
          fontWeight: 'bold'
        }
      },
      h2: {
        fontSize: '2rem',
        [breakpoints.down('sm')]: {
          fontSize: '1.5em'
        },
        [breakpoints.down('xs')]: {
          fontSize: '1em'
        }
      },
      subtitle1: {
        fontSize: '1.5em',
        [breakpoints.down('sm')]: {
          fontSize: '1em'
        },
        [breakpoints.down('xs')]: {
          fontSize: '1em'
        }
      }
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: '#8e8e99'
        },
        color: color.white
      }
    },
    MuiInputBase: {
      root: {
        '&::placeholder': {
          color: '#8e8e99'
        },
        color: color.white
      }
    }
  }
})
