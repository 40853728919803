import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { Button } from '../../../components/Button'
import { SUPPORT_EMAIL } from '../../../lib/constant'

export const ContactUs = () => {
  const classes = useStyles()
  const { login, name, email } = useSelector((state) => state.user)

  const handleLiveChat = () => {
    if (window.zE) {
      window.zE('webWidget', 'show')
      window.zE('webWidget', 'open')
      if (login)
        window.zE('webWidget', 'prefill', {
          name: { value: name, readOnly: true },
          email: { value: email, readOnly: true }
        })
    }
  }

  return (
    <>
      <div className={classes.mainContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.text}>Want to contact us?</Typography>
          <div className={classes.buttonContainer}>
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              target={'_blank'}
              className={classes.link}
              rel="noreferrer">
              <Button title={'Email us'} className={classes.button} />
            </a>
          </div>
          <div className={classes.buttonContainer}>
            <Button title={'Live Chat'} onClick={handleLiveChat} className={classes.button} />
          </div>
        </div>
      </div>
      {/* <div className={classes.mainContainerAddress}>
        <div className={classes.mainDivAddress}>
          <Typography className={classes.textAddress}>
            Address: A-82, Phase 2, Gulshan Jamal, Karachi East, Karachi East
          </Typography>
        </div>
      </div> */}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  // mainContainerAddress: {
  //   padding: 20,
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   textAlign: 'center'
  // },
  buttonContainer: {
    padding: '0px 10px'
  },
  button: {
    margin: 0,
    backgroundColor: 'rgba(43, 42, 66, 0.8)',
    color: 'white',
    borderRadius: 5,
    height: 36,
    fontWeight: '300',
    '&:hover': {
      backgroundColor: 'rgba(43, 42, 66, 0.8)'
    }
  },
  text: {
    paddingRight: 30,
    color: 'white',
    fontSize: 16,
    textTransform: 'uppercase',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  // textAddress: {
  //   paddingRight: 30,
  //   color: 'white',
  //   fontSize: 14,
  //   textTransform: 'uppercase',
  //   alignItems: 'center',
  //   [theme.breakpoints.down('xs')]: {
  //     flex: 1
  //   }
  // },
  // mainDivAddress: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'unset'
  //   }
  // },
  link: {
    textDecoration: 'none'
  }
}))
