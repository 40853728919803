import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { makeStyles, Grid, Snackbar, Typography } from '@material-ui/core'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { persistor, store } from './configureStore'
import { Routes } from './Routes'
import { ErrorBoundary } from './errorBoundry'

const useStyles = makeStyles({
  mainRoot: {
    flexGrow: 1
  },
  root: {
    color: 'white',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'rgb(235,108,32)'
  },
  content: {
    display: 'flex'
  },
  alertContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 13
  },
  alertIcon: {
    color: 'white',
    marginRight: 15
  }
})

export function App() {
  const classes = useStyles()
  const [offline, setOffline] = useState(false)

  useEffect(() => {
    window.addEventListener('online', activeNetwork)
    window.addEventListener('offline', inActiveNetwork)
    window.zE('webWidget', 'hide')
    window.zE('webWidget:on', 'close', function () {
      window.zE('webWidget', 'hide')
    })
    return () => {
      window.removeEventListener('offline', inActiveNetwork)
      window.removeEventListener('online', activeNetwork)
    }
  })

  const activeNetwork = () => setOffline(false)
  const inActiveNetwork = () => setOffline(true)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className={classes.mainRoot}>
          <Grid container direction="column">
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Grid>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={offline}
            message={
              <div className={classes.content}>
                <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
                <Typography className={classes.alertContent}>
                  {'You are not connected to network!'}
                </Typography>
              </div>
            }
            key={'network-error'}
            ContentProps={{
              classes: {
                root: classes.root
              }
            }}
          />
        </div>
      </PersistGate>
    </Provider>
  )
}
