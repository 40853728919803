import React from 'react'
import { Grid } from '@material-ui/core'

export function GridContainer({ children, navigation, gutter, ...otherProps }) {
  return (
    <>
      <Grid item container {...otherProps}>
        <Grid item xs={gutter ? 1 : false} sm={1} />
        <Grid item xs={gutter ? 10 : 12} sm={10}>
          {children}
        </Grid>
        <Grid item xs={gutter ? 1 : false} sm={1} />
      </Grid>
    </>
  )
}
