import { updateVideoViewLog } from '../../api/viewLogs'
import errorHandler from '../../lib/errorHandler'

//constants
const INITIALIZE_LOGS = 'INITIALIZE_LOGS'
const UPDATE_LOG = 'UPDATE_LOG'
const UPDATE_MINUTES_STREAMED = 'UPDATE_MINUTES_STREAMED'
const RESET_LOG = 'RESET_LOG'

export const intializeLogs = (videoId, id) => ({
  type: INITIALIZE_LOGS,
  payload: { videoId, id }
})

export const updateLogs = (videoId, logData) => async (dispatch, getState) => {
  const { viewLogs, location } = getState()
  const ishratLogs = viewLogs[videoId]
  const log = { ...logData, timestamp: Date.now(), ip_address: location.query }

  dispatch({
    type: UPDATE_LOG,
    payload: {
      videoId,
      log
    }
  })

  // sync data if 2 minutes exceed
  if (
    ishratLogs &&
    ishratLogs.minutes_streamed &&
    (log.timestamp - ishratLogs.timestamp) / 60000 >= 2
  ) {
    dispatch(syncLogs(false, videoId))
  }
}

export const updateMintuesStreamed = (videoId, minutes_streamed) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_MINUTES_STREAMED,
    payload: {
      videoId,
      minutes_streamed
    }
  })
}

//actions
export const syncLogs = (reset, videoId) => async (dispatch, getState) => {
  try {
    const { viewLogs } = getState()
    const ishratLogs = viewLogs[videoId]

    if (ishratLogs && ishratLogs.minutes_streamed) {
      const { id, minutes_streamed, last_timestamp, ip_address, ended_at } = ishratLogs

      // update local ishrat streaming data
      dispatch({
        type: 'UPDATE_STREAMING_PARAMETERS',
        payload: {
          minutes_streamed,
          last_timestamp,
          videoId
        }
      })

      if (last_timestamp) {
        const response = await updateVideoViewLog(
          id,
          {
            minutes_streamed,
            last_timestamp,
            ip_address,
            ended_at
          },
          dispatch
        )

        // reset data
        if (response && reset)
          dispatch({
            type: RESET_LOG,
            payload: {
              videoId
            }
          })
      }
    }
  } catch (err) {
    errorHandler.report(err)
  }
}

export const uploadRemainingLogs = () => (dispatch, getState) => {
  const { viewLogs } = getState()
  const videoIds = Object.keys(viewLogs).filter((id) => viewLogs[id] || false)
  videoIds.forEach((id) => {
    dispatch(syncLogs(true, id))
  })
}

//reducer
const initialState = {}

const viewLogs = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case UPDATE_LOG:
      return {
        ...state,
        [payload.videoId]: { ...state[payload.videoId], ...payload.log }
      }

    case UPDATE_MINUTES_STREAMED:
      return {
        ...state,
        [payload.videoId]: { ...state[payload.videoId], minutes_streamed: payload.minutes_streamed }
      }
    case INITIALIZE_LOGS:
      return {
        ...state,
        [payload.videoId]: { id: payload.id }
      }
    case RESET_LOG:
      return {
        ...state,
        [payload.videoId]: null
      }
    default:
      return state
  }
}

export default viewLogs
