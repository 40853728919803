import React from 'react'
import { useSelector } from 'react-redux'
import {
  StripeForm,
  PaypalForm,
  CryptoForm,
  JazzCashForm,
  PaymentCheckoutLayout,
  EasyPaisaForm
} from './components'

export const PaymentCheckout = ({ plans, videoId, handleStep, setProcessing, accessToken }) => {
  const { paymentGateway } = useSelector((state) => state.checkout)

  if (!paymentGateway) return null
  switch (paymentGateway.name) {
    case 'STRIPE':
      return (
        <PaymentCheckoutLayout plans={plans} videoId={videoId}>
          <StripeForm accessToken={accessToken} videoId={videoId} handleStep={handleStep} />
        </PaymentCheckoutLayout>
      )
    case 'JAZZ_CASH':
      return (
        <PaymentCheckoutLayout plans={plans} videoId={videoId}>
          <JazzCashForm paymentMethod={paymentGateway} />
        </PaymentCheckoutLayout>
      )
    case 'EASY_PAISA':
      return (
        <PaymentCheckoutLayout plans={plans} videoId={videoId}>
          <EasyPaisaForm paymentMethod={paymentGateway} handleStep={handleStep} />
        </PaymentCheckoutLayout>
      )
    case 'PAYPAL':
      return (
        <PaymentCheckoutLayout plans={plans} videoId={videoId}>
          <PaypalForm accessToken={accessToken} handleStep={handleStep} />
        </PaymentCheckoutLayout>
      )
    case 'CRYPTO':
      return (
        <PaymentCheckoutLayout plans={plans} videoId={videoId}>
          <CryptoForm handleStep={handleStep} />
        </PaymentCheckoutLayout>
      )
    default:
      return null
  }
}
