import { makeStyles, Typography, Grid } from '@material-ui/core'
import { color } from '../../styles/color'

import { logo } from '../../assets'

export function MintrioOriginal(props) {
  const classes = useStyles()
  const { style, contentType } = props
  return (
    <Grid alignItems="center" justify="center" direction="row" container>
      <img src={logo} alt="Mintrio Logo" className={classes.img} style={style} />
      <Typography color="secondary" className={classes.orignal}>
        &nbsp; Mintrio
      </Typography>
      <span className={classes.spanOriginal}>&nbsp;Original</span>
      <Typography className={classes.content}>{contentType}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  img: {
    alignSelf: 'center',
    height: '28px',
    [theme.breakpoints.down('xs')]: {
      height: '20px'
    }
  },
  orignal: {
    color: color.white,
    fontSize: 20,
    fontFamily: 'Rubik',
    fontWeight: 'bold',
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  spanOriginal: {
    color: color.white,
    fontSize: 20,
    fontFamily: 'Rubik',
    fontWeight: '200',
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  content: {
    color: color.lightGrey,
    fontWeight: '400',
    fontSize: 23,
    fontFamily: 'Rubik',
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  }
}))
