import { Button } from '../../../../Button'
import { useStyles } from './styles'

export const Footer = ({ closeModal }) => {
  const classes = useStyles()
  return (
    <div className={classes.thFooterContainer}>
      <Button onClick={closeModal} title={'DONE'} className={classes.button} />
    </div>
  )
}
