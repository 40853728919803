import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { Footer, TransactionAccordion, TransactionTable } from './components'
import { setTransactionHistoryModal } from '../../../redux/modules/globalModals'
import { fetchTransactions } from '../../../api/order'
import { useStyles } from './styles'
import { Header } from '../header'
import { ALERT_TYPES } from '../../../lib/constant'
import { showAlert } from '../../../redux/modules/alertHandler'
import errorHandler from '../../../lib/errorHandler'
import { isMobileOnly } from 'react-device-detect'

export const TransactionHistory = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])

  const { userId } = useSelector((state) => state.user)
  const { isTransactionHistoryVisbile } = useSelector((state) => state.globalModals)
  const dispatch = useDispatch()

  const closeModal = () => dispatch(setTransactionHistoryModal(false))

  useEffect(() => {
    if (userId && isTransactionHistoryVisbile) {
      ;(async () => {
        try {
          setLoading(true)
          const orders = await fetchTransactions(userId)
          setTransactions(orders)
          setLoading(false)
        } catch (err) {
          errorHandler.report(err)
          setLoading(false)
          dispatch(
            showAlert({
              type: ALERT_TYPES.ERROR,
              message: 'Unable to fetch transactions. Please try again',
              isVisible: true
            })
          )
        }
      })()
    }
  }, [userId, isTransactionHistoryVisbile, dispatch])
  return (
    <Modal
      open={isTransactionHistoryVisbile}
      className={classes.modal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <Header title={'Payment History'} closeModal={closeModal} />
        {loading ? (
          <div className={classes.transactionLoader}>
            <CircularProgress size={24} className={classes.progressSpinner} />
          </div>
        ) : (
          transactions.length > 0 && (
            <div className={classes.historyContainer}>
              {isMobileOnly ? (
                <TransactionAccordion transactions={transactions} />
              ) : (
                <Grid container>
                  <Grid item lg={1} xl={2} md={1} sm={0} xs={0}></Grid>
                  <Grid item lg={10} xl={8} md={10} sm={12} xs={12}>
                    <TransactionTable transactions={transactions} />
                  </Grid>
                </Grid>
              )}
            </div>
          )
        )}

        {!loading && transactions.length === 0 && (
          <div className={classes.emptyContainer}>You do not have any transaction history.</div>
        )}

        <Footer closeModal={closeModal} />
      </div>
    </Modal>
  )
}
