import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { withAuthenticator } from './withAuthenticator'
import { makeStyles } from '@material-ui/core'

import { Login, SignUp, Verification, ResetPassword } from '../pages/auth'
import { Home } from '../pages/Home/index'
import { PageNotFound } from '../pages/404'
import { Help } from '../pages/Help'
import { Preview } from '../pages/Preview'
import { MyContent } from '../pages/MyContent'
import { fetchVideos } from '../redux/modules/videos'

import {
  TrailerPlayerModal,
  VideoPlayerModal,
  WatchOnMobileModal,
  TransactionHistory,
  UpdateProfile,
  UserMissingDetail
} from '../components/modals'
import { fetchLocation } from '../redux/modules/location'
import { getDeviceDetail } from '../redux/modules/deviceType'
import { uploadRemainingLogs } from '../redux/modules/viewLogs'
import { ALERT_TYPES, AUTHENTICATION_PAGES, EVENT_CATEGORIES } from '../lib/constant'
import { Alert } from '../components/alert'
import { Navigation } from '../components/Navigation'
import { HowToWatch } from '../pages/howToWatch.js'
import { color } from '../styles/color'
import { showAlert } from '../redux/modules/alertHandler'
import errorHandler from '../lib/errorHandler'
import { MobileCheckout } from '../pages/mobileCheckout'
import { fetchLandingPageData } from '../redux/modules/landingPage'
import { Auth, Hub } from 'aws-amplify'
import { setUserData } from '../redux/modules/user'
import { FullScreenSpinner } from '../components/modals/fullScreenSpinner'
import { setFullScreenSpinner } from '../redux/modules/globalModals'

const ProtectedRoutes = () => {
  return (
    <Switch>
      <Route exact path="/my-content" component={MyContent} />
      <Route path="/*" component={ProtectedPageNotFound} />
    </Switch>
  )
}

const ProtectedPageNotFound = () => {
  const location = useLocation()
  const history = useHistory()
  if (
    AUTHENTICATION_PAGES.includes(location.pathname) ||
    location.pathname.includes('/email-verification')
  )
    history.replace('/')
  return <PageNotFound />
}

const AuthenticationRoutes = () => {
  return (
    <Switch>
      <Route exact path="/sign-in" component={Login} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/email-verification/:email" component={Verification} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="/*" component={Login} />
    </Switch>
  )
}

const PagesWithAuth = withAuthenticator(ProtectedRoutes, AuthenticationRoutes)

export function Routes() {
  const { login, name, email } = useSelector((state) => state.user)
  const { isPlayerModalVisible } = useSelector((state) => state.videoPlayer)
  const { isWatchOnMobileModalVisible } = useSelector((state) => state.globalModals)
  const [openUserDetailModal, setOpenUserDetailModal] = useState(false)
  const [redirectPage, setRedirectPage] = useState('')
  const [signInEvent, setSignInEvent] = useState(null)

  const classes = useStyles()

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      if (!login) {
        const currentUser = await Auth.currentAuthenticatedUser()
        const {
          attributes: { email, sub: userId, gender, birthdate: dob, name, given_name, identities }
        } = currentUser
        dispatch(
          setUserData({
            email,
            userId,
            login: true,
            gender,
            dob,
            name: name + ` ${given_name || ''}` || ''
          })
        )
        var identities_object = JSON.parse(identities)

        window.gtag('event', 'sign_in', {
          event_label: 'Email Address',
          event_category: EVENT_CATEGORIES.SIGN_IN,
          event_value: email,
          provider_name: identities_object[0].providerName
        })
      }
    })()
  }, [login, dispatch])

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setSignInEvent(true)
          break
        case 'customOAuthState':
          setRedirectPage(data)
          break
      }
    })

    return unsubscribe
  }, [setRedirectPage, setSignInEvent])

  useEffect(() => {
    if (signInEvent) {
      ;(async () => {
        dispatch(setFullScreenSpinner({ loading: true, loadingText: 'Signing you in...' }))
        Auth.currentAuthenticatedUser()
          .then((currentUser) => {
            const {
              attributes: { email, sub: userId, gender, birthdate: dob, name },
              username
            } = currentUser
            if (
              username.includes('facebook') ||
              username.includes('google') ||
              username.includes('signinwithapple')
            ) {
              dispatch(setUserData({ email, userId, login: true, gender, dob, name: name || '' }))
            }

            dispatch(setFullScreenSpinner({ loading: false, loadingText: '' }))
            if (redirectPage) window.location.href = `${window.location.origin}${redirectPage}`
          })
          .catch((err) => {
            console.log('Error is: ,', err)
            dispatch(setFullScreenSpinner({ loading: false, loadingText: '' }))
            dispatch(
              showAlert({
                type: ALERT_TYPES.ERROR,
                message: err.message || 'Unable to sign you in. Please try again',
                isVisible: true
              })
            )
          })
      })()
    }
  }, [redirectPage, signInEvent, dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchVideos())
      } catch (err) {
        errorHandler.report(err)
        dispatch(
          showAlert({
            type: ALERT_TYPES.ERROR,
            message: 'Unable to fetch videos please try again',
            isVisible: true
          })
        )
      }
    })()

    if (login) {
      dispatch(getDeviceDetail())
      dispatch(uploadRemainingLogs())
    }
  }, [dispatch, login])

  useEffect(() => {
    ;(async () => {
      await dispatch(fetchLocation())
    })()
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      await dispatch(fetchLandingPageData())
    })()
  }, [dispatch])

  useEffect(() => {
    if (login && (!name || !email)) {
      setOpenUserDetailModal(true)
    } else setOpenUserDetailModal(false)
  }, [login, name, email, setOpenUserDetailModal])

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/how-to-watch" component={HowToWatch} />
        <Route exact path="/preview/:videoId" component={Preview} />
        <Route exact path="/mobile-checkout" component={MobileCheckout} />
        <Route path="/*" component={PagesWithAuth} />
      </Switch>
      <TrailerPlayerModal />
      {login && (
        <>
          {isPlayerModalVisible && <VideoPlayerModal />}
          {isWatchOnMobileModalVisible && <WatchOnMobileModal />}
          <UserMissingDetail open={openUserDetailModal} />
          <TransactionHistory />
          <UpdateProfile />
        </>
      )}

      <Alert />
      <FullScreenSpinner />
      <div className={classes.navbarContainer}>
        <Navigation />
      </div>
    </Router>
  )
}

const useStyles = makeStyles(() => ({
  navbarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 5
  },
  pagesTopContainer: {
    backgroundColor: color.primaryColor,
    height: '100vh'
  }
}))
