import React from 'react'
import { Typography } from '@material-ui/core'

import { formHeaderStyles } from './styles'

export const FormHeader = ({ title, subTitle }) => {
  const classes = formHeaderStyles()
  return (
    <div className={classes.formHeaderContainer}>
      <Typography color="secondary" variant="h2" align="center" className={classes.h2}>
        {title}
      </Typography>
      <Typography
        color="secondary"
        variant="subtitle1"
        align="center"
        className={classes.subtitle1}>
        {subTitle}
      </Typography>
    </div>
  )
}
