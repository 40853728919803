import { useState, useReducer, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Auth } from 'aws-amplify'

import { postLoginUser, setRegistrationData } from '../../../redux/modules/user'
import { validateEmail, validateLoginForm, validatePassword } from '../../../lib/utils'
import { useStyles } from './styles'
import { addNewLog } from '../../../redux/modules/userActivityLogs'
import { showAlert } from '../../../redux/modules/alertHandler'
import { ALERT_TYPES, USER_ACTIVITY_ACTIONS, EVENT_CATEGORIES } from '../../../lib/constant'
import errorHandler from '../../../lib/errorHandler'
import {
  AuthenticationPagesWrapper,
  TextInput,
  Button,
  DividerWithText,
  SocialAuthButtons,
  HelmetMetaData
} from '../../../components'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const Login = () => {
  const [{ email, emailError, password, passwordError, isValid }, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      password: '',
      isValid: false,
      emailError: false,
      passwordError: false
    }
  )

  const [loading, setLoading] = useState(false)
  let query = useQuery()
  const redirectPage = query.get('redirectPage')

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const handleUserNotConfirmed = async (email) => {
    await Auth.resendSignUp(email)
    history.push(`/email-verification/${email}`)
  }

  const postLoginNavigation = () => {
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SIGN_IN, {
        email_address: email,
        device_type_id: deviceTypeId,
        ip_address: locationData.query,
        country_code: locationData.countryCode
      })
    )

    window.gtag('event', 'sign_in', {
      event_label: 'Email Address',
      event_category: EVENT_CATEGORIES.SIGN_IN,
      event_value: email,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })

    window.fbq('trackCustom', 'sign_in', {
      event_label: 'Email Address',
      event_category: EVENT_CATEGORIES.SIGN_IN,
      event_value: email,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })

    if (redirectPage)
      history.replace(redirectPage, {
        checkoutModalVisibility: location.state.checkoutModalVisibility,
        watchPreviewFlag: location.state.watchPreviewFlag
      })
    else if (window.location.pathname === '/my-content') window.location.reload()
    else history.replace('/')
  }

  async function loginUser(event) {
    if (event) event.preventDefault()

    if (loading) return

    try {
      setLoading(true)
      await dispatch(postLoginUser(email, password))

      postLoginNavigation()
    } catch (error) {
      errorHandler.report(error)
      if (error.code && error.code === 'UserNotConfirmedException') handleUserNotConfirmed(email)
      else {
        setLoading(false)
        dispatch(
          showAlert({
            message: error.message || 'Invalid username/password. Please try again.',
            type: ALERT_TYPES.ERROR,
            isVisible: true
          })
        )
      }
    }
  }

  const navigateToSignUp = () =>
    history.push(redirectPage ? `/sign-up?redirectPage=${redirectPage}` : '/sign-up')

  const handleChange = ({ target: { value, name } }) => setData({ [name]: value })

  useEffect(() => {
    if (validateLoginForm(email, password)) setData({ isValid: true })
    else setData({ isValid: false })

    dispatch(setRegistrationData({ password }))
  }, [email, password, dispatch])

  const navigateToResetPassword = () => history.push('/reset-password')

  const emailValidation = () => {
    if (validateEmail(email)) setData({ emailError: false })
    else setData({ emailError: true })
  }

  const passwordValidation = () => {
    if (validatePassword(password)) setData({ passwordError: false })
    else setData({ passwordError: true })
  }

  return (
    <>
      <HelmetMetaData
        title={'Mintrio - Sign In'}
        description={'Sign In to mintrio'}
        hashtag={'#mintrio-signin'}
      />
      <AuthenticationPagesWrapper>
        <Grid
          container
          item
          direction="column"
          md={4}
          xs={10}
          style={{ margin: '0 auto', paddingTop: 150 }}
        >
          <Grid item xs={12} className={classes.container} justify="center" direction="column">
            <form onSubmit={loginUser}>
              <Typography color="secondary" variant="h2" align="center" className={classes.h2}>
                WELCOME BACK
              </Typography>
              <Typography
                color="secondary"
                variant="subtitle1"
                align="center"
                className={classes.subtitle1}
              >
                SIGN IN TO YOUR ACCOUNT
              </Typography>
              <TextInput
                onBlur={emailValidation}
                onFocus={() => setData({ emailError: false })}
                value={email}
                error={emailError}
                name="email"
                onChange={handleChange}
                placeholder="Email Address"
                className={classes.input}
                style={{ marginTop: '1.5em', marginBottom: '1em' }}
              />
              <TextInput
                onBlur={passwordValidation}
                onFocus={() => setData({ passwordError: false })}
                value={password}
                error={passwordError}
                name="password"
                onChange={handleChange}
                placeholder="Password"
                type="password"
                className={classes.input}
              />
              <Typography
                color="secondary"
                align="right"
                variant="subtitle1"
                role="presentation"
                onClick={navigateToResetPassword}
                className={classes.subtitle2}
              >
                Forgot password?
              </Typography>
              <Button
                onClick={loginUser}
                title="Sign in"
                disabled={!isValid}
                color="primary"
                hover
                style={{ width: '100%' }}
                loading={loading}
                type="submit"
              />
            </form>
            <SocialAuthButtons redirectPage={redirectPage} />
            <DividerWithText>OR</DividerWithText>
            <Button
              onClick={navigateToSignUp}
              title="Sign up"
              color="primary"
              hover
              style={{ width: '100%', marginTop: 0, marginBottom: 20 }}
            />
          </Grid>
        </Grid>
      </AuthenticationPagesWrapper>
    </>
  )
}
