import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { KeyboardArrowRightOutlined } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

import { useStyles } from './styles'
import {
  CHECKOUT_STEPS,
  PAYMENT_METHODS,
  USER_ACTIVITY_ACTIONS
} from '../../../../../../lib/constant'
import { setPaymentGateway } from '../../../../../../redux/modules/checkout'
import { PaymentIcon } from '../../..'
import { addNewLog } from '../../../../../../redux/modules/userActivityLogs'

export const PaymentMethodItem = ({ paymentMethod, handleStep, videoId }) => {
  const classes = useStyles()
  const { paymentGateway } = useSelector((state) => state.checkout)
  const { name, id } = paymentMethod
  const dispatch = useDispatch()

  const handleSelect = () => {
    dispatch(setPaymentGateway(paymentMethod))
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SELECT_PAYMENT_GATEWAY, {
        video_id: videoId,
        country: paymentMethod.country_code,
        name: paymentMethod.name
      })
    )
    handleStep(CHECKOUT_STEPS.CHECKOUT)
  }

  return (
    <div
      role="presentation"
      onClick={handleSelect}
      className={
        paymentGateway && paymentGateway.id === id
          ? clsx(classes.itemContainer, classes.selected)
          : classes.itemContainer
      }>
      <Typography className={classes.paymentMethodTitle}>{PAYMENT_METHODS[name]}</Typography>
      <div className={classes.visaImagesContainer}>
        <PaymentIcon name={name} />
      </div>
      <div className={classes.rightArrowIcon}>
        <KeyboardArrowRightOutlined className={classes.rightArrow} />
      </div>
    </div>
  )
}
