import { Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { purchaseDone } from '../../../../assets'
import { Button } from '../../../../components/Button'
import { USER_ACTIVITY_ACTIONS } from '../../../../lib/constant'
import { addNewLog } from '../../../../redux/modules/userActivityLogs'
import { orderDetail } from '../../../../api/order'

import { useGreetingsStyles } from './styles'

export const Greetings = ({ closeCheckoutModal, videoId, checkoutSource }) => {
  const classes = useGreetingsStyles()
  const dispatch = useDispatch()
  const { userId } = useSelector((state) => state.user)
  const { countryCode: country_code } = useSelector((state) => state.location)

  useEffect(() => {
    ;(async () => {
      const response = await orderDetail(userId, videoId, country_code, dispatch)
      const checkout_source = checkoutSource !== null ? checkoutSource : 'webApp'
      const coupon = response.promo_code ? response.promo_code.code : null
      const percentage_off = response.promo_code ? response.promo_code.percentage_off : 0
      const original_price = response.promo_code
        ? response.payment_total / (1 - response.promo_code.percentage_off / 100)
        : response.payment_total
      const discount = original_price - response.payment_total

      window.gtag('event', 'add_payment_info', {
        currency: response.country.currency,
        value: response.payment_total,
        payment_type: response.payment_gateway.name,
        plan_id: response.video_plan.plan_id,
        plan_name: response.video_plan.plan.title,
        coupon,
        percentage_off,
        checkout_source: checkout_source,
        items: [
          {
            item_id: videoId,
            location_id: country_code,
            plan_id: response.video_plan.plan_id,
            plan_name: response.video_plan.plan.title,
            currency: response.country.currency,
            price: original_price,
            quantity: 1,
            discount
          }
        ]
      })

      window.gtag('event', 'purchase', {
        transaction_id: response.id,
        value: response.payment_total,
        currency: response.country.currency,
        payment_type: response.payment_gateway.name,
        plan_id: response.video_plan.plan_id,
        plan_name: response.video_plan.plan.title,
        coupon,
        percentage_off,
        checkout_source: checkout_source,
        items: [
          {
            item_id: videoId,
            item_name: response.video.title,
            plan_id: response.video_plan.plan_id,
            plan_name: response.video_plan.plan.title,
            currency: response.country.currency,
            price: original_price,
            quantity: 1,
            discount
          }
        ]
      })

      window.fbq('track', 'Purchase', {
        transaction_id: response.id,
        value: response.payment_total,
        currency: response.country.currency,
        payment_type: response.payment_gateway.name,
        plan_id: response.video_plan.plan_id,
        plan_name: response.video_plan.plan.title,
        coupon,
        percentage_off,
        checkout_source: checkout_source,
        discount,
        price: original_price,
        video_id: videoId
      })

      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.CHECKOUT, {
          video_id: videoId,
          country: country_code,
          price: original_price,
          payment_total: response.payment_total,
          currency: response.country.currency,
          plan_id: response.video_plan.id,
          plan_name: response.video_plan.name,
          payment_gateway: response.payment_gateway.name,
          coupon,
          percentage_off,
          discount
        })
      )
    })()
  }, [videoId, country_code, userId, checkoutSource, dispatch])

  const close = () => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('true')
    else closeCheckoutModal(true)
  }

  return (
    <div className={classes.mainContainer}>
      <Typography className={`${classes.text} ${classes.heading}`}>Thank you!</Typography>
      <Typography className={classes.text}>Your order details have been emailed to you.</Typography>
      <div className={classes.imageContainer}>
        <img src={purchaseDone} alt="Thank you" className={classes.image} />
      </div>
      <div className={classes.footer}>
        <Button title="Continue" className={classes.button} onClick={close} />
      </div>
    </div>
  )
}
