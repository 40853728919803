export class QuarterlyFlags {
  constructor({ updateFlags }) {
    const videoPlayer = document.getElementById('bitmovinplayer-video-player')

    const offset = 0.2
    let first = false
    let second = false
    let third = false
    let fourth = false
    let fifth = false
    let sixth = false
    let seventh = false
    let eighth = false
    let ninth = false
    let tenth = false

    let duration = 0

    let passfirstFlag = false,
      passSecondFlag = false,
      passThirdFlag = false,
      passFourthFlag = false,
      passFifthFlag = false,
      passSixthFlag = false,
      passSeventhFlag = false,
      passEighthFlag = false,
      passNinthFlag = false,
      passTenthFlag = false

    const reset = function (e) {
      first = false
      second = false
      third = false
      fourth = false
      fifth = false
      sixth = false
      seventh = false
      eighth = false
      ninth = false
      tenth = false
      duration = 0
      passfirstFlag = false
      passSecondFlag = false
      passThirdFlag = false
      passFourthFlag = false
      passFifthFlag = false
      passSixthFlag = false
      passSeventhFlag = false
      passEighthFlag = false
      passNinthFlag = false
      passTenthFlag = false
    }

    videoPlayer.onloadstart = reset
    videoPlayer.ontimeupdate = function () {
      const curTime = +videoPlayer.currentTime.toFixed(0)

      if (
        !passfirstFlag &&
        (curTime === first || (curTime > first && curTime < first + first * offset))
      ) {
        passfirstFlag = true
        updateFlags({ passfirstFlag })
      } else if (
        !passSecondFlag &&
        (curTime === second || (curTime > second && curTime < second + first * offset))
      ) {
        passSecondFlag = true
        updateFlags({ passSecondFlag })
      } else if (
        !passThirdFlag &&
        (curTime === third || (curTime > third && curTime < third + first * offset))
      ) {
        passThirdFlag = true
        updateFlags({ passThirdFlag })
      } else if (
        !passFourthFlag &&
        (curTime === fourth || (curTime > fourth && curTime < fourth + first * offset))
      ) {
        passFourthFlag = true
        updateFlags({ passFourthFlag })
      } else if (
        !passFifthFlag &&
        (curTime === fifth || (curTime > fifth && curTime < fifth + first * offset))
      ) {
        passFifthFlag = true
        updateFlags({ passFifthFlag })
      } else if (
        !passSixthFlag &&
        (curTime === sixth || (curTime > sixth && curTime < sixth + first * offset))
      ) {
        passSixthFlag = true
        updateFlags({ passSixthFlag })
      } else if (
        !passSeventhFlag &&
        (curTime === seventh || (curTime > seventh && curTime < seventh + first * offset))
      ) {
        passSeventhFlag = true
        updateFlags({ passSeventhFlag })
      } else if (
        !passEighthFlag &&
        (curTime === eighth || (curTime > eighth && curTime < eighth + first * offset))
      ) {
        passEighthFlag = true
        updateFlags({ passEighthFlag })
      } else if (
        !passNinthFlag &&
        (curTime === ninth || (curTime > ninth && curTime < ninth + first * offset))
      ) {
        passNinthFlag = true
        updateFlags({ passNinthFlag })
      } else if (
        !passTenthFlag &&
        (curTime === tenth || (curTime > tenth && curTime <= duration))
      ) {
        passTenthFlag = true
        updateFlags({ passTenthFlag })
      }
    }

    videoPlayer.ondurationchange = function () {
      duration = +videoPlayer.duration.toFixed(0)
      if (duration > 0) {
        const flag = (duration / 10).toFixed(0)

        first = +flag
        second = +flag * 2
        third = +flag * 3
        fourth = +flag * 4
        fifth = +flag * 5
        sixth = +flag * 6
        seventh = +flag * 7
        eighth = +flag * 8
        ninth = +flag * 9
        tenth = +Math.floor(duration * 0.97)
      }
    }
  }
}
