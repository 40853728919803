import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { easyPaisaCheckout } from '../../../../../../api/checkout'
import validator from 'validator'

import { useStyles } from './styles'
import { ALERT_TYPES, CHECKOUT_STEPS } from '../../../../../../lib/constant'
import { showAlert } from '../../../../../../redux/modules/alertHandler'
import { setFullScreenSpinner } from '../../../../../../redux/modules/globalModals'
import errorHandler from '../../../../../../lib/errorHandler'
import { TextInput } from '../../../../../../components/TextInput'
import { useQuery } from '../../../../../../lib/utils'
import { Button } from '../../../../../../components/Button'
import { color } from '../../../../../../styles/color'
import { pakistanFlag } from '../../../../../../assets'

export const EasyPaisaForm = ({ paymentMethod, handleStep }) => {
  const classes = useStyles()
  const {
    videoId,
    plan: { videoPlanId: video_plan_id },
    promoCode
  } = useSelector((state) => state.checkout)
  const { userId } = useSelector((state) => state.user)
  const { countryCode: country_code, calling_code } = useSelector((state) => state.location)
  const [mobileNumber, setMobileNumber] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const { id } = paymentMethod
  const code = '+92'
  const accessToken = useQuery().get('access-token')

  const dispatch = useDispatch()

  const setLoader = (loading, loadingText) => {
    dispatch(
      setFullScreenSpinner({
        loading,
        loadingText
      })
    )
  }

  const submitForm = async () => {
    let body = {
      payment_gateway_id: id,
      user_id: userId,
      video_plan_id,
      video_id: videoId,
      country_code,
      mobile_number: `0${mobileNumber}`
    }

    if (promoCode) body['promo_code_id'] = promoCode.id

    try {
      setLoader(true, 'Processing your request...')
      const { order_id, detail } = await easyPaisaCheckout(accessToken, body, dispatch)
      getStatus(order_id, detail)
    } catch (error) {
      errorHandler.report(error)
      setLoader(false, '')
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message:
            error?.response?.data?.message || 'Unable to process your request. Please try again',
          isVisible: true
        })
      )
    }
  }

  const getStatus = async (order_id, detail) => {
    try {
      setLoader(true, 'Please check your phone or EasyPaisa app')
      const response = await easyPaisaCheckout(accessToken, {}, dispatch, order_id)
      if (response === 'PROCESSING') {
        setTimeout(async () => {
          await getStatus(order_id, detail)
        }, 3000)
      } else if (response === 'ERROR') {
        setLoader(false, '')
        dispatch(
          showAlert({
            type: ALERT_TYPES.ERROR,
            message: 'Unable to process your request. Please try again',
            isVisible: true
          })
        )
      } else {
        setLoader(false, '')
        handleStep(CHECKOUT_STEPS.POST_CHECKOUT)
      }
    } catch (error) {
      errorHandler.report(error)
      setLoader(false, '')
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message:
            error?.response?.data?.message || 'Unable to process your request. Please try again',
          isVisible: true
        })
      )
    }
  }

  useEffect(() => {
    if (validator.isMobilePhone(`${code}${mobileNumber}`)) setIsDisabled(false)
    else setIsDisabled(true)
  }, [mobileNumber, code, setIsDisabled])

  const handleMobileNumber = ({ target: { value } }) => {
    if (!isNaN(value.substring(1))) {
      setMobileNumber(value)
    }
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div className={`${classes.inputField} ${classes.codeInput}`}>
          <img src={pakistanFlag} className={classes.flag} />
          <div className={classes.codeText}>
            <Typography>+92</Typography>
          </div>
        </div>
        <TextInput
          style={{ flex: 1 }}
          name="Phone number"
          value={mobileNumber}
          onChange={handleMobileNumber}
          placeholder="123 1234 1243"
          className={classes.inputField}
        />
      </div>

      <div className={classes.footerContainer}>
        <Button
          onClick={submitForm}
          title="Click to pay"
          className={classes.button}
          style={{ backgroundColor: isDisabled ? 'gray' : color.buttonBlue, color: '#FFFFFF' }}
          disabled={isDisabled}
        />
      </div>
    </>
  )
}
