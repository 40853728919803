import React, { useEffect, useState, useReducer } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'

import { useStyles } from './styles'
import {
  // GENDER_OPTIONS,
  ALERT_TYPES
} from '../../../../../lib/constant'
import { Button } from '../../../../Button'
import { showAlert } from '../../../../../redux/modules/alertHandler'
import { validateUserMissingDetail } from '../../../../../lib/utils'
import {
  //  DateInput,
  GridWRapper,
  TextInput
  // , SelectInput
} from '../../../../'
import errorHandler from '../../../../../lib/errorHandler'
import { setUserData } from '../../../../../redux/modules/user'

export const Form = () => {
  const classes = useStyles()

  const { name } = useSelector((state) => state.user)
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const [{ gender, dob, firstName, lastName }, setDetail] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      gender: 'Gender',
      dob: '',
      firstName: '',
      lastName: ''
    }
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (name) {
      setDetail({
        firstName: name
      })
    }
  }, [name, setDetail])

  const handleChange = ({ target: { name, value } }) => setDetail({ [name]: value })

  useEffect(() => {
    if (validateUserMissingDetail(gender, dob, firstName, lastName)) setIsValid(true)
    else setIsValid(false)
  }, [gender, dob, firstName, lastName])

  const addUserAttributes = async (event) => {
    if (event) event.preventDefault()

    if (loading) return

    try {
      setLoading(true)
      let attributes = { birthdate: dob, gender: gender, name: firstName, given_name: lastName }

      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, attributes)
      dispatch(setUserData({ dob, gender, name: `${firstName} ${lastName}` }))

      setLoading(false)
    } catch (err) {
      errorHandler.report(err)
      setLoading(false)
      dispatch(
        showAlert({
          message: err.message || 'Something went wrong please try again',
          type: ALERT_TYPES.ERROR,
          isVisible: true
        })
      )
    }
  }

  return (
    <form onSubmit={addUserAttributes} className={classes.initialFormContainer}>
      <Grid container md={5} lg={4} xs={10} sm={10}>
        {!name && (
          <>
            <GridWRapper>
              <TextInput
                name="firstName"
                value={firstName}
                placeholder="First Name"
                className={classes.input}
                onChange={handleChange}
              />
            </GridWRapper>
            <GridWRapper>
              <TextInput
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                className={classes.input}
                onChange={handleChange}
              />
            </GridWRapper>
          </>
        )}
        {/* <GridWRapper>
          <SelectInput
            value={gender}
            name="gender"
            label={'Gender'}
            menus={GENDER_OPTIONS}
            onChange={handleChange}
          />
        </GridWRapper>

        <GridWRapper>
          <DateInput
            onChange={handleChange}
            value={dob}
            id="dob"
            name="dob"
            placeholder="Date of birth"
            className={classes.input}
          />
        </GridWRapper> */}

        <GridWRapper>
          <div className={classes.buttonsContainer}>
            <Button
              title="Continue"
              color="primary"
              hover
              loading={loading}
              disabled={!isValid}
              onClick={addUserAttributes}
              style={{ width: '292px', height: 36, align: 'center' }}
              type="submit"
            />
          </div>
        </GridWRapper>
      </Grid>
    </form>
  )
}
