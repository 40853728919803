import { API } from 'aws-amplify'
import { getToken } from '../redux/modules/user'

export const getInvitePromoCode = async (dispatch) => {
  try {
    const authToken = await dispatch(getToken())

    const params = {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    return await API.get('user', '/invite-promo-code', params)
  } catch (err) {
    throw err
  }
}
