import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Countdown from 'react-countdown'

import { useStyles, useCountDownStyles } from './styles'
import { Button } from '../../../components/Button'
import { MintrioOriginal } from '../../../components/MintrioOriginal'
import { playTrailer } from '../../../redux/modules/trailerPlayer'
import { setMinimumPrice, setSymbol } from '../../../redux/modules/videos'
import { EVENT_CATEGORIES } from '../../../lib/constant'
import { color } from '../../../styles/color'
import { fetchPricingForBanner } from '../../../api/pricings'

export const MovieBanner = ({
  id,
  main_title,
  sub_title,
  title,
  banner_message,
  short_description,
  thumbnail_lg,
  release_date,
  trailers,
  accessToken = false
}) => {
  const classes = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const handlePreview = () => {
    dispatch(setMinimumPrice({ price: minPrice }))
    dispatch(setSymbol({ symbol: countrySymbol }))
    history.push(`/preview/${id}`)
  }

  const locationData = useSelector((state) => state.location)
  const { login } = useSelector((state) => state.user)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)
  const { countryCode } = useSelector((state) => state.location)

  const [prices, setPrices] = useState([])
  const [minPrice, setMinPrice] = useState('')
  const [contains, setContains] = useState(false)
  const [countrySymbol, setCountrySymbol] = useState('')
  const [durationmins, setDurationMins] = useState('')

  useEffect(() => {
    if (countryCode && id) {
      const filter = {
        video_id: {
          eq: id
        },
        country_code: {
          eq: countryCode
        },
        status: {
          eq: true
        }
      }
      ;(async () => {
        const response = await fetchPricingForBanner(filter, accessToken, login)

        setPrices(response)
        setCountrySymbol(response[0]?.country?.currency_symbol)
      })()
    }
  }, [countryCode, id, login, setPrices, accessToken])

  useEffect(() => {
    if (prices.length) {
      let min = Math.min.apply(
        Math,
        prices.map(function (o) {
          return o.price
        })
      )
      setMinPrice(min)
      if (trailers) {
        setContains(trailers?.items?.some((e) => e.type === 'CONTENT_PREVIEW'))

        let objectPreview = trailers?.items?.find((e) => e.type === 'CONTENT_PREVIEW')
        if (objectPreview) {
          let duration_object = JSON.parse(objectPreview?.additional_attributes)['duration']
          let duration_min =
            parseInt(duration_object['hours']) * 60 +
            parseInt(duration_object['minutes']) +
            Math.ceil(parseInt(duration_object['seconds']) / 60)
          setDurationMins(duration_min)
        }
      }
    }
  }, [prices, trailers, setContains, setDurationMins])

  const handlePlayTrailer = () => {
    if (!trailers || !trailers.items || trailers.items.length === 0) return

    const filteredTrailers = trailers.items.filter((item) => item.is_main)
    const payload = {
      isPlayerModalVisible: true,
      trailer: filteredTrailers.length > 0 ? filteredTrailers[0] : trailers.items[0]
    }
    dispatch(playTrailer(payload))

    window.gtag('event', 'watch_trailer', {
      event_label: 'Trailer ID',
      content_type: 'Trailer',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: payload.trailer.id,
      parent_video_id: id,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }
  return (
    <div
      className={`${classes.movieBannerContainer}`}
      style={{
        backgroundImage: `linear-gradient(to right, 
          rgba(0, 0, 0, 0.2), 
          rgba(0, 0, 0, 0.2), 
          transparent, transparent), 
          url(${thumbnail_lg})`
      }}
    >
      <div
        className={`${classes.imageBanner}`}
        style={{
          backgroundImage: `linear-gradient(to top, 
          ${color.backgroundPurple}, 
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.1), 
          transparent, transparent), 
            url(${thumbnail_lg})`
        }}
      ></div>
      <Grid
        item
        xs={12}
        md={4}
        className={classes.leftcontainer}
        alignItems="center"
        justify="center"
        direction="column"
      >
        <div className={classes.bannerMessageContainer}>
          {banner_message.length > 0 && (
            <Typography className={classes.banner}>{banner_message}</Typography>
          )}
        </div>

        <div className={classes.bannerPriceContainer}>
          {minPrice === 0 ? (
            <>
              <Typography className={classes.bannerPrice}>
                <span>Watch for FREE</span>
              </Typography>
            </>
          ) : contains ? (
            <>
              <Typography className={classes.bannerPrice}>
                <span className={`${classes.letterSpaced}`}>
                  Watch {durationmins} Min FREE{' '}
                  {minPrice && (
                    <span>
                      {' '}
                      <span style={{ margin: '0 3px' }}>●</span> Rent From{' '}
                      {countrySymbol?.length === 1 ? `${countrySymbol}` : `${countrySymbol + ' '}`}{' '}
                      {minPrice}
                    </span>
                  )}
                </span>
              </Typography>
            </>
          ) : durationmins ? (
            <>
              <Typography className={classes.bannerPrice}>
                <span>Watch {durationmins} Min FREE</span>
              </Typography>
            </>
          ) : minPrice ? (
            <>
              <Typography className={classes.bannerPrice}>
                <span>From</span>{' '}
                {countrySymbol?.length === 1 ? `${countrySymbol}` : `${countrySymbol + ' '}`}
                {minPrice}
              </Typography>
            </>
          ) : (
            ''
          )}
        </div>

        <div className={classes.parentContainer}>
          <div className={classes.textContainer}>
            <Typography className={classes.moviefname}>
              {sub_title && sub_title !== '' ? main_title : title}
            </Typography>
            {sub_title && <Typography className={classes.movieSubTitle}>{sub_title}</Typography>}
            {moment(release_date).isAfter(new Date()) ? (
              <CountDownTimer release_date={release_date} />
            ) : (
              <Typography className={classes.shortdisc}>{short_description}</Typography>
            )}
          </div>

          <div className={classes.buttonContainer}>
            <Button
              title="Watch Trailer"
              className={classes.button}
              hover
              onClick={handlePlayTrailer}
            />
            <Button
              title="Open"
              onClick={handlePreview}
              className={classes.transparentbutton}
              hover
            />

            <div className={classes.mintrioOriginal}>
              <MintrioOriginal />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  )
}

const CountDownTimer = ({ release_date }) => {
  const classes = useCountDownStyles()

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>
    } else {
      return (
        <div className={classes.timerMain}>
          <div className={classes.timerSub}>
            <span className={classes.time}>{days}</span>
            <span className={classes.timeTitle}>DAYS</span>
          </div>
          <div className={classes.timerSub}>
            <span className={classes.time}>:</span>
            <span className={classes.timeTitle} style={{ fontWeight: 600, fontSize: 15 }}>
              :
            </span>
          </div>
          <div className={classes.timerSub}>
            <span className={classes.time}>{hours}</span>
            <span className={classes.timeTitle}>HOURS</span>
          </div>
          <div className={classes.timerSub}>
            <span className={classes.time}>:</span>
            <span className={classes.timeTitle} style={{ fontWeight: 600, fontSize: 15 }}>
              :
            </span>
          </div>
          <div className={classes.timerSub}>
            <span className={classes.time}>{minutes}</span>
            <span className={classes.timeTitle}>MINUTES</span>
          </div>
        </div>
      )
    }
  }
  return (
    <div className={classes.countDownContainer}>
      {moment(release_date).subtract(90, 'days').isSameOrBefore(new Date()) ? (
        <div>
          <Typography className={classes.streaming}>STREAMING TO YOUR HOME IN</Typography>
          <Countdown date={new Date(release_date)} renderer={renderer} />
        </div>
      ) : (
        <div>
          <Typography className={classes.comingsoon}>COMING SOON</Typography>
        </div>
      )}
    </div>
  )
}
