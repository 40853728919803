import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { verifyPayment } from '../../../../../../api/checkout'
import { ALERT_TYPES, CHECKOUT_STEPS } from '../../../../../../lib/constant'
import { showAlert } from '../../../../../../redux/modules/alertHandler'
import { useStyles } from './styles'
import errorHandler from '../../../../../../lib/errorHandler'
import { setFullScreenSpinner } from '../../../../../../redux/modules/globalModals'
import { Typography } from '@material-ui/core'
import { Button } from '../../../../../../components'

export const CryptoForm = ({ handleStep }) => {
  const classes = useStyles()
  const {
    paymentGateway: { id: payment_gateway_id, name },
    videoId: video_id,
    plan: { price, videoPlanId: video_plan_id },
    promoCode
  } = useSelector((state) => state.checkout)
  const { userId: user_id } = useSelector((state) => state.user)
  const { countryCode: country_code } = useSelector((state) => state.location)
  const dispatch = useDispatch()

  const showError = useCallback(
    (message) => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message,
          isVisible: true
        })
      )
    },
    [dispatch]
  )

  const setLoader = useCallback(
    (loading, loadingText) => {
      dispatch(setFullScreenSpinner({ loading, loadingText }))
    },
    [dispatch]
  )

  const onSuccess = useCallback(
    async (data, actions) => {
      setLoader(true, 'Preparing your order...')
      if (data && data.id && data.status === 'succeeded') {
        const body = {
          payment_gateway_id,
          user_id,
          video_plan_id,
          video_id,
          country_code,
          paymentId: data.id,
          payment_method: name,
          price: promoCode ? price - price * (promoCode.percentage_off / 100) : price,
          promoCodeId: promoCode ? promoCode.id : null
        }
        try {
          // verify payment and add order for user
          await verifyPayment(body, dispatch)
          setLoader(false, '')
          handleStep(CHECKOUT_STEPS.POST_CHECKOUT)
        } catch (err) {
          errorHandler.report(err)
          setLoader(false, '')
          showError('Unable to process your request. Please try again')
        }
      } else {
        setLoader(false, '')
        showError('Unable to process your request. Please try again')
      }
    },
    [
      name,
      payment_gateway_id,
      user_id,
      video_plan_id,
      video_id,
      country_code,
      price,
      promoCode,
      handleStep,
      showError,
      dispatch,
      setLoader
    ]
  )

  useEffect(() => {
    window.cryptopay
      .Button({
        createPayment: function (actions) {
          return actions.payment.create({
            currency: 'USD',
            amount: (promoCode ? price - price * (promoCode.percentage_off / 100) : price) * 100,
            description: video_id,
            order_id: video_plan_id,
            metadata: {
              user_id,
              video_id,
              created_at: Date.now(),
              video_plan_id
            }
          })
        },
        onApprove: onSuccess,
        defaultLang: 'en-US'
      })
      .render('#pay-button')
  }, [video_plan_id, user_id, video_id, price, promoCode, onSuccess])

  const handleInBrowser = () => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('preview')
  }
  return (
    <div className={classes.cryptoMainContainer}>
      {window.ReactNativeWebView ? (
        <div className={classes.openInBrowserContainer}>
          <div className={classes.buttonContainer}>
            <Button onClick={handleInBrowser} title="Open in Browser" className={classes.button} />
          </div>
          <Typography className={classes.notSupportedText}>
            Crypto method only works in browser.
          </Typography>
        </div>
      ) : (
        <div id="pay-button" className={classes.cryptoButtonContainer}></div>
      )}
    </div>
  )
}
