import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  historyTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  historyTable: {
    width: '100%',
    borderCollapse: 'collapse',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  tableColumn: {
    padding: '15px',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Rubik',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0px 15px 0px',
      fontSize: '9px'
    }
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  total: {
    color: '#645fc2',
    width: '70px'
  },
  tableRow: {
    borderBottom: '1px solid #bebebe'
  },
  tableHeader: {
    borderBottom: '1px solid #bebebe',
    padding: '0px 15px 10px 15px',
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Rubik',
    fontSize: '10px',
    fontWeight: '400',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 10px 0px',
      fontSize: '9px'
    }
  }
}))
