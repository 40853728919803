// constants
const SET_PLAYER_VOLUME = 'SET_PLAYER_VOLUME'

export const setVolumne = (volume) => ({
  type: SET_PLAYER_VOLUME,
  payload: { volume }
})

// reducer
const initialState = {
  player: {
    volume: 1,
    subTitles: null
  }
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_PLAYER_VOLUME: {
      return { ...state, player: { ...state.player, volume: payload.volume } }
    }
    default:
      return state
  }
}

export default reducer
