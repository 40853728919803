import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../styles/color'

export const useGreetingsStyles = makeStyles(() => ({
  mainContainer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    position: 'relative',
    padding: 10
  },
  text: {
    fontSize: 18,
    color: 'white',
    textAlign: 'center',
    paddingBottom: 10
  },
  heading: {
    fontSize: 22,
    fontWeight: '600'
  },
  imageContainer: {
    marginTop: 30
  },
  image: {
    height: 140,
    width: 140
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 120,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: 240,
    height: 40,
    color: 'white',
    fontWeight: '600',
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue
    }
  }
}))
