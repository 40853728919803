import { makeStyles } from '@material-ui/core'

import { color } from '../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  h1: {
    fontFamily: 'Rubik',
    fontSize: '1.3rem',
    letterSpacing: '0.015em',
    marginTop: '0.5em',
    marginBottom: '5em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em',
      fontWeight: 'bold'
    }
  },
  h2: {
    fontSize: '2em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em'
    }
  },
  subtitle1: {
    fontSize: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em'
    }
  },
  subtitle2: {
    fontSize: '0.8em',
    marginBottom: '1em',
    cursor: 'pointer',
    '&:active': {
      color: 'lightgray'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em'
    }
  },
  input: {
    color: color.white,
    borderRadius: 4,
    height: 36,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12
  },
  errorMessage: {
    textAlign: 'center',
    color: color.red,
    fontSize: '14px'
  }
}))
