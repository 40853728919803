import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'

import { dislikeIcon, likeIcon, selectedDislikeIcon, selectedLikeIcon } from '../../../assets'
import { updateUserRating } from '../../../redux/modules/myContent'

export const RatingButtons = ({ orderDetail, videoId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const updateVideo = (isLiked) => {
    dispatch(updateUserRating(isLiked, videoId))
  }

  return (
    <div className={classes.ratingsContainer}>
      <Typography className={classes.text}>Rate your experience?</Typography>
      <div className={classes.ratingIconContainer}>
        <img
          alt="like"
          src={
            orderDetail && orderDetail.userRating && orderDetail.userRating.is_liked
              ? selectedLikeIcon
              : likeIcon
          }
          onClick={() => updateVideo(true)}
          role="presentation"
          className={classes.ratingIcon}
        />
      </div>
      <div className={classes.ratingIconContainer}>
        <img
          alt="dislike"
          src={
            orderDetail && orderDetail.userRating && orderDetail.userRating.is_liked === false
              ? selectedDislikeIcon
              : dislikeIcon
          }
          role="presentation"
          onClick={() => updateVideo(false)}
          className={classes.ratingIcon}
        />
      </div>
    </div>
  )
}

export const useStyles = makeStyles(() => ({
  ratingsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    width: '100%',
    height: 40,
    background: '#3D3E58',
    backdropFilter: 'blur(10px)',
    borderRadius: 3
  },
  ratingIconContainer: {
    margin: '0px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ratingIcon: {
    height: 20,
    width: 20,
    cursor: 'pointer'
  },
  text: {
    color: 'white',
    fontSize: 13,
    fontWeight: '600',
    padding: '0px 10px'
  }
}))
