import React, { useEffect } from 'react'
import { Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useStyles } from './styles'
import { closeTrailerPlayer } from '../../../redux/modules/trailerPlayer'
import { updateTrailerViewLog } from '../../../api/viewLogs'
import { addNewLog } from '../../../redux/modules/userActivityLogs'
import { USER_ACTIVITY_ACTIONS } from '../../../lib/constant'
import { setVolumne } from '../../../redux/modules/userPreferences'
import BitmovinPlayer from '../../players/bitmovinPlayers'

export const TrailerPlayerModal = () => {
  const { trailer, isPlayerModalVisible } = useSelector((state) => state.trailerPlayer)
  const { price, symbol } = useSelector((state) => state.videos)

  const {
    player: { volume }
  } = useSelector((state) => state.userPreferences)
  const { userId } = useSelector((state) => state.user)
  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    return history.listen((location) => {
      dispatch(closeTrailerPlayer())
    })
  }, [history, dispatch])

  useEffect(() => {
    if (isPlayerModalVisible && trailer && locationData.countryCode && locationData.query) {
      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.WATCH_TRAILER, {
          user_id: userId,
          trailer_id: trailer.id,
          device_type_id: deviceTypeId,
          ip_address: locationData.query,
          country_code: locationData.countryCode
        })
      )
      ;(async () => {
        let data = {
          trailer_id: trailer.id,
          device_type_id: deviceTypeId,
          ip_address: locationData.query,
          country_code: locationData.countryCode
        }
        if (userId) data['user_id'] = userId
        await updateTrailerViewLog(data)
      })()
    }
  }, [isPlayerModalVisible, trailer, locationData, deviceTypeId, userId, dispatch])

  const close = () => {
    dispatch(closeTrailerPlayer())
  }
  const handleVolumeChanges = (value) => dispatch(setVolumne(value))

  const handleRedirect = () => {
    history.push(location.pathname, { checkoutModalVisibility: true })
  }

  const handleBack = () => {
    history.push(location.pathname)
  }

  return (
    <Modal
      open={isPlayerModalVisible && trailer}
      className={classes.modalContainer}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.mainContainer}>
        {trailer && (
          <BitmovinPlayer
            title={trailer.title}
            url={trailer.hls_url}
            poster={trailer.thumbnail_lg}
            close={close}
            volume={volume}
            handleVolumeChanges={handleVolumeChanges}
            handleRedirect={handleRedirect}
            handleBack={handleBack}
            trailerType={trailer.type}
            symbol={symbol}
            price={price}
          />
        )}
      </div>
    </Modal>
  )
}
