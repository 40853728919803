import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { useDesktopViewPlanItemStyles } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { setPlan } from '../../../../../redux/modules/checkout'
import { SupportedDevices } from './supportedDevices'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { RentalTermsModal } from './rentalTermsModal'

export const DesktopTabletViewPlanItem = ({ item, devices, promoCode }) => {
  const [isVisible, setIsVisible] = useState(false)
  const classes = useDesktopViewPlanItemStyles()
  const { plan } = useSelector((state) => state.checkout)
  const { countryCode } = useSelector((state) => state.location)
  const dispatch = useDispatch()

  const handlePlanSelection = () => dispatch(setPlan(item))
  const openModal = () => setIsVisible(true)
  const closeModal = () => setIsVisible(false)

  return (
    <div className={classes.mainContainer}>
      <div
        id={item.id}
        className={
          plan && plan.id === item.id
            ? clsx(classes.planContainer, classes.selectedBorder)
            : classes.planContainer
        }
        role={'presentation'}
        onClick={handlePlanSelection}>
        <Typography
          className={
            plan && plan.id === item.id
              ? clsx(classes.planNameContainer, classes.planNameSelected)
              : classes.planNameContainer
          }>
          {item.planName}
        </Typography>

        <div className={classes.planDetailContainer}>
          {countryCode !== 'PK' && (
            <Typography
              className={promoCode ? classes.priceContainerWithPromo : classes.priceContainer}>
              {`${item.currency} ${item.price}`}
            </Typography>
          )}
          {promoCode && (
            <Typography className={classes.priceContainer}>
              {`${item.currency} ${item.price - item.price * (promoCode.percentage_off / 100)}`}
            </Typography>
          )}
          {!promoCode && countryCode === 'PK' && (
            <>
              <Typography className={classes.priceContainerWithPromo}>
                {`${item.currency}`} {item.planName === 'Standard' ? '699' : '499'}
              </Typography>
              <Typography className={classes.priceContainer}>
                {`${item.currency} ${item.price}`}
              </Typography>
            </>
          )}

          <Typography
            className={
              plan && plan.id === item.id
                ? clsx(classes.qualityContainer, classes.qualityContainerSelected)
                : classes.qualityContainer
            }>
            {item.description}
          </Typography>

          <Typography
            className={
              plan && plan.id === item.id
                ? clsx(classes.qualityContainer, classes.qualityContainerSelected)
                : classes.qualityContainer
            }>
            {item.maxResolution}
          </Typography>

          <Typography
            className={
              plan && plan.id === item.id
                ? clsx(classes.qualityContainer, classes.qualityContainerSelected)
                : classes.qualityContainer
            }>
            Watch on your
          </Typography>
          <SupportedDevices devices={devices} selected={plan && plan.id === item.id} />

          <div className={classes.centeredContainer}>
            <Typography className={classes.allowDownloadText}>Allows download</Typography>
            {item.isDownloadable ? (
              <CheckCircle className={classes.checkIcon} />
            ) : (
              <Cancel className={classes.closeIcon} />
            )}
          </div>

          <div className={classes.centeredContainer}>
            <Typography className={classes.rentalTerms} role="presentation" onClick={openModal}>
              View Rental Terms
            </Typography>
          </div>
        </div>
      </div>

      <RentalTermsModal plan={plan} isVisible={isVisible} closeModal={closeModal} />
    </div>
  )
}
