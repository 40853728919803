import { IconButton, makeStyles, Typography } from '@material-ui/core'
import { useState } from 'react'
import { color } from '../../../../styles/color'
import { PlayArrow as PlayArrowIcon } from '@material-ui/icons'

let timer = null

export const Detail = ({ player, title, description }) => {
  const [showDetail, setShowDetail] = useState(false)
  const classes = useStyles({ showDetail })

  player.on(window.bitmovin.player.PlayerEvent.Paused, function () {
    if (!timer) {
      timer = setTimeout(() => {
        setShowDetail(true)
      }, 15000)
    }
  })

  player.on(window.bitmovin.player.PlayerEvent.Play, function () {
    handleContinueWatching()
  })

  const handleContinueWatching = () => {
    setShowDetail(false)
    if (player) player.play()
    if (timer) clearTimeout(timer)
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <Typography className={classes.title}>You are watching</Typography>
        <Typography className={classes.movieTitle}>{title}</Typography>
        <Typography className={classes.description}>
          {description.length > 300 ? `${description.slice(0, 300)}...` : description}
        </Typography>

        <div
          role="presentation"
          onClick={handleContinueWatching}
          className={classes.continueButton}>
          <IconButton size="small">
            <PlayArrowIcon className={classes.playIcon} />
          </IconButton>
          <Typography className={classes.continueWatchButton}>Continue Watching</Typography>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1000000000000,
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: ({ showDetail }) => (showDetail ? 'flex' : 'none'),
    alignItems: 'center',
    padding: '0px 50px'
  },
  continueButton: {
    width: 280,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: color.white,
    '&:hover': {
      color: color.white
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: '400',
    color: color.green
  },
  movieTitle: {
    fontSize: 27,
    fontWeight: '600',
    color: color.white
  },
  description: {
    fontSize: 18,
    fontWeight: '400',
    color: color.lightGrey,
    width: 400,
    padding: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  continueWatchButton: {
    fontSize: 16,
    fontWeight: '600',
    width: 200
  },
  playIcon: {
    fontSize: 40,
    color: color.white
  }
}))
