import { makeStyles } from '@material-ui/styles'
import { isFirefox } from 'react-device-detect'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    outline: 'none',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.8)',
    backdropFilter: 'blur(10px)',
    width: '80%',
    minHeight: '80%',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      borderRadius: '0px'
    }
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    fontFamily: 'Rubik'
  },
  transactionLoader: {
    flex: 1,
    // justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  progressSpinner: {
    color: 'white'
  },
  historyContainer: {
    flex: 1,
    overflow: 'auto'
  },
  prfileAvatar: {
    backgroundColor: 'rgba(64, 155, 206, 0.4)',
    width: 70,
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(64, 155, 206, 0.4)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60
    }
  },
  nameAlphabet: {
    fontFamily: 'Pacifico',
    fontSize: 24,
    color: 'white',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  detail: {
    width: 400,
    marginTop: 20,
    padding: '0px 15px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  item: {
    borderBottom: '1px solid gray',
    marginBottom: 15,
    paddingBottom: 5
  },
  itemTitle: {
    color: 'white',
    fontWeight: '600'
  },
  itemValue: {
    color: 'white',
    fontWeight: '300'
  }
}))
