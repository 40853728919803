import React from 'react'

import {
  easypaisa,
  jazzCash,
  paypal,
  cryptoIcon as crypto,
  visa,
  masterCard,
  americanExpress,
  discover,
  UnionPay
} from '../../../assets'

export const PaymentIcon = ({ name, header = false }) => {
  switch (name) {
    case 'STRIPE':
      return <StripIcon header={header} />
    case 'JAZZ_CASH':
      return <JazzcashIcon header={header} />
    case 'EASY_PAISA':
      return <EasypaisaIcon header={header} />
    case 'PAYPAL':
      return <PaypalIcon header={header} />
    case 'CRYPTO':
      return <CryptoIcon header={header} />
    default:
      return null
  }
}

const StripIcon = ({ header }) => {
  return (
    <>
      <div style={{ paddingTop: header ? 10 : 0, paddingRight: header ? 10 : 5 }}>
        <img src={visa} alt="Visa" title="Visa" />
      </div>
      <div style={{ paddingTop: header ? 10 : 0, paddingRight: header ? 10 : 5 }}>
        <img src={masterCard} alt="Master Card" title="Master Card" />
      </div>
      <div style={{ paddingTop: header ? 10 : 0, paddingRight: header ? 10 : 5 }}>
        <img src={americanExpress} alt="American Express" title="American Express" />
      </div>
      <div style={{ paddingTop: header ? 10 : 0, paddingRight: header ? 10 : 5 }}>
        <img src={discover} alt="Discover" title="Discover" />
      </div>
      <div style={{ paddingTop: header ? 10 : 0, paddingRight: header ? 10 : 5 }}>
        <img src={UnionPay} alt="UnionPay" title="UnionPay" />
      </div>
    </>
  )
}

const JazzcashIcon = ({ header }) => {
  return (
    <img
      src={jazzCash}
      style={{ height: header ? 30 : 25, marginTop: header ? 10 : 0 }}
      alt="Jazzcash"
    />
  )
}

const EasypaisaIcon = ({ header }) => {
  return (
    <img
      src={easypaisa}
      style={{ height: header ? 30 : 15, marginTop: header ? 10 : 0 }}
      alt="Easypaisa"
    />
  )
}

const PaypalIcon = ({ header }) => {
  return (
    <img
      src={paypal}
      style={{ height: header ? 30 : 20, marginTop: header ? 10 : 0 }}
      alt="paypal"
    />
  )
}

const CryptoIcon = ({ header }) => {
  return (
    <img
      src={crypto}
      style={{ height: header ? 40 : 25, marginTop: header ? 10 : 0 }}
      alt="crypto"
    />
  )
}
