import { makeStyles } from '@material-ui/core'

import { color } from '../../../styles/color'

export const useContentViewBoxStyles = makeStyles((theme) => ({
  movieBannerContainer: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '330px',
    width: '240px',
    margin: '15px 30px',
    position: 'relative',
    borderImageSlice: 1,
    borderWidth: '1px',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    border: '1px solid',
    // backgroundSize: '100% 190px',
    // backgroundPosition:'top',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '15px 0px'
      // backgroundSize: '100% 190px',
    }
  },
  movieInnerContainer: {
    height: '328px',
    width: '238px',
    background: ` 
    linear-gradient(to top, 
      ${color.primaryColor},
      ${color.primaryColor} ,
      ${color.primaryColor} ,
      ${color.primaryColordock} ,
      transparent, transparent)`,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  simpleBorder: {
    borderImageSource: `linear-gradient(to top ,${color.buttonBlue}, ${color.buttonBlue}, ${color.backgroundPurple},${color.backgroundPurple})`
  },
  expiredBorder: {
    borderImageSource: `linear-gradient(to top ,${color.red}, ${color.red}, ${color.red}, ${color.backgroundPurple},${color.backgroundPurple})`
  },
  leftcontainer: {
    display: 'flex',
    backgroundColor: color.newdock,
    padding: 10,
    textAlign: 'center',
    height: '100%',
    justifyContent: 'flex-end'
  },
  banner: {
    color: color.white,
    fontSize: '10px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    backgroundColor: color.greypurple,
    borderRadius: 5,
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center'
  },
  movieexpires: {
    color: color.white,
    fontSize: '11px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    display: 'flex',
    justifyContent: 'center'
  },
  movieexpired: {
    color: color.white,
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    textAlign: 'center'
  },
  movieexpiresdate: {
    color: color.red,
    fontSize: '9px',
    fontWeight: '450',
    fontFamily: 'Rubik',
    paddingLeft: '2px'
  },
  mainTitle: {
    color: color.white,
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  subTitle: {
    color: color.grey,
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  buttonsContainer: {
    display: 'flex',
    padding: '10px 0px 5px 0px'
  },
  playButtons: {
    flex: 1,
    display: 'flex'
  },
  previewButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  playButton: {
    width: '100%',
    height: '28px',
    backgroundColor: 'rgba(40, 45, 219, 0.6);',
    color: color.white,
    fontWeight: '600',
    fontSize: '10px',
    fontFamily: 'Rubik',
    borderRadius: 3,
    margin: '5px 0px',
    border: '1px solid #3237E5',
    '&:disabled': {
      backgroundColor: 'rgba(40, 45, 200, 0.3);',
      color: 'rgba(255, 255, 255, 0.3)',
      border: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(40, 45, 219, 0.6);'
    }
  },
  resumebutton: {
    width: '100%',
    height: '28px',
    backgroundColor: 'rgba(181, 4, 4, 0.6)',
    color: color.white,
    fontWeight: '600',
    fontSize: '10px',
    borderRadius: 3,
    border: '1px solid #B50404',
    fontFamily: 'Rubik',
    margin: '5px 0px',
    '&:disabled': {
      backgroundColor: 'rgba(163, 3, 3, 0.2)',
      color: 'rgba(255, 255, 255, 0.3)',
      border: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(181, 4, 4, 0.6)'
    }
  },
  previewButton: {
    width: '100%',
    height: '28px',
    backgroundColor: '#232238',
    color: color.white,
    fontWeight: '600',
    fontSize: '10px',
    borderRadius: 3,
    border: '1px solid #414056',
    fontFamily: 'Rubik',
    margin: '5px 0px',
    '&:hover': {
      backgroundColor: '#232238'
    }
  },
  expiryReleaseContainer: {
    height: 35
  },
  tagText: {
    color: color.white,
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '8px',
    fontWeight: 450,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    borderRadius: 2,
    width: 80,
    paddingTop: 2
  },
  tag: {
    display: 'flex',
    justifyItems: 'flex-start'
  },
  notSupported: {
    backgroundColor: '#616161'
  },
  expired: {
    backgroundColor: 'red'
  },
  comingSoon: {
    backgroundColor: 'blue'
  }
}))

export const useContentListStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  emptyListContainer: {
    height: '75vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  iconButton: {
    border: '1px solid white',
    height: 80,
    width: 80,
    margin: 10
  },
  tvIcon: {
    color: 'white',
    height: 30,
    width: 30
  },
  emptyText: {
    color: 'white',
    fontFamily: 'Rubik'
  }
}))

export const useExpirationDetailStyles = makeStyles((theme) => ({
  expiryTagContainer: {
    paddingBottom: '3px',
    paddingTop: '10px'
  },
  movieexpires: {
    color: color.white,
    fontSize: '11px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    display: 'flex',
    justifyContent: 'center'
  },
  expiresInText: {
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  movieexpired: {
    color: color.white,
    fontSize: '9px',
    fontWeight: '400',
    textTransform: 'uppercase',
    fontFamily: 'Rubik',
    textAlign: 'center'
  },
  expiredTag: {
    fontSize: 10,
    fontWeight: '600',
    textTransform: 'uppercase',
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
    backgroundColor: color.red,
    borderRadius: 3,
    padding: '2px 8px',
    color: 'white'
  },
  expirationDate: {
    fontSize: 11,
    fontWeight: '600',
    fontFamily: 'Rubik',
    paddingLeft: 5
  },
  progressBar: {
    marginTop: 5,
    borderRadius: 5
  }
}))

export const useReleaseDetailStyles = makeStyles(() => ({
  mainContainer: {
    paddingBottom: '3px',
    paddingTop: '10px'
  },
  comingSoonTag: {
    fontSize: 10,
    fontWeight: '600',
    textTransform: 'uppercase',
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 2,
    backgroundColor: 'rgba(23,22,46,0.7)',
    borderRadius: 3,
    padding: '4px 8px',
    color: 'white'
  },
  releaseDate: {
    fontSize: 13,
    fontWeight: '600',
    fontFamily: 'Rubik',
    color: 'white'
  }
}))
