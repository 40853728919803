import { Grid } from '@material-ui/core'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { usePreviewSkeletonStyles } from './styles'

export const PreviewSkeleton = () => {
  const classes = usePreviewSkeletonStyles()
  return (
    <Grid item xs={12} sm={12} md={4}>
      <div className={classes.skeletonContainer}>
        <SkeletonTheme
          style={{ height: '100%' }}
          color={'rgba(35, 35, 65, 1)'}
          highlightColor={'rgba(55, 55, 80, 1)'}>
          <Skeleton height={200} className={classes.skeleton} />
          <Skeleton height={100} className={classes.skeleton} />
          <Skeleton height={120} className={classes.skeleton} />
        </SkeletonTheme>
      </div>
    </Grid>
  )
}
