import { API } from 'aws-amplify'
import { getToken } from '../redux/modules/user'

export const verifyPromoCode = async (accessToken, body, dispatch) => {
  const authToken = accessToken ? accessToken : await dispatch(getToken())

  const params = {
    body,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('promoCode', '/verify', params)
}

export const verifyPayment = async (body, dispatch, accessToken) => {
  try {
    const authToken = accessToken || (await dispatch(getToken()))
    const params = {
      body,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    await API.post('checkout', '/verify-payment', params)
  } catch (err) {
    throw err
  }
}

export const createCheckoutMiddleware = async (accessToken = false, body, dispatch) => {
  try {
    const authToken = accessToken || (await dispatch(getToken()))
    const params = {
      body,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    await API.post('checkout', '/create-checkout-middleware', params)
  } catch (err) {
    throw err
  }
}

export const createHash = async (accessToken = false, body, dispatch) => {
  try {
    const authToken = accessToken || (await dispatch(getToken()))
    const params = {
      body,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    return await API.post('checkout', '/hash', params)
  } catch (err) {
    throw err
  }
}

export const easyPaisaCheckout = async (accessToken = false, body, dispatch, orderId = null) => {
  try {
    const authToken = accessToken || (await dispatch(getToken()))
    const params = {
      body,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
    if (orderId) {
      return await API.get('checkout', `/easypaisa${`?order_id=${orderId}`}`, params)
    } else {
      return await API.post(
        'checkout',
        `/easypaisa${orderId ? `?order_id=${orderId}` : ''}`,
        params
      )
    }
  } catch (err) {
    throw err
  }
}
