import { API } from 'aws-amplify'
import _ from 'lodash'

import { listPaymentGateways, listPricings } from '../graphql/queries'
import { sendAxiosRequest } from './baseRequest'

export const listPricingsLoggedOutUser = /* GraphQL */ `
  query ListPricings($filter: ModelPricingFilterInput, $limit: Int, $nextToken: String) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        country {
          currency_symbol
          currency
        }
        video_plan {
          id
          plan {
            id
            title
            is_ad_supported
            n_watches
            n_days_download_to_expire
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            device_types {
              items {
                id
                plan_id
                device_type_id
                additional_attributes
                createdAt
                updatedAt
                device_types {
                  id
                  title
                  status
                  createdAt
                  updatedAt
                }
                plans {
                  id
                  title
                  status
                  video_quality_id
                  additional_attributes
                  is_ad_supported
                  expire_max_mins_after_purchase
                  expire_max_min_after_start
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            video_quality {
              vertical_resolution {
                title
              }
            }
          }
        }
        video {
          id
          title
        }
      }
      nextToken
    }
  }
`

export const fetchPricingForBanner = async (filter, accessToken = false, login = false) => {
  try {
    const payload = {
      query: login ? listPricings : listPricingsLoggedOutUser,
      authMode: login ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
      variables: {
        filter
      }
    }
    const {
      data: {
        listPricings: { items }
      }
    } = accessToken ? await sendAxiosRequest(accessToken, payload) : await API.graphql(payload)

    return items
  } catch (error) {
    throw error
  }
}

export const fetchPricings = async (filter, accessToken = false, login = false) => {
  try {
    const payload = {
      query: login ? listPricings : listPricingsLoggedOutUser,
      authMode: login ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
      variables: {
        filter
      }
    }

    const {
      data: {
        listPricings: { items }
      }
    } = accessToken ? await sendAxiosRequest(accessToken, payload) : await API.graphql(payload)

    if (items.length === 0) return []

    const plans = items.map((pricing) => {
      const {
        id,
        video_plan: {
          id: videoPlanId,
          plan: {
            id: planId,
            title,
            expire_max_min_after_start,
            expire_max_mins_after_purchase,
            n_watches: nWatches,
            n_days_download_to_expire: nDaysDownloadToExpire,
            is_downloadable: isDownloadable,
            video_quality: {
              vertical_resolution: { title: maxResolution }
            },
            device_types
          }
        },
        video: { title: videoTitle },
        country: { country_name, currency },
        price
      } = pricing

      const supportedDevices = device_types.items.reduce(
        (accum, { device_types }) => {
          const deviceTitle = _.get(device_types, 'title', '').toLowerCase()
          const deviceStatus = _.get(device_types, 'status', false)

          if (deviceTitle === 'laptop' && deviceStatus === true) {
            return {
              ...accum,
              laptop: true
            }
          }

          if (deviceTitle === 'mobile' && deviceStatus === true) {
            return {
              ...accum,
              mobile: true
            }
          }

          if (deviceTitle === 'tablet' && deviceStatus === true) {
            return {
              ...accum,
              tablet: true
            }
          }

          if (deviceTitle === 'desktop' && deviceStatus === true) {
            return {
              ...accum,
              desktop: true
            }
          }

          if (deviceTitle === 'tv' && deviceStatus === true) {
            return {
              ...accum,
              tv: true
            }
          }

          return accum
        },
        {
          tablet: false,
          mobile: false,
          laptop: false,
          desktop: false,
          tv: false
        }
      )

      return {
        id,
        planId,
        videoPlanId,
        planName: title,
        currency,
        country: country_name,
        price,
        maxResolution,
        description: 'Good', // We are setting by default to good because if below conditions not met it will be Good always.
        supportedDevices,
        nWatches,
        nDaysDownloadToExpire,
        isDownloadable,
        videoTitle,
        expireAfterStart: expire_max_min_after_start / 60,
        expireAfterPurchase: expire_max_mins_after_purchase / (60 * 24)
      }
    })

    const sortedPlans = _.orderBy(plans, ['price'], ['asc'])

    if (sortedPlans.length === 2) {
      sortedPlans[sortedPlans.length - 1].description = 'Best'
    } else if (sortedPlans.length >= 3) {
      sortedPlans[sortedPlans.length - 1].description = 'Better'
      sortedPlans[sortedPlans.length - 2].description = 'Best'
    }

    return sortedPlans
  } catch (error) {
    throw error
  }
}

export const fetchPaymentGateways = async (filter, accessToken = false) => {
  const payload = {
    query: listPaymentGateways,
    variables: {
      filter
    }
  }
  return accessToken ? await sendAxiosRequest(accessToken, payload) : await API.graphql(payload)
}
