import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, makeStyles, Modal, Typography } from '@material-ui/core'
import { Share as ShareIcon, Close as CloseIcon } from '@material-ui/icons'
import { useLocation } from 'react-router'
import { isFirefox, isMobileOnly } from 'react-device-detect'

import { Button } from '../../../components/Button'
import { SHARE_OPTIONS, USER_ACTIVITY_ACTIONS, EVENT_CATEGORIES } from '../../../lib/constant'
import errorHandler from '../../../lib/errorHandler'
import { addNewLog } from '../../../redux/modules/userActivityLogs'

export const ShareMenu = ({ title, imageUrl, description }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [width, setWidth] = useState(0)
  const classes = useStyles()
  const movieName = title.replaceAll(/ /g, '_').replaceAll('-', '_')
  const location = useLocation()

  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const url = `${process.env.REACT_APP_BASE_UR}${location.pathname}`

  const dispatch = useDispatch()

  const openShareMenu = () => {
    setWidth('100%')
    setTimeout(() => {
      setShowMenu(true)
    }, 200)
  }
  const closeShareMenu = () => {
    setWidth(0)
    setTimeout(() => {
      setShowMenu(false)
    }, 500)
  }

  const objectToGetParams = (data) => {
    let response = ``
    const keys = Object.keys(data)
    keys.map((item, index) => {
      response = `${response}${item}=${data[item]}${index === keys.length - 1 ? '' : '&'}`
    })
    return response
  }

  const handlePress = (id) => {
    if (id === 'fa fa-link') copyToClipboard()
    else if (id === 'fab fa-twitter') shareToTwitter()
    else if (id === 'fab fa-facebook-f') shareToFacebook()
    else if (id === 'fab fa-linkedin-in') shareToLonkedin()
    else if (id === 'far fa-envelope') shareToEmail()
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText('url')
      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
          page: movieName,
          path: location.pathname,
          method: 'Copy To Clipboard'
        })
      )
      window.gtag('event', 'share_page', {
        event_label: 'Title',
        path: location.pathname,
        method: 'Copy To Clipboard',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: movieName,
        device_type_id: deviceTypeId,
        ip_address: locationData.query
      })
    } catch (err) {
      errorHandler.report(err)
    }
  }

  const shareToTwitter = () => {
    const link =
      `https://twitter.com/share?` +
      objectToGetParams({
        url: url,
        text: title,
        hashtags: `mintrio,${movieName}`
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Twitter'
      })
    )

    window.gtag('event', 'share_page', {
      event_label: 'Title',
      path: location.pathname,
      method: 'Twitter',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: movieName,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToFacebook = () => {
    const link =
      `https://www.facebook.com/sharer/sharer.php?` +
      objectToGetParams({
        u: url,
        hashtag: `${movieName}`,
        caption: 'Mintrio'
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Facebook'
      })
    )

    window.gtag('event', 'share_page', {
      event_label: 'Title',
      path: location.pathname,
      method: 'Facebook',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: movieName,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToLonkedin = () => {
    const link =
      `https://linkedin.com/shareArticle?` +
      objectToGetParams({
        url: url,
        mini: 'true',
        title: title,
        summary: description,
        source: url
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'LinkedIn'
      })
    )

    window.gtag('event', 'share_page', {
      event_label: 'Title',
      path: location.pathname,
      method: 'LinkedIn',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: movieName,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToEmail = () => {
    const body = `${description}%0D%0D${url}`
    const link = `mailto:?&subject=Mintrio - ${title}&body=${body}`
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Email'
      })
    )

    window.gtag('event', 'share_page', {
      event_label: 'Title',
      path: location.pathname,
      method: 'Email',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: movieName,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  return (
    <div className={classes.shareMenuContainer}>
      <div
        style={{
          width: width
        }}
        className={classes.shareIconsContainer}>
        {!isMobileOnly &&
          SHARE_OPTIONS.map(({ id, color }) => (
            <IconButton key={id} onClick={() => handlePress(id)} className={classes.shareButton}>
              <i className={id} style={{ color, fontSize: 15 }}></i>
            </IconButton>
          ))}
      </div>

      <IconButton
        onClick={showMenu ? closeShareMenu : openShareMenu}
        className={classes.shareButton}
        style={{ padding: 0 }}>
        {showMenu ? (
          <CloseIcon className={classes.shareIcon} />
        ) : (
          <ShareIcon className={classes.shareIcon} />
        )}
      </IconButton>

      <Modal
        open={showMenu && isMobileOnly}
        onClose={closeShareMenu}
        className={classes.shareMenuModal}>
        <div className={classes.menuOuterContainer}>
          <Typography className={classes.shareToTitle}>Share To</Typography>
          <div className={classes.shareOptionsOuterContainer}>
            <ShareOptions title={title} url={url} description={description} />
          </div>

          <Button onClick={closeShareMenu} title={'Close'} className={classes.closeButton} />
        </div>
      </Modal>
    </div>
  )
}

const ShareOptions = ({ title, url, description }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const movieName = title.replaceAll(/ /g, '_').replaceAll('-', '_')

  const handlePress = (id) => {
    if (id === 'fa fa-link') copyToClipboard()
    else if (id === 'fab fa-twitter') shareToTwitter()
    else if (id === 'fab fa-facebook-f') shareToFacebook()
    else if (id === 'fab fa-linkedin-in') shareToLonkedin()
    else if (id === 'far fa-envelope') shareToEmail()
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText('url')
      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
          page: movieName,
          path: location.pathname,
          method: 'Copy To Clipboard'
        })
      )
    } catch (err) {
      errorHandler.report(err)
    }
  }

  const shareToTwitter = () => {
    const link =
      `https://twitter.com/share?` +
      objectToGetParams({
        url: url,
        text: title,
        hashtags: `mintrio,${movieName}`
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Twitter'
      })
    )
  }

  const shareToFacebook = () => {
    const link =
      `https://www.facebook.com/sharer/sharer.php?` +
      objectToGetParams({
        u: url,
        hashtag: `${movieName}`,
        caption: 'Mintrio'
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Facebook'
      })
    )
  }

  const shareToLonkedin = () => {
    const link =
      `https://linkedin.com/shareArticle?` +
      objectToGetParams({
        url: url,
        mini: 'true',
        title: title,
        summary: description,
        source: url
      })
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'LinkedIn'
      })
    )
  }

  const shareToEmail = () => {
    const body = `${description}%0D%0D${url}`
    const link = `mailto:?&subject=Mintrio - ${title}&body=${body}`
    window.open(link, '_blank')

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SHARE_PAGE, {
        page: movieName,
        path: location.pathname,
        method: 'Email'
      })
    )
  }

  const objectToGetParams = (data) => {
    let response = ``
    const keys = Object.keys(data)
    keys.map((item, index) => {
      response = `${response}${item}=${data[item]}${index === keys.length - 1 ? '' : '&'}`
    })
    return response
  }

  return (
    <div className={classes.shareOptionsContainer}>
      <div className={classes.optionsList}>
        {SHARE_OPTIONS.map(({ id, color, name }) => {
          return (
            <div
              role={'presentation'}
              onClick={() => handlePress(id)}
              key={id}
              className={classes.shareOptionButton}>
              <div className={classes.iconContainer}>
                <i className={id} style={{ color, fontSize: 30 }}></i>
              </div>
              <div className={classes.iconNameContainer}>
                <Typography className={classes.iconName}>{name}</Typography>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const useStyles = makeStyles((theme) => ({
  shareMenuContainer: {
    position: 'absolute',
    top: 10,
    right: 0,
    left: 0,
    zIndex: 3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
    padding: '0px 10px'
  },
  shareIconsContainer: {
    transition: 'width 1s',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'flex-end'
  },
  shareToTitle: {
    color: 'white',
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: '600',
    textAlign: 'center'
  },
  shareButton: {
    padding: '0px 10px',
    cursor: 'pointer',
    backgroundColor: '#51526C',
    height: 30,
    width: 35,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: '#51526C'
    }
  },
  shareIcon: {
    color: 'white',
    fontSize: 20
  },
  shareMenuModal: {
    background: 'rgba(255,255,255,0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  menuOuterContainer: {
    outline: 'none',
    padding: '30px 0px',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.6)',
    backdropFilter: 'blur(5px)',
    height: '30vh',
    width: '50vh',
    color: 'white',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      borderRadius: 0
    }
  },
  shareOptionsOuterContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    height: 30,
    width: 100,
    fontSize: 12,
    borderRadius: 3,
    color: 'white',
    backgroundColor: 'transparent',
    border: '1px solid white'
  },
  shareOptionsContainer: {
    width: '100%'
  },
  optionsList: {
    padding: '20px 0px'
  },
  shareOptionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px'
  },
  iconContainer: {
    width: 60
  },
  iconNameContainer: {
    width: 80
  },
  iconName: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: '600'
  }
}))
