import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: ({ socialIconsBottomOffset }) => `${socialIconsBottomOffset}px`,
    position: ({ position }) => position
  },
  iconsContainer: {
    backgroundColor: ({ socialIconsBackgroundColor, position }) =>
      position === 'static' ? 'none' : socialIconsBackgroundColor,
    margin: ({ position }) => (position === 'static' ? '7px 0px' : '20px 0px 0px 0px'),
    borderRadius: 5,
    display: 'flex',
    padding: '0px 10px',
    backdropFilter: ({ background_blurriness, position }) =>
      position === 'static' ? 'none' : `blur(${background_blurriness}px)`
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '30px',
    fontSize: '20px',
    color: ({ socialIconsColor }) => socialIconsColor
  }
}))
