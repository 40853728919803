import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh'
  },

  // temporary styles for coming soon
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  displayText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  divider: {
    backgroundColor: color.white,
    marginTop: '10px',
    marginBottom: '10px'
  },
  itemContainer: {
    justifyContent: 'center',
    height: '40px',
    borderRadius: '5px',
    display: 'flex',
    margin: '30px 10px 10px 10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '30px',
    cursor: 'no-drop',
    backgroundColor: color.grey,
    color: color.white
  }
}))
