import { makeStyles } from '@material-ui/core'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const ContentSkeleton = () => {
  const classes = useStyles()
  return (
    <div className={classes.contentSkeleton}>
      {[1, 2, 3, 4].map((item) => (
        <SkeletonItem key={item} />
      ))}
    </div>
  )
}

const SkeletonItem = () => {
  const classes = useStyles()

  return (
    <div className={classes.skeletonContainer}>
      <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
        <Skeleton height={'330px'} className={classes.skeleton} />
      </SkeletonTheme>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  skeletonContainer: {
    height: '330px',
    width: '240px',
    margin: '15px 30px'
  },
  skeleton: {
    marginBottom: 20,
    borderRadius: 0
  },
  contentSkeleton: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
}))
