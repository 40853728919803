import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { playTrailer } from '../../../redux/modules/trailerPlayer'

import { EVENT_CATEGORIES } from '../../../lib/constant'

export const TrailerSongCard = ({ item, videoThumbnail, thumbnailId, video }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const { thumbnail_sm, title } = item

  const handlePlayTrailer = () => {
    const payload = {
      isPlayerModalVisible: true,
      trailer: item
    }
    dispatch(playTrailer(payload))

    window.gtag('event', 'watch_trailer', {
      event_label: 'Song ID',
      content_type: payload.trailer.type,
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: payload.trailer.id,
      parent_video_id: video.id,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  return (
    <div id={thumbnailId} className={classes.item}>
      <img
        role="presentation"
        onClick={handlePlayTrailer}
        src={thumbnail_sm || videoThumbnail}
        alt={title}
        className={classes.image}
      />
      <Typography className={classes.title}>{title}</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '0px 10px',
    cursor: 'pointer'
  },
  image: {
    width: 310,
    height: 200,
    objectFit: 'cover',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 100
    }
  },
  title: {
    fontSize: 12,
    fontWeight: '600',
    color: 'white',
    textAlign: 'left'
  }
}))
