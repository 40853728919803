import React, { useEffect, useState, useReducer } from 'react'
import { CircularProgress, IconButton, Modal, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { setUpdateProfileModal } from '../../../redux/modules/globalModals'
import { useStyles } from './styles'
import { Header } from '../header'
import { showAlert } from '../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../lib/constant'
import { getInvitePromoCode } from '../../../api/user'
import { Footer } from './components/Footer'

export const UpdateProfile = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [{ promoCode, count, percentageOff }, setPromoCodeDetail] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      promoCode: null,
      count: null,
      percentageOff: null
    }
  )

  const { isUpdateProfileVisible } = useSelector((state) => state.globalModals)
  const { name, email } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isUpdateProfileVisible) {
      ;(async () => {
        try {
          setLoading(true)
          const { count, code, percentage_off } = await getInvitePromoCode(dispatch)
          setPromoCodeDetail({
            promoCode: code,
            count: count,
            percentageOff: percentage_off
          })
          setLoading(false)
        } catch (err) {
          dispatch(
            showAlert({
              message: err.message || 'Something went wrong please try again',
              type: ALERT_TYPES.ERROR,
              isVisible: true
            })
          )
          setLoading(false)
        }
      })()
    }
  }, [isUpdateProfileVisible, dispatch, setLoading, setPromoCodeDetail])

  const closeModal = () => dispatch(setUpdateProfileModal(false))

  return (
    <Modal
      open={isUpdateProfileVisible}
      className={classes.modal}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <Header title={'Profile Info'} closeModal={closeModal} />
        <div className={classes.transactionLoader}>
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              className={classes.prfileAvatar}
              color="inherit">
              <Typography className={classes.nameAlphabet}>
                {Boolean(name) ? name[0] : 'A'}
              </Typography>
            </IconButton>
          </div>
          <div className={classes.detail}>
            <div className={classes.item}>
              <Typography className={classes.itemTitle}>Name</Typography>
              <Typography className={classes.itemValue}>{`${name}`}</Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.itemTitle}>Email</Typography>
              <Typography className={classes.itemValue}>{email}</Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.itemTitle}>Account Status</Typography>
              <Typography className={classes.itemValue}>Active</Typography>
            </div>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={18} style={{ color: 'white' }} />
              </div>
            ) : (
              <>
                {count !== null && (
                  <div className={classes.item}>
                    <Typography className={classes.itemTitle}>
                      Successfull Referrals (Unblocks Awards)
                    </Typography>
                    <Typography className={classes.itemValue}>{count}</Typography>
                  </div>
                )}
                {promoCode && (
                  <>
                    <div className={classes.item}>
                      <Typography className={classes.itemTitle}>Invite Promo Code</Typography>
                      <Typography className={classes.itemValue}>{promoCode}</Typography>
                    </div>
                    <Footer
                      title="Mintrio Invitation"
                      description={`Check out Mintrio for a collection of premium exclusive content. Use my invite promo code for ${percentageOff}% off your first order: ${promoCode}`}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
