import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useMediaPredicate } from 'react-media-hook'
import { useLocation, useHistory } from 'react-router'

import { Button } from '../../../../../components/Button'
import PlanItem from './planItem'
import { usePlansListStyles } from './styles'
import { PromoCodeModal } from './promoCodeModal'
import { useSelector, useDispatch } from 'react-redux'
import { CHECKOUT_STEPS, USER_ACTIVITY_ACTIONS } from '../../../../../lib/constant'
import { setPromoCode, gratisCheckout, resetCheckout } from '../../../../../redux/modules/checkout'
import { addNewLog } from '../../../../../redux/modules/userActivityLogs'
import { setFullScreenSpinner } from '../../../../../redux/modules/globalModals'

export const PlansList = ({ plans, videoId, handleStep, login, accessToken }) => {
  const {
    plan: { price }
  } = useSelector((state) => state.checkout)

  const classes = usePlansListStyles()
  const [isPromoModalVisible, setIsPromoModalVisible] = useState(false)
  const [invalidCode, setInvalidCode] = useState(false)
  const dispatch = useDispatch()
  const lessThan700 = useMediaPredicate('(max-width: 650px)')
  const location = useLocation()
  const history = useHistory()

  const { plan, promoCode } = useSelector((state) => state.checkout)
  if (promoCode) var discounted_price = price - price * (promoCode.percentage_off / 100)

  const openPromoCodeModal = () => setIsPromoModalVisible(true)
  const closePromoCodeModal = () => setIsPromoModalVisible(false)

  const addCoupon = (isValid, detail) => {
    if (isValid && detail) dispatch(setPromoCode(detail))
    else setInvalidCode(true)

    setIsPromoModalVisible(false)
  }

  const handleSignIn = () => {
    history.push(`/sign-in?redirectPage=${location.pathname}`, {
      checkoutModalVisibility: true
    })
  }

  const setLoader = (loading, loadingText) => {
    dispatch(
      setFullScreenSpinner({
        loading,
        loadingText
      })
    )
  }

  const handleGratisOrder = async () => {
    setLoader(true, 'Processing...')
    try {
      await dispatch(gratisCheckout(accessToken))
      setLoader(false, '')
      handleStep(CHECKOUT_STEPS.POST_CHECKOUT)
      dispatch(resetCheckout())
    } catch (err) {
      console.log(err)
    }
  }

  const handleContinue = () => {
    if (!plan) return
    handleStep(CHECKOUT_STEPS.SELECT_PAYMENT_METHOD)

    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SELECT_PLAN, {
        video_id: videoId,
        country: plan.country,
        plan_id: plan.planId,
        plan_name: plan.planName,
        price: plan.price,
        currency: plan.currency,
        promocode: promoCode
      })
    )

    window.gtag('event', 'begin_checkout', {
      currency: plan.currency,
      coupon: promoCode,
      plan_id: plan.planId,
      plan_name: plan.planName,
      items: [
        {
          item_id: videoId,
          item_name: videoId
        }
      ]
    })

    window.fbq('trackCustom', 'begin_checkout', {
      currency: plan.currency,
      coupon: promoCode,
      plan_id: plan.planId,
      plan_name: plan.planName,
      video_id: videoId
    })
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.plansContainer}>
        <Grid container justify="center" style={{ display: lessThan700 ? 'block' : 'flex' }}>
          {plans.map((plan) => {
            return <PlanItem key={plan.id} item={plan} promoCode={promoCode} />
          })}
        </Grid>
      </div>
      {login && (
        <div className={classes.promoCodeTextContainer}>
          <Typography
            role="presentation"
            onClick={() => {
              if (!promoCode) openPromoCodeModal()
            }}
            className={
              promoCode
                ? clsx(classes.promoCodeText, classes.promoCodeApplied)
                : classes.promoCodeText
            }>
            {promoCode ? (
              'Code Applied'
            ) : invalidCode ? (
              <span style={{ color: 'red' }}>Invalid code. Try another one? ⟲</span>
            ) : (
              'Add Gift / Promo Code'
            )}
          </Typography>
        </div>
      )}
      {login || accessToken ? (
        <div className={classes.buttonContainer}>
          <Button
            onClick={price === 0 || discounted_price === 0 ? handleGratisOrder : handleContinue}
            title="Continue"
            className={classes.button}
          />
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          <Button onClick={handleSignIn} title="Sign In to Continue" className={classes.button} />
        </div>
      )}

      <PromoCodeModal
        addCoupon={addCoupon}
        isVisible={isPromoModalVisible}
        close={closePromoCodeModal}
        videoId={videoId}
      />
    </div>
  )
}
