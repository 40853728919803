import React from 'react'
import { Typography, Modal, IconButton } from '@material-ui/core'

import { useCastDetailModalStyles } from './styles'
import CloseIcon from '@material-ui/icons/Close'

export const CastDetailModal = ({ selectedItem, close }) => {
  const classes = useCastDetailModalStyles()

  const {
    imageUrl,
    cast_crew: { first_name, last_name, description, nick_name }
  } = selectedItem
  return (
    <Modal
      open={selectedItem ? true : false}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}>
      <div className={classes.container}>
        <Typography className={classes.name}>{`${nick_name}`}</Typography>
        <Typography className={classes.fullName}>{`${first_name} ${last_name}`}</Typography>
        <div className={classes.imageDetailContainer}>
          <div className={classes.imageContainer}>
            <img src={imageUrl} alt="" className={classes.image} />
          </div>
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description}>{description}</Typography>
          </div>
        </div>

        <IconButton onClick={close} className={classes.closeButton}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    </Modal>
  )
}
