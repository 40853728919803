import React from 'react'

import { useStyles } from './styles'
import { VerificationForm } from './components'
import { AuthenticationPagesWrapper, FormHeader } from '../../../components'

export const Verification = () => {
  const classes = useStyles()

  return (
    <AuthenticationPagesWrapper>
      <div className={classes.formContainer}>
        <FormHeader
          title={'Email Verification'}
          subTitle={'Check your email and enter your verification code'}
        />
        <VerificationForm />
      </div>
    </AuthenticationPagesWrapper>
  )
}
