import { makeStyles, withStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'
import { isFirefox } from 'react-device-detect'

export const useStyles = makeStyles({
  icon: {
    fill: 'white'
  },
  input: {
    width: '100%',
    color: 'white',
    marginTop: '0.9em'
  },
  unselectedInput: {
    width: '100%',
    color: '#8e8e99',
    marginTop: '0.9em'
  },
  select: {
    backgroundColor: isFirefox ? '#373535' : 'rgba(234,234,234,0.2)',
    backdropFilter: 'blur(30px)',
    border: 'none',
    maxHeight: 300,
    '& ul': {
      color: 'white'
    },
    '& li': {
      color: 'white'
    }
  }
})

export const InputBaseStyles = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    position: 'relative',
    width: '100%',
    border: `0`,
    fontSize: 16,
    borderRadius: 5,
    padding: '7px 26px 7px 12px',
    backgroundColor: isFirefox ? '#373535' : 'rgba(234,234,234,0.2)',
    backdropFilter: 'blur(30px)',

    '&:focus': {
      borderRadius: 5,
      borderColor: 'white',
      backgroundColor: isFirefox ? '#373535' : 'rgba(234,234,234,0.2)',
      backdropFilter: 'blur(30px)'
    }
  }
}))(InputBase)
