import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import { Container } from './container'
import {
  all,
  androidIcon,
  appleIcon,
  castIcon,
  mobilesIcon,
  tabletsIcon,
  tv,
  logo
} from '../../../assets'

export const AvailableDevices = () => {
  const classes = useStyles()
  return (
    <Container title={'Available on your favorite devices'}>
      <div className={classes.mainContainer}>
        <TVDevice />
        <TabletDevice />
        <PhoneDevice />
        <MintrioWeb />
      </div>
    </Container>
  )
}

const Device = ({ icon, children, style = {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.imageContainer}>
      <div className={classes.deviceImageContainer}>
        <img src={icon} style={{ ...style }} alt={'device'} className={classes.image} />
      </div>
      <div className={classes.tags}>{children}</div>
    </div>
  )
}

const Tag = ({ icon, style = {}, text, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.tagContainer}>
      <img src={icon} style={{ ...style }} className={classes.tagIcon} />
      <Typography className={classes.tagText}>{text}</Typography>
      <>{children}</>
    </div>
  )
}

const TVDevice = () => {
  return (
    <Device icon={tv} style={{ height: 60 }}>
      <Tag icon={castIcon} text={'Chromecast'}></Tag>
      <Tag icon={androidIcon} text={'Android TV'} />
      <Tag icon={appleIcon} text={'Apple TV'} />
    </Device>
  )
}

const TabletDevice = () => {
  return (
    <Device icon={tabletsIcon}>
      <Tag icon={androidIcon} text={'Andriod Tablet'} />
      <Tag icon={appleIcon} text={'Apple Tablet'} />
    </Device>
  )
}

const MintrioWeb = () => {
  return (
    <Device icon={all} style={{ height: 60 }}>
      <Tag icon={logo} text={'mintrio.com'} />
    </Device>
  )
}

const PhoneDevice = () => {
  return (
    <Device icon={mobilesIcon}>
      <Tag icon={androidIcon} text={'Andriod Phone'} />
      <Tag icon={appleIcon} text={'iPhone'} />
    </Device>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  deviceImageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  imageContainer: {
    flex: 1,
    height: 160,
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row'
    }
  },
  image: {
    height: '50px',
    [theme.breakpoints.down('xs')]: {
      height: '28px'
    }
  },
  text: {
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 20,
    padding: '0px 20px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  tags: {
    paddingTop: 10,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: '100%',
      padding: '10px 0px'
    }
  },
  tagContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tagText: {
    color: 'white',
    fontSize: 11,
    padding: '0px 7px'
  },
  tagIcon: {
    width: 10
  },
  limited: {
    width: 35,
    textAlign: 'center',
    color: 'white',
    fontSize: 6,
    textTransform: 'uppercase',
    fontWeight: '600',
    backgroundColor: '#E49700',
    padding: '1px 0px',
    borderRadius: 2
  },
  comingSoon: {
    width: 35,
    textAlign: 'center',
    color: 'white',
    fontSize: 5,
    textTransform: 'uppercase',
    fontWeight: '600',
    backgroundColor: '#282DDB',
    borderRadius: 2
  }
}))
