import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

import { listKnowledgeBaseCategories } from '../graphql/queries'
import { useSelector } from 'react-redux'
import errorHandler from '../lib/errorHandler'

export const useFetchKnowledgeBase = () => {
  const [questionsList, setQuestionsList] = useState([])
  const [loading, setLoading] = useState(true)
  const { login } = useSelector((state) => state.user)

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: {
            listKnowledgeBaseCategories: { items }
          }
        } = await API.graphql({
          query: listKnowledgeBaseCategories,
          authMode: login ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        })

        const sortedItems = items
          .filter((item) => item.Knowledge_base.items.length > 0)
          .sort((first, second) => first.precedence - second.precedence)

        setLoading(false)
        setQuestionsList(sortedItems)
      } catch (err) {
        errorHandler.report(err)
        setLoading(false)
      }
    })()
  }, [login, setQuestionsList, setLoading])

  return { questionsList, loading }
}
