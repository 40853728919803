import React, { useEffect, useState } from 'react'
import { AppBar, Hidden, List, ListItem, Toolbar, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { UserProfileMenu, SideDrawer, HideOnScroll } from './components'
import { logo } from '../../assets'
import { LOGGED_IN_LINKS, LINKS } from '../../lib/constant'
import { useStyles } from './styles'
import { useQuery } from '../../lib/utils'

export const Navigation = ({ navigation = true }) => {
  const classes = useStyles()

  const { login } = useSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLink, setSelectedLink] = useState('')
  const hideNavBars = useQuery().get('hide-nav-bars')
  const location = useLocation()

  const history = useHistory()

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    if (location.hash === '#discover' || location.pathname === '/') {
      setSelectedLink('/#discover')
    } else {
      setSelectedLink(location.pathname)
    }
  }, [location, setSelectedLink])

  const links = login ? LOGGED_IN_LINKS : LINKS

  if (!navigation) {
    return <></>
  }
  if (location.pathname === '/mobile-checkout') return null

  if (hideNavBars) return null
  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar component="nav">
            <div className={classes.navbarDisplayFlex}>
              <div className={classes.logoContainer} onClick={() => history.push('/')}>
                <img src={logo} alt="" className={classes.logo} />
                <Typography className={classes.mintrioText}>Mintrio</Typography>
              </div>
              <div className={classes.menusContainer}>
                <Hidden smDown>
                  <List
                    component="nav"
                    aria-labelledby="main navigation"
                    className={classes.navListDisplayFlex}>
                    {links.map(({ title, path }) =>
                      path === '/sign-in' ? null : (
                        <div key={title} className={classes.linkText}>
                          <ListItem
                            className={
                              selectedLink === path ? classes.selectedListItem : classes.listItem
                            }
                            button
                            onClick={() => history.push(path)}>
                            <Typography className={classes.linkText}>{title}</Typography>
                          </ListItem>
                        </div>
                      )
                    )}
                  </List>

                  {login ? (
                    <ListItem className={classes.loginListItem}>
                      <UserProfileMenu
                        handleClose={handleClose}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                      />
                    </ListItem>
                  ) : (
                    <div className={classes.linkText}>
                      <ListItem
                        className={
                          selectedLink === '/sign-in' ? classes.selectedListItem : classes.listItem
                        }
                        button
                        onClick={() => history.push('/sign-in')}>
                        <Typography className={classes.linkText}>Sign In</Typography>
                      </ListItem>
                    </div>
                  )}
                </Hidden>
              </div>
              <Hidden mdUp>
                <SideDrawer navLinks={links} />
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />
    </>
  )
}
