export const color = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  backgroundGrey: 'rgb(11,10,21)',
  readGrey: '#64648b',
  backgroundPurple: '#17162E',
  primaryColordock: 'rgba(23,22,46,0.8)',
  buttonPrimaryColor: '#3C3C63',
  buttonRed: '#DB2828',
  lightGrey: '#727272',
  red: 'red',
  readPurple: '#64648B',
  greypurple: 'rgba(66, 66, 93, 1)',
  buttonBlue: '#282DDB',
  green: '#5ECF63',
  lightsilver: 'rgba(216,216,216,1)',
  dock: 'rgba(50, 50, 50, 0.3)',
  newdock: 'rgba(22, 21, 42, 0.1)',
  darkdock: 'rgba(234,234,234,0.2)',
  blackdock: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#17162E',
  Amber: 'rgb(255,191,0)',
  genreColor: 'rgb(201,146,42)',
  modalColor: 'rgba(60,61,98,0.7)',
  modalColseIcon: 'rgba(60,61,98,0.9)',
  yellowColor: '#FFB800',
  lightgray: '#FCFCFC',
  unSelectedPlanColor: '#1e2082',
  grey: '#969696',
  modalbgtransperency: 'rgba(59,58,89,0.55)'
}
