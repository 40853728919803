import { makeStyles } from '@material-ui/core/styles'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mintrioText: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '600',
    paddingTop: 4,
    paddingLeft: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  logo: {
    height: '26px',
    paddingRight: '0.4em'
  },
  menusContainer: {
    flex: 1,
    border: '2 px solid white',
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 55
  },
  navbarDisplayFlex: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15
    }
  },
  navListDisplayFlex: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  linkText: {
    textDecoration: 'none',
    color: color.white,
    fontFamily: 'Rubik',
    margin: '0px 8px',
    padding: '10px 0px'
  },
  appbar: {
    backgroundColor: color.dock,
    height: 60,
    padding: 0,
    boxShadow: 'none',
    backdropFilter: 'blur(5px)',
    background: 'rgba(0, 0, 0, 0.2)'
  },
  notificationIcon: {
    color: 'white'
  },
  loginListItem: {
    width: 50
  },
  listItem: {
    backgroundColor: 'transparent',
    height: 30,
    borderRadius: 5
  },
  selectedListItem: {
    backgroundColor: 'rgba(64, 155, 206, 0.4)',
    height: 30,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(64, 155, 206, 0.4)'
    }
  }
}))
