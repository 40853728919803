import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  shareIconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shareToTitle: {
    color: 'white',
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: '600',
    textAlign: 'center'
  },
  shareButton: {
    padding: '0px 10px',
    cursor: 'pointer',
    backgroundColor: '#51526C',
    height: 30,
    width: 35,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    '&:hover': {
      backgroundColor: '#51526C'
    }
  },
  shareIcon: {
    color: 'white',
    fontSize: 20
  },
  shareMenuModal: {
    background: 'rgba(255,255,255,0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  menuOuterContainer: {
    outline: 'none',
    padding: '30px 0px',
    background: 'rgba(23,22,46,0.6)',
    // background: '#e0e5ec',
    backdropFilter: 'blur(5px)',
    height: '30vh',
    width: '50vh',
    color: 'white',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      borderRadius: 0
    }
  },
  shareOptionsOuterContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeButton: {
    height: 30,
    width: 100,
    fontSize: 12,
    borderRadius: 3,
    color: 'white',
    backgroundColor: 'transparent',
    border: '1px solid white'
  },
  shareOptionsContainer: {
    width: '100%'
  },
  optionsList: {
    padding: '20px 0px'
  },
  shareOptionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px'
  },
  iconContainer: {
    width: 60
  },
  iconNameContainer: {
    width: 80
  },
  iconName: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: '600'
  }
}))
