import React, { useLayoutEffect, useEffect, useReducer } from 'react'
import { Typography, makeStyles, IconButton } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { color } from '../../../styles/color'
import { isMobile, isSafari } from 'react-device-detect'

export const Container = ({
  title,
  listAlignment = 'center',
  children,
  scroll = false,
  cardId
}) => {
  const classes = useStyles()
  const id = title.toLowerCase().split(' ').join('-')
  const [{ disableLeftPagination, disableRightPagination, showPagination }, setPagination] =
    useReducer((state, newState) => ({ ...state, ...newState }), {
      disableLeftPagination: true,
      disableRightPagination: false,
      showPagination: false
    })

  const handleScroll = (value) => {
    const listContainer = document.getElementById(id)
    listContainer.scrollBy(value, 0)
  }
  useLayoutEffect(() => {
    function updateSize() {
      setPagination({
        showPagination:
          document.getElementById(id)?.scrollWidth + 20 >
          document.getElementById('movie-section')?.clientWidth - 20
      })
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [id, setPagination])

  useEffect(() => {
    const listContainer = document.getElementById(id)
    if (listContainer) {
      function updatePosition() {
        setPagination({
          disableLeftPagination: !listContainer.scrollLeft > 0,
          disableRightPagination:
            listContainer.clientWidth + listContainer.scrollLeft === listContainer.scrollWidth
        })
      }
      listContainer.addEventListener('scroll', updatePosition)
      updatePosition()
      return () => listContainer.removeEventListener('scroll', updatePosition)
    }
  }, [id, setPagination])

  return (
    <div className={classes.container} id={'movie-section'}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.listcontainer} style={{ justifyContent: listAlignment }} id={id}>
        {children}
      </div>

      {scroll && showPagination && (
        <>
          <div className={classes.leftArrowContainer}>
            <IconButton
              disabled={disableLeftPagination}
              onClick={() => {
                handleScroll(cardId ? -document.getElementById(cardId)?.clientWidth : -200)
              }}
              className={classes.iconButton}>
              <ArrowBack className={disableLeftPagination ? classes.disabledIcon : classes.icon} />
            </IconButton>
          </div>

          <div className={classes.rightArrowContainer}>
            <IconButton
              disabled={disableRightPagination}
              onClick={() =>
                handleScroll(cardId ? document.getElementById(cardId)?.clientWidth : 200)
              }
              className={classes.iconButton}>
              <ArrowForward
                className={disableRightPagination ? classes.disabledIcon : classes.icon}
              />
            </IconButton>
          </div>
        </>
      )}
    </div>
  )
}

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(39,38,69,0.8)',
    boxShadow: '0px 34px 64px rgba(0, 0, 0, 0.55)',
    borderRadius: '10px',
    marginTop: 10,
    padding: 20,
    position: 'relative'
  },
  title: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: '600',
    textAlign: 'center'
  },
  listcontainer: {
    display: 'flex',
    overflow: isSafari || isMobile ? 'auto' : 'hidden',
    width: '100%',
    paddingTop: 10,
    scrollBehavior: 'smooth'
  },
  leftArrowContainer: {
    position: 'absolute',
    left: 20,
    top: 15,
    zIndex: 10,
    display: 'flex'
  },
  rightArrowContainer: {
    position: 'absolute',
    right: 20,
    top: 15,
    zIndex: 10,
    display: 'flex'
  },
  icon: {
    color: 'white',
    fontSize: 17
  },
  disabledIcon: {
    color: 'gray',
    fontSize: 17
  },
  iconButton: {
    height: 23,
    width: 40,
    border: `2px solid ${color.greypurple}`,
    borderRadius: 5,
    '&:disabled': {
      border: `2px solid ${color.greypurple}`
    },
    '&:hover': {
      border: `2px solid ${color.greypurple}`
    }
  }
}))
