import React, { useState } from 'react'
import ReactCodeInput from 'react-verification-code-input'
import { CircularProgress, Typography } from '@material-ui/core'
import { Auth } from 'aws-amplify'

import { useStyles } from './styles'
import errorHandler from '../../lib/errorHandler'

export const CodeInput = ({ email, handleChange }) => {
  const classes = useStyles()
  const [resendingCode, setResendingCode] = useState(false)

  const handleResend = async () => {
    try {
      setResendingCode(true)
      await Auth.resendSignUp(email)
      setResendingCode(false)
    } catch (err) {
      errorHandler.report(err)
      setResendingCode(false)
    }
  }

  return (
    <div>
      <ReactCodeInput
        fieldHeight={45}
        fieldWidth={45}
        className={classes.codeInput}
        onChange={handleChange}
      />
      <Typography color="secondary" align="center" className={classes.resendCodeContainer}>
        {resendingCode ? (
          <CircularProgress size={14} color="white" />
        ) : (
          <span role="presentation" onClick={handleResend} className={classes.resendCodeText}>
            Resend Code
          </span>
        )}
      </Typography>
    </div>
  )
}
