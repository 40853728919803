import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useStyles } from './styles'

export const LoaderSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <Grid
        item
        xs={12}
        md={4}
        className={classes.leftcontainer}
        alignItems="center"
        justify="center"
        direction="column">
        <div className={classes.bannerMessageContainer}>
          <div className={classes.bannerSkeleton}>
            <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
              <Skeleton height={'20px'} />
            </SkeletonTheme>
          </div>
        </div>

        <div className={classes.titleSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'60px'} />
          </SkeletonTheme>
        </div>

        <div className={classes.subTitleSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'20px'} />
          </SkeletonTheme>
        </div>

        <div className={classes.buttonSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'40px'} />
          </SkeletonTheme>
        </div>
        <div className={classes.buttonSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'40px'} />
          </SkeletonTheme>
        </div>
      </Grid>

      <Grid item xs={0} md={8} className={classes.rightContainer}>
        <div className={classes.logoSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'200px'} />
          </SkeletonTheme>
        </div>
      </Grid>
    </div>
  )
}
