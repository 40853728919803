import React from 'react'
import { Grid } from '@material-ui/core'

import { useStyles } from './styles'
import { PlanSelector } from './planSelector'

export const PaymentCheckoutLayout = ({ plans, videoId, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <div className={classes.formContainer}>
        <Grid container justify="center" className={classes.gridContainer}>
          <Grid md={6} lg={4} xl={4} sm={12} xs={12}>
            {children}
            <PlanSelector plans={plans} videoId={videoId} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
