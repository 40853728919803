import { makeStyles } from '@material-ui/core'
import { color } from '../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputContainer: {
    padding: '0px 5px'
  },
  formHeaderContainer: {
    padding: '0px 5px',
    marginBottom: 30
  },

  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '40vh'
  },
  initialFormContainer: {
    paddingTop: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  centeredContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  h1: {
    fontFamily: 'Rubik',
    fontSize: '1.4rem',
    letterSpacing: '0.015em',
    marginTop: '0.5em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em',
      fontWeight: 'bold'
    }
  },
  h2: {
    fontSize: '2em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em'
    }
  },
  subtitle1: {
    fontSize: '0.9em',
    fontWeight: '300',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
      fontWeight: '200'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em',
      fontWeight: '200'
    }
  },
  subtitle2: {
    fontSize: '0.8em',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em'
    }
  },
  input: {
    color: color.white,
    border: 'none',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: '0.8em',
    height: 36,
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  errorMessage: {
    textAlign: 'center',
    color: color.red,
    fontSize: '14px'
  },
  alreadyHaveAccount: {
    color: 'white',
    padding: '20px 0px 0px 0px',
    textAlign: 'center',
    fontSize: 14
  },
  signInAnchor: {
    color: 'white',
    fontWeight: '500',
    cursor: 'pointer',
    textDecoration: 'none',
    paddingLeft: 10,
    '&:hover': {
      color: color.buttonBlue
    }
  }
}))

export const registrationStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputContainer: {
    padding: '0px 5px'
  },
  formHeaderContainer: {
    padding: '0px 5px',
    marginBottom: 10
  },

  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '40vh'
  },
  initialFormContainer: {
    paddingTop: '5vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  h1: {
    fontFamily: 'Rubik',
    fontSize: '1.4rem',
    letterSpacing: '0.015em',
    marginTop: '0.5em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em',
      fontWeight: 'bold'
    }
  },
  h2: {
    fontSize: '2em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em'
    }
  },
  subtitle1: {
    fontSize: '0.9em',
    fontWeight: '300',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em',
      fontWeight: '200'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em',
      fontWeight: '200'
    }
  },
  subtitle2: {
    fontSize: '0.8em',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7em'
    }
  },
  input: {
    color: color.white,
    border: 'none',
    borderRadius: 4,
    height: 36,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: '0.8em',
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  errorMessage: {
    textAlign: 'center',
    color: color.red,
    fontSize: '14px'
  },
  alreadyHaveAccount: {
    color: 'white',
    padding: '20px 0px',
    textAlign: 'center',
    fontSize: 14
  },
  signInAnchor: {
    color: 'white',
    fontWeight: '500',
    cursor: 'pointer',
    textDecoration: 'none',
    paddingLeft: 10
  },
  selectedAvatar: {
    height: '140px',
    [theme.breakpoints.down('sm')]: {
      height: '120px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '120px'
    }
  },
  avatarMainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 50
  },
  chooseAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  dobContainer: {
    width: '100%',
    display: 'flex'
  },
  flexOne: {
    flex: 1
  },
  monthInputCpontainer: {
    flex: 1,
    padding: '0px 20px'
  },
  phoneInputContainer: {
    width: '100%',
    display: 'flex'
  },
  codeInputContainer: {
    flex: 0.32,
    paddingRight: '20px'
  },
  mobileInputcontainer: {
    flex: 0.7
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 30
  },
  errorContainer: {
    color: 'red',
    textAlign: 'center',
    fontSize: 12,
    paddingTop: 10
  }
}))

export const avatarListStyles = makeStyles((theme) => ({
  gridList: {
    width: 635,
    maxHeight: 500
  },
  gridListMobile: {
    width: 300,
    maxHeight: 500
  },
  avatarItemMobile: {
    height: 110,
    width: '75%',
    borderRadius: 5,
    cursor: 'pointer'
  },
  avatarItemDesktop: {
    height: 140,
    borderRadius: 5,
    cursor: 'pointer'
  },
  avatarListModal: {
    outline: 'none',
    overflow: 'hidden',
    borderRadius: '10px',
    background: '#151429',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px 10px 0px',
      margin: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px 10px 0px',
      margin: '10px'
    }
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexContainer: {
    display: 'flex'
  },
  avatarModalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(30px)'
  },
  avatarButtons: {
    padding: '10px'
  }
}))
