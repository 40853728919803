import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { useDispatch } from 'react-redux'

import { Button } from '../'
import { setFullScreenSpinner } from '../../redux/modules/globalModals'
import { useStyles } from './styles'
import { fbSocialIcon, googleSocialIcon } from '../../assets'
import { Apple } from '@material-ui/icons'

export const SocialAuthButtons = ({ redirectPage = null }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = (provider) => {
    dispatch(setFullScreenSpinner({ loading: true, loadingText: 'Signing you in...' }))
    let signInPayload = { provider }
    if (redirectPage) signInPayload['customState'] = redirectPage
    Auth.federatedSignIn(signInPayload)
  }

  return (
    <>
      <div className={classes.socialAuthButtonsContainer} align="center">
        <Button
          startIcon={<img src={googleSocialIcon} width="70%" />}
          onClick={() => handleClick(CognitoHostedUIIdentityProvider.Google)}
          title="Continue with Google"
          color="primary"
          hover
          className={classes.googleAuthButton}
          classes={{ startIcon: classes.startIcon }}
          type="submit"
        />
      </div>
      <div className={classes.socialAuthButtonsContainer}>
        <Button
          startIcon={<img src={fbSocialIcon} width="70%" />}
          onClick={() => handleClick(CognitoHostedUIIdentityProvider.Facebook)}
          color="primary"
          hover
          title="Continue With Facebook"
          className={classes.facebookAuthButton}
          classes={{ startIcon: classes.startIcon }}
          type="submit"
        />
      </div>

      <div className={classes.socialAuthButtonsContainer}>
        <Button
          startIcon={<Apple className={classes.appleIcon} />}
          onClick={() => handleClick(CognitoHostedUIIdentityProvider.Apple)}
          color="primary"
          hover
          title="Continue With Apple"
          className={classes.facebookAuthButton}
          classes={{ startIcon: classes.startIcon }}
          type="submit"
        />
      </div>
    </>
  )
}
