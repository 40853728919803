import { useScrollTrigger, Slide } from '@material-ui/core'
import React from 'react'

export const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
