import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import { useSelector, useDispatch } from 'react-redux'

import { useStyles } from './styles'
import { showAlert } from '../../../../../../redux/modules/alertHandler'
import { ALERT_TYPES, CHECKOUT_STEPS } from '../../../../../../lib/constant'
import errorHandler from '../../../../../../lib/errorHandler'
import { verifyPayment } from '../../../../../../api/checkout'
import { setFullScreenSpinner } from '../../../../../../redux/modules/globalModals'

export const PaypalForm = ({ handleStep, accessToken }) => {
  const classes = useStyles()
  const { userId: user_id } = useSelector((state) => state.user)
  const { countryCode: country_code } = useSelector((state) => state.location)
  const {
    paymentGateway: { id: payment_gateway_id, name },
    videoId: video_id,
    plan: { price, videoPlanId: video_plan_id, id, currency },
    promoCode
  } = useSelector((state) => state.checkout)
  const dispatch = useDispatch()

  const showErrorMessage = (message) => {
    dispatch(
      showAlert({
        type: ALERT_TYPES.ERROR,
        message,
        isVisible: true
      })
    )
  }

  const setLoader = (loading, loadingText) => {
    dispatch(setFullScreenSpinner({ loading, loadingText }))
  }

  const onOrderSucess = async (details) => {
    setLoader(true, 'Preparing your order...')
    if (details && details.id && details.status === 'COMPLETED') {
      const body = {
        payment_gateway_id,
        user_id,
        video_plan_id,
        video_id,
        country_code,
        paymentId: details.id,
        price: promoCode ? price - price * (promoCode.percentage_off / 100) : price,
        promoCodeId: promoCode ? promoCode.id : null,
        payment_method: name
      }

      try {
        // verify payment and add order for user
        await verifyPayment(body, dispatch, accessToken)
        setLoader(false, '')
        handleStep(CHECKOUT_STEPS.POST_CHECKOUT)
      } catch (err) {
        errorHandler.report(err)
        setLoader(false, '')
        showErrorMessage('Unable to process your request. Please try again')
      }
    } else {
      setLoader(false, '')
      showErrorMessage('Unable to process your request')
    }
  }

  const onError = () => {
    dispatch(
      showAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Unable to process your request. Please try again',
        isVisible: true
      })
    )
  }

  return (
    <div className={classes.paypalContainer}>
      <PayPalButton
        currency={currency}
        amount={promoCode ? price - price * (promoCode.percentage_off / 100) : price}
        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        createOrder={(data, actions, err) => {
          return actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [
              {
                description: id,
                amount: {
                  currency_code: currency,
                  value: promoCode ? price - price * (promoCode.percentage_off / 100) : price
                }
              }
            ]
          })
        }}
        onSuccess={async (details, data) => onOrderSucess(details)}
        onError={onError}
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: currency
        }}
      />
    </div>
  )
}
