import { v4 as uuid4 } from 'uuid'
import { updateUserActivity } from '../../api/userActivity'
import errorHandler from '../../lib/errorHandler'
// constants
const ADD_LOG = 'ADD_LOG'
const RESET_LOGS = 'RESET_LOGS'

const addLog = (payload) => ({
  type: ADD_LOG,
  payload
})

const resetLogs = () => ({
  type: RESET_LOGS
})

export const addNewLog = (action_id, detail) => async (dispatch, getState) => {
  const {
    user: { userId: user_id },
    userActivityLogs
  } = getState()
  if (user_id) {
    const log = {
      id: uuid4(),
      action_id,
      user_id,
      additional_attributes: JSON.stringify(detail)
    }
    dispatch(addLog(log))
    if (userActivityLogs.length >= 10) {
      syncLogs(userActivityLogs, dispatch)
    }
  }
}

export const syncLogs = async (logs, dispatch) => {
  try {
    await updateUserActivity(logs, dispatch)
    dispatch(resetLogs())
  } catch (err) {
    errorHandler.report(err)
  }
}

// reducer
const userActivityLogs = []

const reducer = (state = userActivityLogs, action) => {
  const { payload, type } = action

  switch (type) {
    case ADD_LOG: {
      return [...state, { ...payload }]
    }
    case RESET_LOGS: {
      return []
    }
    default:
      return state
  }
}

export default reducer
