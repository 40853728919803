import { makeStyles } from '@material-ui/styles'
import { isFirefox } from 'react-device-detect'
import { color } from '../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    outline: 'none',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(10px)',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.8)',
    position: 'relative'
  },
  textContainer: {
    padding: 20
  },
  text: {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
    padding: '0px',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      fontWeight: '600'
    }
  },
  buttonContainer: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    color: 'white',
    backgroundColor: color.buttonBlue,
    width: 190,
    '&:hover': {
      backgroundColor: color.buttonBlue
    }
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    zIndex: 100
  },
  closeIcon: {
    color: 'white'
  }
}))
