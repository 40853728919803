import { howToWatchBackground } from '../../assets'
import { makeStyles } from '@material-ui/core'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: color.backgroundPurple,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  containerContent: {
    backgroundImage: `url(${howToWatchBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'auto',
    height: '100vh',
    flexDirection: 'column',
    display: 'flex'
  },
  gridContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 20px 0px 20px'
  },
  footerContainer: {
    width: '100vw',
    marginLeft: -20,
    marginRight: -20
  }
}))
