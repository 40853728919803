import React from 'react'

import { Container } from '.'
import { TrailerSongCard } from './trailerSongCard'

export const PreviewTrailers = ({ video }) => {
  const {
    trailers: { items: trailersList },
    thumbnail_sm
  } = video
  const trailers = trailersList.filter(
    (item) => item.type !== 'SONG' && item.type !== 'CONTENT_PREVIEW'
  )
  if (!trailers.length) return null
  return (
    <Container
      scroll={true}
      title={'Trailers & Clips'}
      cardId={'trailer-thumbnail'}
      listAlignment={trailers.length > 1 ? 'flex-start' : 'center'}>
      {trailers.map((trailer) => {
        return (
          <TrailerSongCard
            id="trailer-card"
            thumbnailId={'trailer-thumbnail'}
            videoThumbnail={thumbnail_sm}
            key={trailer.id}
            item={trailer}
            video={video}
          />
        )
      })}
    </Container>
  )
}
