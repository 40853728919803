import { makeStyles } from '@material-ui/styles'
import { isFirefox } from 'react-device-detect'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    outline: 'none',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.8)',
    backdropFilter: 'blur(10px)',
    minHeight: '55%',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      borderRadius: '0px'
    }
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
}))
