import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { Container } from './container'
import { useFetchKnowledgeBase } from '../../../hooks/useFetchKnowledgeBase'

export const FAQ = () => {
  const classes = useStyles()
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const { questionsList } = useFetchKnowledgeBase()

  const handleQuestion = (id) => {
    if (selectedQuestion === id) setSelectedQuestion('')
    else setSelectedQuestion(id)
  }

  const category = questionsList.filter((item) => item.title === 'Frequently Asked Questions')
  const FAQS = category.length ? category[0]?.Knowledge_base?.items : []
  if (!FAQS.length) return null
  return (
    <Container title={'Frequently asked questions'}>
      <div className={classes.mainContainer}>
        {FAQS.map(({ id, question, answer }) => {
          return (
            <div
              key={id}
              className={classes.questionContainer}
              style={{
                border: selectedQuestion === id ? '1px solid #585BCE' : '0px solid #585BCE'
              }}>
              <div className={classes.question}>
                <Typography
                  className={clsx(
                    classes.questionText,
                    selectedQuestion === id ? classes.gray : classes.white
                  )}>
                  {question}
                </Typography>
                <div>
                  <IconButton size={'small'} onClick={() => handleQuestion(id)}>
                    {id === selectedQuestion ? (
                      <RemoveIcon className={classes.icon} />
                    ) : (
                      <AddIcon className={classes.icon} />
                    )}
                  </IconButton>
                </div>
              </div>
              <Collapse in={id === selectedQuestion}>
                <Typography className={classes.answer}>{answer}</Typography>
              </Collapse>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    padding: '0px 10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: 0
    }
  },
  questionText: {
    color: 'white',
    flex: 1,
    textTransform: 'uppercase',
    fontWeight: '300',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  white: {
    color: 'white'
  },
  gray: {
    color: 'gray'
  },
  questionContainer: {
    backgroundColor: '#2B2A42',
    backdropFilter: 'blur(50px)',
    borderRadius: 5,
    marginTop: 10,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  question: {
    display: 'flex'
  },
  icon: {
    color: 'white',
    fontSize: 20
  },
  answer: {
    color: 'white',
    fontWeight: '300',
    fontSize: 15,
    paddingTop: 10
  }
}))
