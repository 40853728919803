import React, { useState } from 'react'
import { Typography, Avatar, makeStyles } from '@material-ui/core'

import { Container, CastDetailModal } from '.'

export const CastList = ({ video }) => {
  const {
    cast_crew: { items }
  } = video

  const [selectedItem, setSelectedItem] = useState(null)
  const openCastDetailModal = (item) => setSelectedItem(item)
  const closeCastDetailModal = () => setSelectedItem(null)

  return (
    <Container
      listAlignment={items.length > 3 ? 'flex-start' : 'center'}
      cardId={'cast-thumbnail'}
      scroll={true}
      title={'Cast & Crew'}>
      {items.map((castCrew) => {
        return (
          castCrew.cast_crew && (
            <CastCrewItem open={openCastDetailModal} key={castCrew.id} castCrew={castCrew} />
          )
        )
      })}
      {selectedItem && <CastDetailModal selectedItem={selectedItem} close={closeCastDetailModal} />}
    </Container>
  )
}

const CastCrewItem = ({ castCrew, open }) => {
  const classes = useStyles()

  const {
    cast_crew: { nick_name, image_sm },
    cast_crew_type: { title }
  } = castCrew

  const openDetail = () => open({ ...castCrew, imageUrl: image_sm })

  return (
    <div role="presentation" id="cast-thumbnail" onClick={openDetail} className={classes.castItem}>
      <Avatar alt={nick_name} src={image_sm} className={classes.avatar} />
      <Typography className={classes.name}>{nick_name}</Typography>
      <Typography className={classes.castType}>{title}</Typography>
    </div>
  )
}

export const useStyles = makeStyles(() => ({
  castItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: '0px 7px',
    minWidth: 80,
    cursor: 'pointer'
  },
  avatar: {
    height: 50,
    width: 50,
    alignItems: 'flex-start'
  },
  name: {
    color: 'white',
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: 5
  },
  castType: {
    color: 'white',
    fontWeight: '600',
    fontSize: 9,
    textTransform: 'uppercase'
  }
}))
