import { Grid, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'

import { useStyles } from './styles'
import { Footer, HelmetMetaData } from '../../components'
import { ContactUs, List, SearchResult, SkeletonList } from './components'
import { TextInput } from '../../components/TextInput'
import _ from 'lodash'
import { USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import { useDispatch } from 'react-redux'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import { useFetchKnowledgeBase } from '../../hooks/useFetchKnowledgeBase'
import { useQuery } from '../../lib/utils'
import { useHistory } from 'react-router-dom'

export const Help = () => {
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const { loading, questionsList } = useFetchKnowledgeBase()
  const [allQuestions, setAllQuestions] = useState(null)
  const hideNavBars = useQuery().get('hide-nav-bars')
  const search = useQuery().get('search')
  const [searchValue, setSearchValue] = useState(search || '')

  const classes = useStyles({ hideNavBars })

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (!loading) {
      let questions = []
      questionsList.forEach((item) => {
        questions = [...questions, ...item.Knowledge_base.items]
      })
      setAllQuestions(questions)
    }
  }, [loading, questionsList, setAllQuestions])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchValue)
      history.push(`?search=${searchValue}`)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    if (!_.isEmpty(search) && allQuestions) {
      const questions = allQuestions.filter((item) => {
        if (
          item.question.toLowerCase().includes(search.toLowerCase()) ||
          item.answer.toLowerCase().includes(search.toLowerCase())
        )
          return true

        return false
      })
      setFilteredQuestions(questions)
    }
  }, [allQuestions, search, setFilteredQuestions])

  useEffect(() => {
    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: 'help', path: '/help' }))
  }, [dispatch])

  const handleInput = (event) => {
    setSearchValue(event.target.value)
    console.log(search, 'param')
  }

  return (
    <>
      <HelmetMetaData
        title={'Mintrio - Help Center'}
        description={'Help Center'}
        hashtag={'#help #faq'}
      />
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <div className={classes.gridContainer}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <div className={classes.helpTextContainer}>
                <Typography className={classes.helpText}>Help Center</Typography>
              </div>
              <div className={classes.inputContainer}>
                <TextInput
                  search={true}
                  name="Search"
                  value={searchValue}
                  onChange={handleInput}
                  placeholder="What do you need help with?"
                  className={classes.inputField}
                />
              </div>
              {loading ? (
                <SkeletonList />
              ) : _.isEmpty(search) ? (
                <List questionsList={questionsList} />
              ) : (
                <SearchResult data={filteredQuestions} />
              )}
            </Grid>
          </div>
          <ContactUs />
          {!hideNavBars && <Footer />}
        </div>
      </div>
    </>
  )
}
