import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './styles'

export const GridWRapper = ({ children }) => {
  const classes = useStyles()
  return (
    <Grid item lg={12} md={12} xs={12} sm={12} className={classes.gridWrapper}>
      {children}
    </Grid>
  )
}
