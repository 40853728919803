import React from 'react'
import { useStyles } from './styles'

export function DividerWithText({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.container} align="center">
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  )
}
