import { makeStyles } from '@material-ui/styles'
import { color } from '../../../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    height: '40px',
    borderRadius: '5px',
    display: 'flex',
    margin: '30px 10px 10px 10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '30px',
    cursor: 'pointer',
    backgroundColor: color.buttonPrimaryColor,
    color: color.white,
    '&:hover': {
      backgroundColor: color.buttonBlue
    }
  },
  selected: {
    backgroundColor: color.buttonBlue
  },
  paymentMethodTitle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      flex: 1.5
    }
  },
  visaImagesContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  rightArrowIcon: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rightArrow: {
    color: '#ffffff'
  }
}))
