import { Typography } from '@material-ui/core'
import React from 'react'

import { Button } from '../components/Button'
import errorHandler from '../lib/errorHandler'
import { color } from '../styles/color'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    errorHandler.report(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            backgroundColor: color.primaryColor,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            style={{ color: 'white', fontSize: 80, fontWeight: 'bold', paddingBottom: 10 }}>
            OOPS!
          </Typography>
          <Typography
            style={{ color: 'white', fontSize: 25, textAlign: 'center', fontWeight: '200' }}>
            Sorry for the inconvenience.
            <br /> The error has been reported.
          </Typography>
          <div style={{ paddingTop: 10 }}>
            <a href={'/'} style={{ textDecoration: 'none' }}>
              <Button
                title="Back to Home"
                style={{
                  width: '180px',
                  height: '40px',
                  backgroundColor: color.buttonBlue,
                  color: color.white,
                  fontWeight: '500',
                  fontSize: '14px',
                  borderRadius: 6,
                  fontFamily: 'Rubik'
                }}
                hover
              />
            </a>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
