import React, { useReducer, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { resetPasswordStyles } from './styles'
import { CodeInput, GridWRapper, TextInput, Button } from '../../../../components'
import { validateResetPasswordForm } from '../../../../lib/utils'
import errorHandler from '../../../../lib/errorHandler'
import { showAlert } from '../../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../../lib/constant'

export const ResetPasswordForm = ({ handleStep, email }) => {
  const classes = resetPasswordStyles()

  const [{ code, password, isValid, loading }, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { isValid: false, loading: false, code: '', password: '' }
  )

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (validateResetPasswordForm(email, code, password)) setData({ isValid: true })
    else setData({ isValid: false })
  }, [email, code, password])

  const goBack = () => handleStep(0)

  const handleChange = (value) => setData({ code: value })

  const passwordChanges = ({ target: { value } }) => setData({ password: value })

  const handleResetPassword = async (event) => {
    if (event) event.preventDefault()
    if (loading) return

    try {
      setData({ loading: true })
      await Auth.forgotPasswordSubmit(email, code, password)
      history.push('/sign-in')
      setData({ loading: false })
    } catch (err) {
      errorHandler.report(err)
      dispatch(
        showAlert({
          isVisible: true,
          message: err.message || 'Invalid verification code',
          type: ALERT_TYPES.ERROR
        })
      )
      setData({ loading: false })
    }
  }

  return (
    <form onSubmit={handleResetPassword} className={classes.initialFormContainer}>
      <Grid container md={5} lg={5} xs={10} sm={10}>
        <GridWRapper>
          <CodeInput email={email} handleChange={handleChange} />
        </GridWRapper>
        <GridWRapper>
          <TextInput
            value={password}
            name="password"
            type="password"
            placeholder="New Password"
            className={classes.input}
            onChange={passwordChanges}
          />
        </GridWRapper>
        <div className={classes.buttonsContainer}>
          <div className={classes.goBack}>
            <GridWRapper>
              <Button
                title="Back"
                color="primary"
                hover
                style={{ width: '100%', height: 36 }}
                onClick={goBack}
              />
            </GridWRapper>
          </div>
          <div className={classes.continueButton}>
            <GridWRapper>
              <Button
                title="Continue"
                color="primary"
                hover
                disabled={!isValid}
                loading={loading}
                onClick={handleResetPassword}
                style={{ width: '100%', height: 36 }}
                type="submit"
              />
            </GridWRapper>
          </div>
        </div>
      </Grid>
    </form>
  )
}
