import { IconButton, Menu, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { PROFILE_DROPDOWN_MENUS } from '../../../lib/constant'
import { usePaneStyles } from './styles'
import { postLogoutUser } from '../../../redux/modules/user'
import {
  setTransactionHistoryModal,
  setUpdateProfileModal
} from '../../../redux/modules/globalModals'
import errorHandler from '../../../lib/errorHandler'

export const UserProfileMenu = ({ handleClose }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = usePaneStyles()
  const { login, name } = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleProfileMenuClick = (id) => {
    handleClose()
    if (id === '3') signOut()
    else if (id === '1') dispatch(setTransactionHistoryModal(true))
    else if (id === '0') dispatch(setUpdateProfileModal(true))
    else if (id === '2') history.push('/help')
    handleMenuClose()
  }

  const signOut = async () => {
    try {
      await dispatch(postLogoutUser())

      handleClose()
    } catch (err) {
      errorHandler.report(err)
    }
  }

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        className={classes.prfileAvatar}
        color="inherit">
        <Typography className={classes.nameAlphabet}>{Boolean(name) ? name[0] : 'A'}</Typography>
      </IconButton>
      <Menu
        className={classes.menuRoot}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={'primary-search-profile-menu-mobile'}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <div className={classes.centeredContainer}>
          <div className={classes.topArrow} />
        </div>

        {login && <Typography className={classes.dropDownMenuName}>{name}</Typography>}

        {PROFILE_DROPDOWN_MENUS.map((item) => {
          return (
            <div className={classes.customMenuContainer} role={'presentation'}>
              <div
                className={classes.customMenuItem}
                role="presentation"
                onClick={() => handleProfileMenuClick(item.id)}>
                <Typography className={classes.menuTitle}>{item.title}</Typography>
              </div>
            </div>
          )
        })}
      </Menu>
    </>
  )
}
