import React from 'react'
import { EmptyList, Loading } from '..'
import { PaymentMethodsList } from './components'

export const PaymentMethods = ({ videoId, handleStep, paymentMethods, loading, setProcessing }) => {
  if (loading) return <Loading />

  if (!loading && paymentMethods.length === 0)
    return <EmptyList text={'No payment method is available in this country'} />

  return (
    <PaymentMethodsList
      paymentMethods={paymentMethods}
      videoId={videoId}
      handleStep={handleStep}
      setProcessing={setProcessing}
    />
  )
}
