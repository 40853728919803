import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useAlertStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'black',
      width: '100%',
      boxShadow: 'none',
      padding: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    alertContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontSize: 14
    },
    error: {
      backgroundColor: '#FF4F4F'
    },
    success: {
      backgroundColor: '#79B283'
    }
  })
)
