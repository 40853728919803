import { makeStyles } from '@material-ui/styles'

export const useHeaderStyles = makeStyles((theme) => ({
  headerMainContainer: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '15vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  headerTitle: {
    fontSize: 18,
    fontFamily: 'Rubik',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0px 10px 10px 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  backIconContainer: {
    position: 'absolute',
    bottom: -9,
    left: '7%',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      bottom: -12,
      left: '2%'
    }
  },
  backIcon: {
    color: 'white'
  },
  content: {
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    flex: 1
  },
  end: {
    alignItems: 'flex-end'
  },
  headerIcons: {
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    [theme.breakpoints.down('xs')]: {
      bottom: -10
    }
  },
  closeIconButton: {
    position: 'absolute',
    bottom: 10,
    right: '1%',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      bottom: 10,
      right: '1%'
    }
  },
  closeIcon: {
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: 19
    }
  }
}))

export const useLoadingStyles = makeStyles(() => ({
  loadingMainContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    color: 'white'
  }
}))

export const useEmptyContainerStyles = makeStyles(() => ({
  emptyListContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyListText: {
    color: 'white',
    fontFamily: 'Rubik',
    fontWeight: '500'
  }
}))
