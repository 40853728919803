import React, { useEffect, useState } from 'react'
import { Grid, Modal } from '@material-ui/core'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getVideoById } from '../../redux/modules/videos'
import { resetCheckout } from '../../redux/modules/checkout'
import {
  CastList,
  MovieDetail,
  PreviewTrailers,
  WatchOptions,
  PreviewSkeleton,
  WatchOnDevices,
  PreviewSongs
} from './components'
import { useStyles } from './styles'
import { getOrder } from '../../redux/modules/myContent'
import { HelmetMetaData } from '../../components'
import { Checkout } from '../../pages/checkout'
import { USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import { color } from '../../styles/color'
import errorHandler from '../../lib/errorHandler'
import { useQuery } from '../../lib/utils'
export const Preview = () => {
  const classes = useStyles()
  const query = new URLSearchParams(useLocation().search)

  const checkoutStatus = query.get('checkout-status')
  const { mobileCheckoutData } = useSelector((state) => state.user)
  const { price } = useSelector((state) => state.videos)
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth)

  const [loading, setLoading] = useState(true)
  const [video, setVideo] = useState(null)
  const [orderDetail, setOrderDetail] = useState(null)
  const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState(false)

  const { videoId } = useParams()
  const location = useLocation()
  const checkoutSource = useQuery().get('checkout-source')

  const { userId, login } = useSelector((state) => state.user)
  const { orders } = useSelector((state) => state.myContent)
  const { countryCode } = useSelector((state) => state.location)

  const dispatch = useDispatch()
  const history = useHistory()

  const locationState = location.state
  const openCheckoutModal = () => setIsCheckoutModalVisible(true)
  const closeCheckoutModal = (isPurchased = false) => {
    if (isPurchased) history.replace(location.pathname.split('?')[0])

    setIsCheckoutModalVisible(false)
    dispatch(resetCheckout())
  }
  useEffect(() => {
    if (locationState && locationState.checkoutModalVisibility) {
      openCheckoutModal()
    }
  }, [locationState])
  useEffect(() => {
    if (orders.length > 0 && videoId && login) {
      const filterdOrders = orders.filter((order) => order.video_id === videoId)
      setOrderDetail(filterdOrders.length > 0 ? filterdOrders[0] : null)
    } else {
      setOrderDetail(null)
    }
  }, [orders, login, setOrderDetail, videoId])

  useEffect(() => {
    if (videoId && countryCode) {
      setVideo(null)
      setLoading(true)
      ;(async () => {
        try {
          let data = await dispatch(getVideoById(videoId))
          if (login) await dispatch(getOrder(videoId, data))

          setVideo(data)
          setLoading(false)
        } catch (err) {
          errorHandler.report(err)
          history.push('/')
        }
      })()
    }
  }, [videoId, userId, history, login, countryCode, dispatch, setVideo, setLoading])

  useEffect(() => {
    if (video) {
      window.gtag('event', 'view_item', {
        items: [
          {
            item_id: videoId,
            item_name: video.title,
            location: countryCode
          }
        ]
      })
      window.fbq('trackCustom', 'view_item', {
        video_id: videoId,
        video_name: video.title,
        location: countryCode
      })
    }
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: 'preview', path: location.pathname })
    )
  }, [dispatch, location, video, videoId, countryCode])

  useEffect(() => {
    if (checkoutStatus) {
      if (window.ReactNativeWebView && mobileCheckoutData) {
        history.push(
          `/mobile-checkout?access-token=${mobileCheckoutData?.accessToken}&video-id=${mobileCheckoutData?.videoId}&user-id=${mobileCheckoutData.userId}&email=${mobileCheckoutData.email}&checkout-status=${checkoutStatus}&checkout-source=nativeApp`
        )
      }
      setIsCheckoutModalVisible(true)
    }
  }, [checkoutStatus, location.pathname, mobileCheckoutData, history])

  useEffect(() => {
    const resizeWindow = () => changeDeviceSize(window.innerWidth)
    window.addEventListener('resize', resizeWindow) // Update the width on resize
    return () => window.removeEventListener('resize', resizeWindow)
  }, [changeDeviceSize])

  return (
    <div className={classes.mainContainer}>
      <HelmetMetaData
        title={video ? video.title : 'Mintrio'}
        image={video ? video.thumbnail_lg : ''}
        description={video ? video.long_description : ''}
        hashtag={video ? `#${video.title}` : ''}
      />
      {deviceSize > 480 ? (
        <Grid
          container
          alignItems="center"
          direction="column"
          className={`${classes.movieBannerContainer}`}
          style={{
            backgroundImage: `linear-gradient(to top, 
            ${color.primaryColor},
            ${color.primaryColordock} ,
            rgba(23,22,46,0.6) ,
            transparent, transparent), 
            url(${video ? video.thumbnail_lg : ''})`
          }}>
          {loading ? (
            <PreviewSkeleton />
          ) : (
            video && (
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.previewContainer}>
                <MovieDetail
                  imageUrl={video.thumbnail_lg}
                  item={video}
                  vidId={videoId}
                  price={price}
                  openCheckoutModal={openCheckoutModal}
                  orderDetail={orderDetail}
                />
                {/* {(!orderDetail ||
                (orderDetail && (!orderDetail.purchased || orderDetail.expired))) && (
                <WatchOptions
                  video={video}
                  openCheckoutModal={openCheckoutModal}
                  videoId={videoId}
                  price={price}
                />
              )} */}
                {orderDetail && orderDetail.purchased && !orderDetail.expired && (
                  <WatchOnDevices orderDetail={orderDetail} />
                )}

                <PreviewTrailers video={video} />
                <PreviewSongs video={video} />
                <CastList video={video} />
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          direction="column"
          className={`${classes.mobileMovieBannerContainer}`}
          style={{
            backgroundImage: `linear-gradient(to bottom, 
            ${color.primaryColor},
            ${color.primaryColordock} ,
            rgba(23,22,46,0.6) ,
            transparent, transparent), 
            url(${video ? video.thumbnail_lg : ''})`
          }}>
          <div
            className={`${classes.mobileImageBanner}`}
            style={{
              backgroundImage: `linear-gradient(to top, 
            ${color.primaryColor},
            ${color.primaryColordock} ,
            rgba(23,22,46,0.6) ,
            transparent, transparent), 
            url(${video ? video.thumbnail_lg : ''})`
            }}></div>
          <div className={`${classes.MobileContent}`}>
            {loading ? (
              <PreviewSkeleton />
            ) : (
              video && (
                <Grid item xs={12} sm={12} md={6} lg={6} className={classes.mobilePreviewContainer}>
                  <MovieDetail
                    imageUrl={video.thumbnail_lg}
                    item={video}
                    orderDetail={orderDetail}
                    vidId={videoId}
                    price={price}
                    openCheckoutModal={openCheckoutModal}
                  />
                  {/* {(!orderDetail ||
                  (orderDetail && (!orderDetail.purchased || orderDetail.expired))) && (
                  <WatchOptions
                    video={video}
                    openCheckoutModal={openCheckoutModal}
                    videoId={videoId}
                    price={price}
                  />
                )} */}
                  {orderDetail && orderDetail.purchased && !orderDetail.expired && (
                    <WatchOnDevices orderDetail={orderDetail} />
                  )}

                  <PreviewTrailers video={video} />
                  <PreviewSongs video={video} />
                  <CastList video={video} />
                </Grid>
              )
            )}
          </div>
        </Grid>
      )}
      <Modal open={isCheckoutModalVisible && videoId} className={classes.checkoutModal}>
        <div className={classes.checkoutContainer}>
          <Checkout
            checkoutStatus={checkoutStatus}
            videoId={videoId}
            closeCheckoutModal={closeCheckoutModal}
            checkoutSource={checkoutSource}
          />
        </div>
      </Modal>
    </div>
  )
}
