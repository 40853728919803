import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'

import { Container } from '.'
import { TVE, MBE, TBE, LTE, TVD, MBD, TBD, LTD } from '../../../assets'

export const WatchOnDevices = ({ orderDetail }) => {
  const classes = useStyles()
  const { userId } = useSelector((state) => state.user)

  const [{ tv, desktop, mobile, tablet }, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tv: true,
      desktop: true,
      mobile: true,
      tablet: true
    }
  )

  useEffect(() => {
    if (userId && orderDetail && orderDetail.purchased && !orderDetail.expired) {
      const {
        video_plan: {
          plan: {
            device_types: { items }
          }
        }
      } = orderDetail
      const devices = items.map((item) => {
        return item.device_types.title
      })
      const showTv = devices.includes('TV')
      const showDesktop = devices.includes('Desktop')
      const showTablet = devices.includes('Tablet')
      const showMobile = devices.includes('Mobile')
      setData({ tv: showTv, desktop: showDesktop, mobile: showTablet, tablet: showMobile })
    } else {
      setData({ tv: true, desktop: true, mobile: true, tablet: true })
    }
  }, [userId, orderDetail, setData])

  return (
    <Container title={'Watch on'}>
      <div className={classes.mainContainer}>
        <div className={classes.item}>
          <div className={classes.iconContainer}>
            <img src={tv ? TVE : TVD} alt="TV" className={classes.tvIcon} />
          </div>
          <Typography
            className={classes.activeText}
            style={{
              color: tv ? 'white' : 'rgb(96,94,106)'
            }}>
            TV
          </Typography>
        </div>
        <div className={classes.item}>
          <div className={classes.iconContainer}>
            <img src={desktop ? LTE : LTD} alt="Desktop" className={classes.desktopIcon} />
          </div>
          <Typography
            className={classes.activeText}
            style={{
              color: desktop ? 'white' : 'rgb(96,94,106)'
            }}>
            Desktop
          </Typography>
        </div>
        <div className={classes.item}>
          <div className={classes.iconContainer}>
            <img src={tablet ? TBE : TBD} alt="Tablet" className={classes.tabletIcon} />
          </div>
          <Typography
            className={classes.activeText}
            style={{
              color: tablet ? 'white' : 'rgb(96,94,106)'
            }}>
            Tablet
          </Typography>
        </div>
        <div className={classes.item}>
          <div className={classes.iconContainer}>
            <img src={mobile ? MBE : MBD} alt="Mobile" className={classes.mobileIcon} />
          </div>
          <Typography
            className={classes.activeText}
            style={{
              color: mobile ? 'white' : 'rgb(96,94,106)'
            }}>
            Mobile
          </Typography>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    padding: '0px 9%',
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  item: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  activeText: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  iconContainer: {
    height: 70,
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      height: 50
    }
  },
  tvIcon: {
    height: 70,
    width: 70,
    [theme.breakpoints.down('xs')]: {
      height: 50,
      width: 50
    }
  },
  desktopIcon: {
    height: 60,
    width: 60,
    [theme.breakpoints.down('xs')]: {
      height: 45,
      width: 45
    }
  },
  tabletIcon: {
    height: 45,
    width: 45,
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      height: 35,
      width: 35,
      paddingBottom: 8
    }
  },
  mobileIcon: {
    height: 40,
    width: 40,
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 30,
      paddingBottom: 8
    }
  }
}))
