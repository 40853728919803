import React from 'react'
import { isMobile } from 'react-device-detect'
import '../player.css'
import { PlayerKeyboardControl } from '../plugins/playerKeyboardControl'
import { QuarterlyFlags } from '../plugins/quarterlyFlags'
import ViewLogs from '../plugins/viewLogs'
import { Detail } from '../skin/detail'
import { RentButton } from '../skin/rentButton'

class SafariPlayer extends React.Component {
  state = {
    player: null,
    loaded: false,
    previewEnded: false
  }

  constructor(props) {
    super(props)
    this.playerDiv = React.createRef()
  }

  componentDidMount() {
    this.setupPlayer()
  }

  componentWillUnmount() {
    this.destroyPlayer()
  }

  closePlayer = () => {
    this.setState({ player: null }, () => {
      this.props.close()
    })
  }

  setupPlayer() {
    const {
      title,
      url,
      poster,
      lastTimeStamp,
      playMovie,
      pauseMovie,
      stallMovie,
      updateFlags,
      updateViewLogs,
      updateWatchTime,
      isProtected,
      volume,
      handleVolumeChanges,
      handleRedirect,
      handleBack,
      trailerType,
      symbol,
      price
    } = this.props

    const config = {
      key: process.env.REACT_APP_BITMOVIN_KEY,
      playback: {
        autoplay: true,
        volume
      }
    }
    const source = {
      title,
      hls: url,
      poster: poster
    }

    var container = document.getElementById('player')
    var player = new window.bitmovin.player.Player(container, config)

    this.setState({
      ...this.state,
      player
    })
    new PlayerKeyboardControl(player)

    // Setup the player
    player.load(source).then(
      () => {
        if (isMobile) {
          player.setViewMode('fullscreen')
          let fullscreen = false
          player.on(window.bitmovin.player.PlayerEvent.ViewModeChanged, () => {
            if (player.getViewMode() === 'fullscreen') fullscreen = true
            else if (
              fullscreen === true &&
              player.getViewMode() === 'inline' &&
              this.props.trailerType !== 'CONTENT_PREVIEW'
            ) {
              player.destroy()
              this.closePlayer()
            }
          })
        }

        document.querySelector('button.bmpui-ui-watermark')?.setAttribute('disabled', 'disabled')
        const videoPlayer = document.getElementById('bitmovinplayer-video-player')

        // protected video settings and plugins
        if (isProtected) {
          if (lastTimeStamp && lastTimeStamp > 0 && lastTimeStamp < player.getDuration() - 15) {
            player.seek(lastTimeStamp)
            playMovie(lastTimeStamp)
          }
          new ViewLogs({
            updateViewLogs,
            updateWatchTime,
            playMovie,
            pauseMovie,
            stallMovie,
            close: this.closePlayer
          })

          new QuarterlyFlags({ updateFlags: updateFlags })
        } else {
          videoPlayer.onended = () => {
            if (this.props.trailerType === 'CONTENT_PREVIEW') {
              this.setState({ previewEnded: true })
              player.setViewMode('inline')
              document.querySelector('button.bmpui-ui-hugereplaybutton').style.display = 'none'
            } else this.closePlayer()
          }
        }
        this.setState({ loaded: true })
      },
      (err) => {
        console.log('Error in playing video is: ', err)
        player.destroy()
        this.closePlayer()
      }
    )
    player.on(window.bitmovin.player.PlayerEvent.VolumeChanged, ({ targetVolume }) => {
      handleVolumeChanges(targetVolume)
    })
  }

  destroyPlayer() {
    if (this.state.player != null) {
      this.setState({
        ...this.state,
        player: null
      })
    }
  }

  handleCustomCloseButton = () => {
    this.state.player.destroy()
    this.setState({ player: null }, () => {
      this.props.close()
    })
  }

  render() {
    return (
      <div className="player-root-container">
        <div id="player" className="player-container" />
        {this.state.player && this.props.isProtected && (
          <Detail
            player={this.state.player}
            title={this.props.title}
            description={this.props.description}
          />
        )}
        <div className="safari-back-button-container">
          <div
            role="presentation"
            className="safari-back-button"
            onClick={this.handleCustomCloseButton}
          />
        </div>
        {!this.state.loaded && (
          <div className="bmpui-ui-buffering-overlay loading-container">
            <div className="bmpui-ui-buffering-overlay-indicator" role="img" />
            <div className="bmpui-ui-buffering-overlay-indicator" role="img" />
            <div className="bmpui-ui-buffering-overlay-indicator" role="img" />
          </div>
        )}
        {this.state.previewEnded && (
          <>
            <div className="bmpui-ui-buffering-overlay preview-container">
              <RentButton
                symbol={this.props.symbol}
                price={this.props.price}
                handleRedirect={this.props.handleRedirect}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default SafariPlayer
