import React, { useEffect, useReducer } from 'react'
import { Grid } from '@material-ui/core'
import validator from 'validator'
import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import { resetPasswordStyles } from './styles'
import { GridWRapper, TextInput, Button } from '../../../../components'
import errorHandler from '../../../../lib/errorHandler'
import { showAlert } from '../../../../redux/modules/alertHandler'
import { ALERT_TYPES } from '../../../../lib/constant'

export const EmailForm = ({ handleStep, email, handleChange }) => {
  const classes = resetPasswordStyles()
  const [{ isValid, loading }, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      isValid: false,
      loading: false,
      error: null
    }
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (validator.isEmail(email.trim().toLowerCase())) setData({ isValid: true })
    else setData({ isValid: false })
  }, [email])

  const sendVerificationCode = async (event) => {
    if (event) event.preventDefault()
    if (loading) return

    try {
      setData({ loading: true, error: null })
      await Auth.forgotPassword(email)
      setData({ loading: false })
      handleStep(1)
    } catch (err) {
      errorHandler.report(err)
      dispatch(
        showAlert({
          message: err.message || 'Email not found',
          type: ALERT_TYPES.ERROR,
          isVisible: true
        })
      )
      setData({ loading: false })
    }
  }

  return (
    <form onSubmit={sendVerificationCode} className={classes.initialFormContainer}>
      <Grid container md={5} lg={3} xs={10} sm={10}>
        <GridWRapper>
          <TextInput
            name="email"
            email={email}
            placeholder="Email Address"
            onChange={handleChange}
            className={classes.input}
          />
        </GridWRapper>
        <div className={classes.buttonsContainer}>
          <GridWRapper>
            <Button
              disabled={!isValid}
              title="Continue"
              color="primary"
              hover
              loading={loading}
              style={{ width: '70%', height: 36 }}
              onClick={sendVerificationCode}
              type="submit"
            />
          </GridWRapper>
        </div>
      </Grid>
    </form>
  )
}
