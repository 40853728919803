import React from 'react'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'

import { TextSkeleton, SocialIconSkeleton } from './bannerSkeleton'
import { GridContainer, SocialIcons } from '../../../components'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { hexToRGBA } from '../../../lib/utils'

export const MintrioBanner = ({ onClick, loading }) => {
  const {
    title: {
      text: titleText,
      font_size: titleFontSize,
      color: titleColor,
      background_color: titleBackgroundColor,
      background_opacity: titleBackgroundOpacity,
      background_blurriness: titleBackgroundBlurriness
    },
    sub_title: {
      text: subTitleText,
      font_size: subTitleFontSize,
      color: subTitleColor,
      background_color: subTitleBackgroundColor,
      background_blurriness: subTitleBackgroundBlurriness,
      background_opacity: subTitleBackgroundOpacity
    },
    banner: {
      text: bannerText,
      font_size: bannerFontSize,
      color: bannerColor,
      background_color: bannerBackgroundColor,
      background_blurriness: bannerBackgroundBlurriness,
      background_opacity: bannerBackgroundOpacity
    }
  } = useSelector((state) => state.landingPage)

  const classes = useStyles({
    titleFontSize,
    titleColor,
    subTitleFontSize,
    subTitleColor,
    bannerFontSize,
    bannerColor,
    bannerBackgroundBlurriness,
    bannerBackgroundColor: Boolean(bannerBackgroundColor)
      ? hexToRGBA(bannerBackgroundColor, bannerBackgroundOpacity)
      : 'transparent',
    titleBackgroundBlurriness,
    titleBackgroundColor: Boolean(titleBackgroundColor)
      ? hexToRGBA(titleBackgroundColor, titleBackgroundOpacity)
      : 'transparent',
    subTitleBackgroundBlurriness,
    subTitleBackgroundColor: Boolean(subTitleBackgroundColor)
      ? hexToRGBA(subTitleBackgroundColor, subTitleBackgroundOpacity)
      : 'transparent'
  })

  return (
    <React.Fragment>
      <GridContainer>
        <Grid
          container
          item
          direction="column"
          xs={12}
          style={{ margin: '0 auto', height: '90vh' }}>
          {loading ? (
            <TextSkeleton />
          ) : (
            <Grid
              item
              xs={12}
              className={classes.container}
              alignItems="center"
              justify="center"
              direction="column">
              {Boolean(titleText.trim()) && (
                <Typography color="secondary" className={classes.h1}>
                  {titleText}
                </Typography>
              )}
              {Boolean(subTitleText.trim()) && (
                <Typography color="secondary" className={classes.h2}>
                  {subTitleText}
                </Typography>
              )}
              {Boolean(bannerText.trim()) && (
                <Typography className={classes.contentLibraryText}>{bannerText}</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </GridContainer>
      <MoveToNextBanner onClick={onClick} />
      {loading ? <SocialIconSkeleton /> : <SocialIcons />}
    </React.Fragment>
  )
}

const MoveToNextBanner = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.downArrowContainer} onClick={onClick}>
      <KeyboardArrowDown style={{ fontSize: '5em' }} />
    </div>
  )
}
