import { API } from 'aws-amplify'
import { getToken } from '../redux/modules/user'

export const updateUserActivity = async (logs, dispatch) => {
  const authToken = await dispatch(getToken())

  const params = {
    body: {
      logs
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('activityLogs', '/add-activity-logs', params)
}
