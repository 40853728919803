import React, { useEffect, useState } from 'react'
import { Modal, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { usePromoCodeModalStyles } from './styles'
import { TextInput } from '../../../../../components/TextInput'
import { Button } from '../../../../../components/Button'
import { verifyPromoCode } from '../../../../../api/checkout'
import errorHandler from '../../../../../lib/errorHandler'
import { useQuery } from '../../../../../lib/utils'

export const PromoCodeModal = ({ isVisible, close, videoId, addCoupon }) => {
  const classes = usePromoCodeModalStyles()
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const accessToken = useQuery().get('access-token')

  const { userId: user_id } = useSelector((state) => state.user)
  const { countryCode: country_code } = useSelector((state) => state.location)
  const { plan } = useSelector((state) => state.checkout)
  const dispatch = useDispatch()

  useEffect(() => {
    setCode('')
    setError(null)
    setLoading(false)
  }, [isVisible, setCode, setError, setLoading])

  const handleInput = (event) => {
    const { value } = event.target

    setCode(value)
    setError(null)
  }

  const verifyCode = async () => {
    if (code.trim() === '') {
      setError('Please enter promo code')
      return
    }
    try {
      setError(null)
      setLoading(true)
      const payload = {
        code,
        video_id: videoId,
        user_id,
        plan_id: plan.planId,
        country_code
      }
      const { isValid, promoCode } = await verifyPromoCode(accessToken, payload, dispatch)
      addCoupon(isValid, promoCode)
    } catch (err) {
      errorHandler.report(err)
      setError(err.message || 'Something went wrong please try again')
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal
        open={isVisible}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalMainContainer}>
        <div className={classes.couponContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>ENTER YOUR PROMO CODE</Typography>
          </div>
          <div>
            <div className={classes.textFieldContainer}>
              <TextInput
                name="code"
                value={code}
                placeholder="Promo Code"
                className={classes.input}
                onChange={handleInput}
              />
              {error && <Typography className={classes.errorMessage}>{error}</Typography>}
            </div>
            <Button
              loading={loading}
              onClick={verifyCode}
              title={'APPLY'}
              className={classes.applyButton}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
