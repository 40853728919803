import { Auth } from 'aws-amplify'
import { USER_ACTIVITY_ACTIONS, EVENT_CATEGORIES } from '../../lib/constant'

import { addNewLog } from './userActivityLogs'
// constants
const LOGIN = 'LOGIN'
const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA'
const RESET_REGISTRATION = 'RESET_REGISTRATION'
const RESET_PASSWORD = 'RESET_PASSWORD'
const SET_USER_DATA = 'SET_USER_DATA'
const SET_MOBILE_CHECKOUT_DATA = 'SET_MOBILE_CHECKOUT_DATA'

// actions
export const setMobileCheckoutData = (payload) => ({
  type: SET_MOBILE_CHECKOUT_DATA,
  payload
})

export const login = (payload) => ({
  type: LOGIN,
  payload
})

export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload
})

export const setRegistrationData = (payload) => ({
  type: SET_REGISTRATION_DATA,
  payload
})

export const resetRegistration = () => ({
  type: RESET_REGISTRATION,
  payload: {
    registration: {
      email: '',
      firstName: '',
      lastName: '',
      gender: 'Gender',
      dob: ''
    }
  }
})

export const resetPassword = () => ({
  type: RESET_PASSWORD,
  payload: { password: '' }
})

// thunks
export const postLoginUser =
  (email, password, newUser = false) =>
  async (dispatch, getState) => {
    try {
      const response = await Auth.signIn(email.trim(), password)
      const {
        attributes: { name, given_name, sub, email: emailAddress, birthdate, gender }
      } = response

      let user = {
        userId: sub,
        email: emailAddress,
        dob: birthdate,
        gender
      }
      if (name && given_name) {
        user['name'] = `${name} ${given_name}`
      }

      window.gtag('set', { user_id: sub })
      window.gtag('set', 'user_properties', {
        guid: sub
      })

      dispatch(login(user))
      // reset login videos
      dispatch({
        type: 'RESET_VIDEOS',
        payload: {
          videos: [],
          nextToken: 'start',
          loading: false
        }
      })
      dispatch(resetPassword())
    } catch (error) {
      throw error
    }
  }

export const postLogoutUser = () => async (dispatch, getState) => {
  try {
    const {
      user: { email }
    } = getState()

    window.gtag('event', 'sign_out', {
      event_label: 'Email Address',
      event_category: EVENT_CATEGORIES.SIGN_IN,
      event_value: email
    })

    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.SIGN_OUT, { username: email }))
    await logoutUser(dispatch)
  } catch (error) {
    throw error
  }
}

const logoutUser = async (dispatch) => {
  dispatch({ type: 'RESET_APP' })
  window.localStorage.clear()
  await Auth.signOut()
}

export const registerNewUser = () => async (dispatch, getState) => {
  try {
    const {
      user: {
        registration: { email, firstName, lastName, gender, dob, password }
      }
    } = getState()

    let attributes = {
      birthdate: dob,
      gender: gender,
      email: email,
      name: firstName,
      given_name: lastName
    }

    await Auth.signUp({
      username: email.trim(),
      password,
      attributes
    })

    dispatch(resetRegistration())
  } catch (error) {
    throw error
  }
}

const refreshUserToken = (refreshToken, dispatch) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((res) => {
      res.refreshSession(refreshToken, async (err, data) => {
        if (err) {
          await logoutUser(dispatch)
          return reject(err)
        } else {
          return resolve(data.getIdToken().getJwtToken())
        }
      })
    })
  })
}

export const getToken = () => async (dispatch, getState) => {
  try {
    const session = await Auth.currentSession()
    const idTokenExpire = session.getIdToken().getExpiration()

    var currentTimeSeconds = Math.round(+new Date() / 1000)
    if (idTokenExpire < currentTimeSeconds) {
      var refreshToken = session.getRefreshToken()
      return await refreshUserToken(refreshToken, dispatch)
    } else {
      return session.getIdToken().getJwtToken()
    }
  } catch (err) {
    logoutUser(dispatch)
    throw err
  }
}

// reducer
const initialState = {
  login: false,
  userId: null,
  email: null,
  name: '',
  gender: '',
  dob: '',
  registration: {
    email: '',
    firstName: '',
    lastName: '',
    gender: 'Gender',
    dob: '',
    password: ''
  },
  mobileCheckoutData: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case LOGIN:
      return {
        ...state,
        ...payload,
        login: true
      }
    case SET_REGISTRATION_DATA:
      return {
        ...state,
        registration: { ...state.registration, ...payload }
      }
    case RESET_REGISTRATION:
      return {
        ...state,
        registration: { ...state.registration, ...payload.registration }
      }
    case RESET_PASSWORD:
      return {
        ...state,
        registration: { ...state.registration, password: payload.password }
      }
    case SET_USER_DATA:
      return { ...state, ...payload }
    case SET_MOBILE_CHECKOUT_DATA:
      return { ...state, mobileCheckoutData: payload }

    default:
      return state
  }
}

export default reducer
