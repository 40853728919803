import { makeStyles } from '@material-ui/styles'
import { isFirefox } from 'react-device-detect'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    outline: 'none',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.5)',
    backdropFilter: 'blur(10px)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loadingText: {
    padding: 20,
    color: 'white'
  },
  spinner: {
    color: 'white'
  }
}))
