import moment from 'moment'

import { orderDetail } from '../../api/order'
import { updateRatings } from '../../api/ratings'
import errorHandler from '../../lib/errorHandler'

// constants
const SET_ORDERS = 'SET_ORDERS'
const ADD_ORDER = 'ADD_ORDER'
const UPDATE_ORDER = 'UPDATE_ORDER'
const EXPIRE_ORDER = 'EXPIRE_ORDER'
const SET_LOADING = 'SET_LOADING'
const UPDATE_STREAMING_PARAMETERS = 'UPDATE_STREAMING_PARAMETERS'
const UPDATE_USER_RATING = 'UPDATE_USER_RATING'
const START_MOVIE_FIRST_TIME = 'START_MOVIE_FIRST_TIME'

// actions
export const setOrders = (payload) => ({
  type: SET_ORDERS,
  payload
})

export const addOrder = (payload) => ({
  type: ADD_ORDER,
  payload
})

export const updateOrder = (payload) => ({
  type: UPDATE_ORDER,
  payload
})

export const expireOrder = (videoId) => ({
  type: EXPIRE_ORDER,
  payload: { videoId }
})

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: { loading }
})

export const rateVideo = (payload) => ({
  type: UPDATE_USER_RATING,
  payload
})

export const fetchOrders = () => async (dispatch, getState) => {
  const {
    user: { userId },
    location: { countryCode }
  } = getState()

  try {
    const orders = await orderDetail(userId, null, countryCode, dispatch)
    dispatch(setLoading(false))
    dispatch(setOrders({ orders }))
  } catch (err) {
    throw err
  }
}

export const getOrder = (videoId, video) => async (dispatch, getState) => {
  const {
    myContent: { orders },
    user: { userId },
    location: { countryCode }
  } = getState()
  const filterdOrders = orders.filter((order) => order.video && order.video.id === videoId)
  if (filterdOrders.length === 0) {
    try {
      const order = await orderDetail(userId, videoId, countryCode, dispatch)
      dispatch(addOrder({ order: { ...order, video_id: videoId, video: order.video || video } }))
    } catch (err) {
      throw err
    }
  }
}

export const fetchAndUpdateOrder = (videoId) => async (dispatch, getState) => {
  const {
    user: { userId },
    location: { countryCode }
  } = getState()
  try {
    const order = await orderDetail(userId, videoId, countryCode, dispatch)
    dispatch(updateOrder({ order }))
  } catch (err) {
    throw err
  }
}

export const updateUserRating = (is_liked, videoId) => async (dispatch, getState) => {
  const {
    myContent: { orders },
    user: { userId: user_id }
  } = getState()

  const filteredOrders = orders.filter((order) => order.video_id === videoId)
  if (filteredOrders.length > 0) {
    const payload = {
      user_id,
      video_id: videoId,
      is_liked,
      id: filteredOrders[0].userRating ? filteredOrders[0].userRating.id : null
    }

    dispatch(rateVideo(payload))

    try {
      const response = await updateRatings(payload, dispatch)
      dispatch(rateVideo(response))
    } catch (err) {
      errorHandler.report(err)
    }
  }
}

export const startMovieFirstTime = (videoId) => (dispatch, getState) => {
  const payload = {
    started: true,
    last_timestamp: 0.1,
    minutes_streamed: 0.1,
    video_id: videoId
  }

  dispatch({
    type: START_MOVIE_FIRST_TIME,
    payload
  })
}

// reducer
const initialState = {
  orders: [],
  loading: true
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: payload.orders
      }
    case ADD_ORDER:
      return {
        ...state,
        orders: [...state.orders, payload.order]
      }
    case UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (payload.order.video && order.video_id === payload.order.video.id) return payload.order
          else return order
        })
      }

    case EXPIRE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.video_id === payload.videoId)
            return {
              ...order,
              purchased: true,
              expireAt: moment().format(),
              expired: true,
              started: false,
              last_timestamp: null,
              minutes_streamed: null,
              video_plan_id: null
            }
          else return order
        })
      }

    case SET_LOADING:
      return {
        ...state,
        loading: payload.loading
      }
    case UPDATE_STREAMING_PARAMETERS:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.video_id === payload.videoId)
            return {
              ...order,
              last_timestamp: payload.last_timestamp,
              minutes_streamed: payload.minutes_streamed
            }

          return order
        })
      }

    case UPDATE_USER_RATING:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.video_id === payload.video_id)
            return {
              ...order,
              userRating: payload
            }

          return order
        })
      }
    case START_MOVIE_FIRST_TIME:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.video_id === payload.video_id)
            return {
              ...order,
              ...payload
            }

          return order
        })
      }

    default:
      return state
  }
}

export default reducer
