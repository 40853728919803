//constants
const PLAY_VIDEO = 'PLAY_VIDEO'
const RESET_VIDEO = 'RESET_VIDEO'

export const playVideo = (payload) => ({
  type: PLAY_VIDEO,
  payload
})

export const closeVideoPlayer = () => ({
  type: RESET_VIDEO
})

//reducer
const initialState = {
  isPlayerModalVisible: false,
  video: null,
  orderId: null
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case PLAY_VIDEO:
      return {
        ...payload
      }
    case RESET_VIDEO:
      return {
        isPlayerModalVisible: false,
        video: null,
        orderId: null
      }

    default:
      return state
  }
}

export default reducer
