import { SOCIAL_ICONS } from '../../lib/constant'
import { useSelector } from 'react-redux'
import { hexToRGBA } from '../../lib/utils'
import { useStyles } from './styles'

export const SocialIcons = ({ position = 'absolute', footer = false }) => {
  const {
    social_icons: {
      bottom_offset: socialIconsBottomOffset,
      color: socialIconsColor,
      background_color: socialIconsBackgroundColor,
      background_opacity: socialIconsBackgroundOpacity,
      background_blurriness: socialIconsBackgroundBlurriness,
      disabled
    }
  } = useSelector((state) => state.landingPage)

  const classes = useStyles({
    socialIconsBottomOffset,
    socialIconsColor,
    socialIconsBackgroundColor: Boolean(socialIconsBackgroundColor)
      ? hexToRGBA(socialIconsBackgroundColor, socialIconsBackgroundOpacity)
      : 'transparent',
    socialIconsBackgroundBlurriness,
    position
  })
  if (disabled && !footer) return null

  return (
    <div className={classes.mainContainer}>
      <div className={classes.iconsContainer}>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        {SOCIAL_ICONS.map((item) => {
          return (
            <div className={classes.icon}>
              <a
                key={item.id}
                href={item.navigateTo}
                target="_blank"
                className={item.icon}
                rel="noreferrer"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
