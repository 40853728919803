import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import { WATCHING_WAYS } from '../../../lib/constant'
import { watchDevices } from '../../../assets'
import { Container } from './container'

export const WatchingWays = () => {
  const classes = useStyles()
  return (
    <Container title={'Watch the way you want'}>
      <div className={classes.mainContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.detailContainer}>
            {WATCHING_WAYS.map(({ id, title, detail }) => {
              return (
                <div id={id} className={classes.detailItem}>
                  <div style={{ width: 220, padding: 10 }}>
                    <Typography className={classes.title}>{title}</Typography>
                    <div className={classes.content}>
                      <Typography className={classes.contentText}>{detail}</Typography>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className={classes.imageContainer}>
          <img src={watchDevices} className={classes.image} />
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  contentText: {
    color: 'lightgray',
    fontSize: 12,
    fontWeight: '300'
  },
  detailContainer: {
    marginRight: '50%',
    marginLeft: '10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginRight: '5%',
      marginLeft: '5%'
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginLeft: '0px'
    }
  },
  detailItem: {
    display: 'flex',
    flex: 1,
    margin: '10px 0px',
    background: 'linear-gradient(90deg, #35344F 0%, rgba(53, 52, 79, 0) 100%)',
    borderRadius: '5px'
  },
  title: {
    color: 'white',
    fontSize: 11,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  dot: {
    color: 'white',
    fontSize: 6
  },
  imageContainer: {
    position: 'absolute',
    right: 0,
    left: '30%',
    bottom: 0,
    top: 0,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      left: 0
    }
  },
  image: {
    width: '100%'
  },
  mainContainer: {
    display: 'flex',
    position: 'relative',
    height: 300,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: '100%',
      flex: 1
    }
  },
  flex: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    }
  }
}))
