import React, { useState, useRef, useEffect } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { Popover, Typography } from '@material-ui/core'

import { setPlan } from '../../../../../../../redux/modules/checkout'
import { useStyles } from './styles'
import { addNewLog } from '../../../../../../../redux/modules/userActivityLogs'
import { USER_ACTIVITY_ACTIONS } from '../../../../../../../lib/constant'

export const PlanSelector = ({ plans, videoId }) => {
  const { plan, promoCode } = useSelector((state) => state.checkout)
  const classes = useStyles()

  const { id, currency, price, planName } = plan

  const [anchorEl, setAnchorEl] = useState(null)

  const planElement = useRef(null)

  const [popoverWidth, setPopOverWidth] = useState(window.innerWidth * 0.33)

  const dispatch = useDispatch()

  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (planElement) setPopOverWidth(planElement.current.clientWidth)
  }, [anchorEl])

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handlePlanSelection = (item) => {
    setAnchorEl(null)

    dispatch(setPlan(item))
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.SELECT_PLAN, {
        video_id: videoId,
        country: item.country,
        plan_id: item.planId,
        plan_name: item.planName,
        price: item.price,
        currency: item.currency,
        promocode: promoCode
      })
    )
  }
  const renderDropDown = () => {
    return (
      <Popover
        className={classes.popoverContainer}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.popoverPlansListContainer} style={{ width: popoverWidth }}>
          {plans.map((item) => {
            return (
              <div
                style={{
                  paddingTop: item.id === id ? 0 : 2,
                  paddingBottom: item.id === '0' ? 0 : 2
                }}>
                <div
                  role="presentation"
                  onClick={() => handlePlanSelection(item)}
                  className={item.id === id ? classes.selectedDropDownMenu : classes.dropDownMenu}>
                  <Typography
                    className={
                      item.id === id
                        ? clsx(classes.planPriceContainer, classes.selected)
                        : classes.planPriceContainer
                    }>
                    {item.currency}
                    <span
                      className={
                        promoCode ? clsx(classes.price, classes.withPromoCode) : classes.price
                      }>
                      {item.price}
                    </span>
                    {promoCode && (
                      <span className={classes.price}>
                        {item.price - item.price * (promoCode.percentage_off / 100)}
                      </span>
                    )}
                  </Typography>
                  <Typography
                    className={
                      item.id === id
                        ? clsx(classes.planTitleContainer, classes.selected)
                        : classes.planTitleContainer
                    }>
                    {item.planName}
                  </Typography>
                  {item.id === id ? (
                    <div className={classes.selectedIconContainer}>
                      <CheckCircleOutlineIcon className={classes.selectedIcon} />
                    </div>
                  ) : (
                    <div className={classes.selectedIconContainer} />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Popover>
    )
  }
  return (
    <div className={classes.planSelectorMain}>
      <div ref={planElement} onClick={handleClick} className={classes.planItemContainer}>
        <Typography className={classes.priceContainer}>
          {currency}
          <span className={promoCode ? clsx(classes.price, classes.withPromoCode) : classes.price}>
            {price}
          </span>
          {promoCode && (
            <span className={classes.price}>
              {price - price * (promoCode.percentage_off / 100)}
            </span>
          )}
        </Typography>
        <Typography className={classes.planName}>{planName}</Typography>
        <Typography className={classes.planName}>Change</Typography>
      </div>

      {renderDropDown()}
    </div>
  )
}
