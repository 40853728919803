import { makeStyles } from '@material-ui/core'
import { color } from '../../../styles/color'

export const useStyles = makeStyles(() => ({
  mainContainer: {
    outline: 'none',
    flex: 1,
    height: '100vh',
    position: 'relative'
  },
  modalContainer: {
    backgroundColor: 'black'
  },
  loaderContainer: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    color: color.green
  },
  loadingText: {
    color: 'white',
    paddingLeft: 20
  }
}))
