import React, { useEffect, useState } from 'react'

import { ContentList, ContentSkeleton, Tabs } from './components'
import { useStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from '../../redux/modules/myContent'
import { ALERT_TYPES, USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import { showAlert } from '../../redux/modules/alertHandler'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import errorHandler from '../../lib/errorHandler'
import { HelmetMetaData } from '../../components'

export function MyContent() {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { loading } = useSelector((state) => state.myContent)
  const { countryCode } = useSelector((state) => state.location)

  const dispatch = useDispatch()

  useEffect(() => {
    if (countryCode) {
      ;(async () => {
        try {
          await dispatch(fetchOrders())
        } catch (err) {
          errorHandler.report(err)
          dispatch(
            showAlert({
              type: ALERT_TYPES.ERROR,
              message: 'Unable to fetch content. Please try again',
              isVisible: true
            })
          )
        }
      })()
    }
  }, [countryCode, dispatch])

  useEffect(() => {
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: 'my content', path: '/my-content' })
    )
  }, [dispatch])

  return (
    <>
      <HelmetMetaData title={'Mintrio'} description={'My Content'} hashtag={'#content'} />
      <div className={classes.mainContainer}>
        <div className={classes.topContainer}>
          <div className={classes.movieBannerContainer}>
            <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
            {loading ? <ContentSkeleton /> : <ContentList selectedTab={selectedTab} />}
          </div>
        </div>

        <div className={classes.couchImageContainer} />
      </div>
    </>
  )
}
