import React from 'react'
import { IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { SHARE_OPTIONS, USER_ACTIVITY_ACTIONS, EVENT_CATEGORIES } from '../../../../../lib/constant'
import { addNewLog } from '../../../../../redux/modules/userActivityLogs'
import errorHandler from '../../../../../lib/errorHandler'
import { useStyles } from './styles'

export const Footer = ({ title, description }) => {
  const classes = useStyles()
  const url = window.location.origin

  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const dispatch = useDispatch()

  const objectToGetParams = (data) => {
    let response = ``
    const keys = Object.keys(data)
    keys.map((item, index) => {
      response = `${response}${item}=${data[item]}${index === keys.length - 1 ? '' : '&'}`
    })
    return response
  }

  const handlePress = (id) => {
    if (id === 'fa fa-link') copyToClipboard()
    else if (id === 'fab fa-twitter') shareToTwitter()
    else if (id === 'fab fa-facebook-f') shareToFacebook()
    else if (id === 'fab fa-linkedin-in') shareToLonkedin()
    else if (id === 'far fa-envelope') shareToEmail()
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(description)

      dispatch(addNewLog(USER_ACTIVITY_ACTIONS.INVITE_LINK, { method: 'Copy To Clipboard' }))

      window.gtag('event', 'invite_link', {
        event_label: 'URL',
        method: 'Copy To Clipboard',
        event_category: EVENT_CATEGORIES.PREVIEW,
        event_value: url,
        device_type_id: deviceTypeId,
        ip_address: locationData.query
      })
    } catch (err) {
      errorHandler.report(err)
    }
  }

  const shareToTwitter = () => {
    const link =
      `https://twitter.com/share?` +
      objectToGetParams({
        url: url,
        text: description,
        hashtags: `mintrio`
      })
    window.open(link, '_blank')

    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.INVITE_LINK, { method: 'Twitter' }))

    window.gtag('event', 'invite_link', {
      event_label: 'URL',
      method: 'Twitter',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: url,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToFacebook = () => {
    const link =
      `https://www.facebook.com/sharer/sharer.php?` +
      objectToGetParams({
        u: url,
        hashtag: `mintrio`,
        caption: 'Mintrio'
      })
    window.open(link, '_blank')

    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.INVITE_LINK, { method: 'Facebook' }))

    window.gtag('event', 'invite_link', {
      event_label: 'URL',
      method: 'Facebook',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: url,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToLonkedin = () => {
    const link =
      `https://linkedin.com/shareArticle?` +
      objectToGetParams({
        url: url,
        mini: 'true',
        title: title,
        summary: description,
        source: url
      })
    window.open(link, '_blank')

    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.INVITE_LINK, { method: 'LinkedIn' }))

    window.gtag('event', 'invite_link', {
      event_label: 'URL',
      method: 'LinkedIn',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: url,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  const shareToEmail = () => {
    const body = `${description}%0D%0D${url}`
    const link = `mailto:?&subject=${title}&body=${body}`
    window.open(link, '_blank')

    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.INVITE_LINK, { method: 'Email' }))

    window.gtag('event', 'invite_link', {
      event_label: 'URL',
      method: 'Email',
      event_category: EVENT_CATEGORIES.PREVIEW,
      event_value: url,
      device_type_id: deviceTypeId,
      ip_address: locationData.query
    })
  }

  return (
    <div className={classes.shareIconsContainer}>
      {SHARE_OPTIONS.map(({ id, color }) => (
        <IconButton key={id} onClick={() => handlePress(id)} className={classes.shareButton}>
          <i className={id} style={{ color, fontSize: 15 }}></i>
        </IconButton>
      ))}
    </div>
  )
}
