import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import moment from 'moment'

import { useStyles } from './styles'
import { ALERT_TYPES, USER_ACTIVITY_ACTIONS } from '../../../../../../lib/constant'
import { PaymentIcon } from '../../../paymentIcon'
import { addNewLog } from '../../../../../../redux/modules/userActivityLogs'
import errorHandler from '../../../../../../lib/errorHandler'
import { createHash as createJazzCashHash } from '../../../../../../api/checkout'
import { useQuery } from '../../../../../../lib/utils'
import { setFullScreenSpinner } from '../../../../../../redux/modules/globalModals'
import { showAlert } from '../../../../../../redux/modules/alertHandler'

export const JazzCashForm = ({ paymentMethod }) => {
  const accessToken = useQuery().get('access-token')

  const classes = useStyles()
  const {
    videoId,
    plan: { price, videoPlanId: video_plan_id },
    promoCode
  } = useSelector((state) => state.checkout)
  const { userId: user_id } = useSelector((state) => state.user)
  const { countryCode: country_code } = useSelector((state) => state.location)
  const { name, id: payment_gateway_id } = paymentMethod
  const dispatch = useDispatch()

  const setLoader = (loading, loadingText) => {
    dispatch(
      setFullScreenSpinner({
        loading,
        loadingText
      })
    )
  }

  const submitForm = async () => {
    setLoader(true, 'Redirecting to JazzCash checkout page...')
    try {
      const timestamp = moment().format('yyyyMMDDHHmmss')
      const expiryDateTime = moment().add(1, 'hours').format('yyyyMMDDHHmmss')

      let input = {
        payment_gateway_id,
        user_id,
        video_plan_id,
        video_id: videoId,
        country_code,
        timestamp,
        expiryDateTime
      }
      if (promoCode) {
        input['promo_code_id'] = promoCode.id
      }
      const { hash, id } = await createJazzCashHash(accessToken, input, dispatch)
      document.getElementsByName(
        'pp_ReturnURL'
      )[0].value = `${process.env.REACT_APP_JAZZ_CASH_RETURN_URL}?checkoutMiddlewareId=${id}`
      document.getElementsByName('pp_SecureHash')[0].value = hash + ''
      document.getElementsByName('pp_TxnExpiryDateTime')[0].value = expiryDateTime
      document.getElementsByName('pp_TxnDateTime')[0].value = timestamp
      document.getElementsByName('pp_TxnRefNo')[0].value = `T${timestamp}`

      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.CHECKOUT, {
          video_id: videoId,
          country: country_code,
          price: price,
          promo_code_id: promoCode ? promoCode.id : null,
          currency: 'PKR',
          plan_id: video_plan_id,
          payment_gateway: name
        })
      )
      document.jsform.submit()
    } catch (error) {
      setLoader(false, '')
      errorHandler.report(error)
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message:
            error?.response?.data?.message || 'Unable to process your request. Please try again',
          isVisible: true
        })
      )
    }
  }

  const amount = promoCode ? price - price * (promoCode.percentage_off / 100) : price
  return (
    <>
      <form name="jsform" method="post" action={process.env.REACT_APP_JAZZ_CASH_REDIRECT_URL}>
        <input type="hidden" name="pp_Version" value="1.1" />
        <input type="hidden" name="pp_Language" value="EN" />
        <input
          type="hidden"
          name="pp_MerchantID"
          value={process.env.REACT_APP_JAZZ_CASH_MERCHANT_ID}
        />
        <input type="hidden" name="pp_SubMerchantID" value="" />
        <input type="hidden" name="pp_Password" value={process.env.REACT_APP_JAZZ_CASH_PASSWORD} />
        <input type="hidden" name="pp_TxnRefNo" id="pp_TxnRefNo" value={``} />
        <input type="hidden" name="pp_Amount" value={`${amount * 100}`} />
        <input type="hidden" name="pp_TxnCurrency" value="PKR" />
        <input type="hidden" name="pp_TxnDateTime" value={''} />
        <input type="hidden" name="pp_BillReference" value={'OrderID'} />
        <input type="hidden" name="pp_Description" value={'Mintrio Video Rental'} />
        <input type="hidden" name="pp_TxnExpiryDateTime" value={''} />
        <input type="hidden" name="pp_ReturnURL" value={''} />
        <input type="hidden" name="pp_SecureHash" value={''} />
        <input type="hidden" name="pp_TxnType" value={''} />
        <input type="hidden" name="ppmpf_1" value={'1'} />
        <input type="hidden" name="ppmpf_2" value={'2'} />
        <input type="hidden" name="ppmpf_3" value={'3'} />
        <input type="hidden" name="ppmpf_4" value={'4'} />
        <input type="hidden" name="ppmpf_5" value={'5'} />
      </form>
      <div role="presentation" onClick={submitForm} className={classes.itemContainer}>
        <Typography className={classes.displayText}>Click to Pay</Typography>
        <div className={classes.imagesContainer}>
          <PaymentIcon name={name} />
        </div>
      </div>
    </>
  )
}
