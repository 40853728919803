import { makeStyles } from '@material-ui/core'

import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
    marginTop: '1em'
  },
  border: {
    borderBottom: `1px solid ${color.white}`,
    width: '100%'
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 15,
    color: color.white
  }
}))
