import { API } from 'aws-amplify'
import { getToken } from '../redux/modules/user'

export const updateVideoViewLog = async (id, log, dispatch) => {
  const authToken = await dispatch(getToken())

  const params = {
    body: {
      id,
      ...log
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  return await API.post('order', '/update-view-log', params)
}

export const updateQuarterlyFlags = async (orderId, purchasedAt, flags, dispatch) => {
  const authToken = await dispatch(getToken())

  const params = {
    body: {
      orderId,
      purchasedAt,
      ...flags
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }

  await API.post('order', '/update-flags', params)
}

export const updateTrailerViewLog = async (data) => {
  const params = {
    body: {
      ...data
    }
  }

  return await API.post('trailerViewLog', '/update-trailer-view-log', params)
}
