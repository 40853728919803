import { makeStyles } from '@material-ui/styles'

import { color } from '../../../styles/color'

export const movieDetailStyles = makeStyles((theme) => ({
  mainContainer: {
    background: 'rgba(39,38,69,0.8)',
    padding: '30px 20px 20px 20px',
    borderRadius: 10,
    width: '100%',
    position: 'relative'
  },
  paper: {
    marginLeft: 10
  },
  movieButtonsOutercontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  movieButtonsTagsContainer: {
    width: 330
  },

  linkIcon: {
    color: 'white',
    transform: 'rotate(-30deg)',
    height: 20,
    width: 20
  },
  socialIcon: {
    color: 'white',
    height: 20,
    width: 20
  },
  bannerMessageContainer: {
    position: 'absolute',
    top: 13,
    left: 20,
    right: 20,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 14
    }
  },
  banner: {
    border: `1px solid rgba(255, 255, 255, 0.4)`,
    color: color.white,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    backgroundColor: color.greypurple,
    borderRadius: 20,
    padding: '2px 10px 2px 10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: '2px 10px 2px 10px'
    }
  },
  mainTitle: {
    color: color.white,
    fontSize: '40px',
    fontWeight: 600,
    padding: '0px 30px',
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingTop: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  subTitle: {
    color: color.white,
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  captionAudio: {
    color: color.white,
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    textAlign: 'center',
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  languageSpan: {
    color: color.white,
    fontSize: '13px',
    fontWeight: 300
  },
  shortdisc: {
    color: color.lightgray,
    fontSize: '13px',
    fontWeight: '400',
    paddingBottom: '20px',
    fontFamily: 'Rubik',
    textAlign: 'left'
  },
  longDescription: {
    color: color.white,
    fontSize: '16px',
    fontWeight: '300',
    fontFamily: 'Rubik',
    textAlign: 'center',
    padding: '10px 6% 10px 6%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  playButton: {
    width: '100%',
    height: '40px',
    marginBottom: '12px',
    backgroundColor: 'rgba(40, 45, 219, 0.6);',
    color: color.white,
    fontWeight: '600',
    fontSize: 14,
    borderRadius: 3,
    border: '1px solid #3237E5',
    fontFamily: 'Rubik',
    '&:disabled': {
      backgroundColor: 'rgba(40, 45, 200, 0.3);',
      color: 'rgba(255, 255, 255, 0.3)',
      border: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(40, 45, 219, 0.6);'
    }
  },
  resumeButton: {
    width: '100%',
    height: '40px',
    backgroundColor: 'rgba(181, 4, 4, 0.6)',
    border: '1px solid #B50404',
    color: color.white,
    fontSize: 14,
    fontWeight: '600',
    borderRadius: 3,
    fontFamily: 'Rubik',
    '&:disabled': {
      backgroundColor: 'rgba(163, 3, 3, 0.2)',
      color: 'rgba(255, 255, 255, 0.3)',
      border: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(181, 4, 4, 0.6)'
    }
  },
  movieduration: {
    color: color.white,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Rubik'
  },
  moviegenre: {
    color: color.white,
    fontSize: '14px',
    fontWeight: 450,
    fontFamily: 'Rubik'
  },
  movierating: {
    color: color.white,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Rubik'
  },
  moviecat: {
    color: color.white,
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Rubik'
  },
  moviepercent: {
    color: color.green,
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Rubik',
    marginLeft: 3
  },
  resumebutton: {
    width: '170px',
    height: '30px',
    backgroundColor: color.red,
    color: color.white,
    fontWeight: '400',
    fontSize: '14px',
    borderRadius: 3,
    fontFamily: 'Rubik'
  },
  movieexpires: {
    color: color.white,
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Rubik'
  },
  movieexpiresdate: {
    color: color.red,
    fontSize: '12px',
    fontWeight: 450,
    fontFamily: 'Rubik',
    paddingLeft: '2px'
  },
  expiryDateContainer: {
    display: 'flex',
    paddingBottom: '3px',
    justifyContent: 'center'
  },
  footerGrid: {
    paddingBottom: '20px'
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'column'
  },

  bottomTabWrapper: {
    float: 'left',
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  qualityIcon: {
    color: color.yellowColor,
    height: 20,
    width: 20
  },
  ratingTag: {
    marginTop: 3,
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: 3,
    padding: '2px 9px 0px 9px',
    fontSize: 8,
    color: 'white'
  },
  durationRatingsIcon: {
    color: color.yellowColor,
    height: 17,
    width: 17,
    paddingTop: 3
  },
  durationRatingsText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 9
    }
  },
  progressBar: {
    marginTop: 10,
    borderRadius: 3,
    width: '100%'
  },
  expireAtTextContainer: {
    textAlign: 'left',
    padding: '5px 0px',
    color: 'white',
    fontSize: 13,
    fontWeight: '600'
  },
  expiryTime: {
    color: 'red'
  },
  detailFooterContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 25
  },
  deviceNotSupportedTagContainer: {
    height: 30,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceNotSupportedTag: {
    color: color.white,
    fontSize: '11px',
    fontWeight: 450,
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    backgroundColor: color.greypurple,
    borderRadius: 5,
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: 2
  },
  durationTag: {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 3,
    padding: '2px 8px',
    background: 'rgba(255, 255, 255, 0.2)'
  },
  otherTag: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 9
    }
  },
  mintrioTag: {
    height: 12,
    width: 12,
    [theme.breakpoints.down('xs')]: {
      height: 10,
      width: 10
    }
  },
  tagWrapper: {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 3,
    padding: '2px 8px',
    minHeight: 22,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }
}))

export const useGenreListStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  genreItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: 'lightgray',
    textTransform: 'uppercase',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13
    }
  },
  dotIcon: {
    color: 'lightgray',
    height: 7,
    width: 7,
    margin: '0px 10px'
  }
}))

export const useCastDetailModalStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: color.newdock,
    backdropFilter: 'blur(5px)',
    outline: 'none',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center'
  },
  container: {
    height: 400,
    width: 440,
    outline: 'none',
    borderRadius: 10,
    backgroundColor: color.modalColor,
    backdropFilter: 'blur(40px)',
    padding: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    }
  },
  name: {
    color: 'white',
    fontSize: 30,
    textTransform: 'uppercase',
    fontWeight: '600',
    paddingRight: 30
  },
  fullName: {
    color: 'lightgray',
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: '500',
    paddingRight: 30
  },
  imageDetailContainer: {
    display: 'flex',
    height: 330,
    padding: '20px 0px',
    alignItems: 'center',
    overflow: 'hidden'
  },
  imageContainer: {
    flex: 1,
    height: '100%',
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  image: {
    width: '100%',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      width: '150px'
    }
  },
  descriptionContainer: {
    flex: 1,
    height: '90%',
    padding: '0px 10px',
    overflow: 'auto',
    alignItems: 'center',
    marginBottom: 30
  },
  description: {
    fontSize: 13,
    color: 'lightgray'
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    height: 30,
    width: 30,
    borderRadius: 5,
    backgroundColor: 'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  closeIcon: {
    color: 'white'
  }
}))

export const usePreviewSkeletonStyles = makeStyles((theme) => ({
  skeletonContainer: {
    height: '100%',
    overflow: 'auto',
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
    [theme.breakpoints.down('xs')]: {
      width: 270
    }
  },
  skeleton: {
    marginBottom: 20,
    borderRadius: 10
  }
}))
