import React from 'react'
import { TabletAndroid, Smartphone, Laptop, DesktopMac, LiveTv } from '@material-ui/icons'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { color } from '../../../../../styles/color'

export const SupportedDevices = ({ devices, selected = true }) => {
  const devicesIcons = {
    desktop: DesktopMac,
    laptop: Laptop,
    tv: LiveTv,
    mobile: Smartphone,
    tablet: TabletAndroid
  }
  const classes = useStyles()

  devices = devices.sort((a, b) => a.key.localeCompare(b.key))
  return (
    <div className={classes.mainContainer}>
      {devices.map(({ key }) => {
        const Icon = devicesIcons[key]
        return (
          <span key={key} className={classes.deviceContainer}>
            <div>
              <Icon className={selected ? classes.icon : clsx(classes.icon, classes.grey)} />
            </div>
            <Typography
              className={selected ? classes.deviceTitle : clsx(classes.deviceTitle, classes.grey)}>
              {key === 'tv' ? 'TV' : key}
            </Typography>
          </span>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  deviceContainer: {
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 17,
    color: 'white'
  },
  deviceTitle: {
    fontSize: 10,
    color: 'white',
    fontWeight: 400,
    textTransform: 'capitalize'
  },
  grey: {
    color: color.grey
  }
}))
