import React from 'react'
import { Questions } from '.'

export const List = ({ questionsList }) => {
  return (
    <div>
      {questionsList.map((item) => {
        return <Questions data={item.Knowledge_base.items} title={item.title} />
      })}
    </div>
  )
}
