import React from 'react'

import { Container } from '.'
import { TrailerSongCard } from './trailerSongCard'

export const PreviewSongs = ({ video }) => {
  const {
    trailers: { items: trailersList },
    thumbnail_sm
  } = video
  const songs = trailersList.filter((item) => item.type === 'SONG')
  if (!songs.length) return null
  return (
    <Container
      scroll={true}
      title={'Songs'}
      cardId={'song-thumbnail'}
      listAlignment={songs.length > 1 ? 'flex-start' : 'center'}>
      {songs.map((song) => {
        return (
          <TrailerSongCard
            id="song-card"
            thumbnailId={'song-thumbnail'}
            videoThumbnail={thumbnail_sm}
            key={song.id}
            item={song}
            video={video}
          />
        )
      })}
    </Container>
  )
}
