import { makeStyles } from '@material-ui/core'
import { couch } from '../../assets'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: color.backgroundPurple,
    position: 'relative'
  },
  couchImageContainer: {
    backgroundImage: `url(${couch})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: '15%',
    height: 300,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      right: 0
    },
    [theme.breakpoints.down('xs')]: {
      right: 0,
      height: 100
    }
  },
  topContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column'
  },
  movieBannerContainer: {
    flex: 1,
    overflow: 'auto',
    paddingTop: 50
  },
  activeTabButton: {
    backgroundColor: 'blue',
    color: 'white',
    width: 120,
    borderRadius: 20,
    margin: 15,
    fontSize: 11,
    height: 30,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'blue',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      margin: 10,
      fontWeight: '500',
      width: 100
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 7,
      margin: 5,
      fontWeight: '600',
      width: 80
    }
  },
  inActiveTabButton: {
    backgroundColor: 'rgba(0,0,255,0.5)',
    color: 'lightgray',
    width: 120,
    borderRadius: 20,
    margin: 15,
    fontSize: 11,
    height: 30,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: 'blue',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      margin: 10,
      fontWeight: '500',
      width: 100
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 7,
      margin: 5,
      fontWeight: '500',
      width: 80
    }
  }
}))
