import React from 'react'
import { Button as MaterialButton, makeStyles, CircularProgress } from '@material-ui/core'

import { color } from '../styles/color'

export function Button({ title, style, hover, loading, className, ...otherProps }) {
  const classes = useStyles()

  return (
    <MaterialButton
      variant="contained"
      className={`${className ? className : classes.button} ${hover && classes.buttonHover}`}
      style={{ ...style }}
      {...otherProps}>
      {loading ? <CircularProgress size={24} className={classes.circularProgress} /> : title}
    </MaterialButton>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '0.8em',
    borderRadius: '5px',
    backgroundColor: color.buttonBlue,
    fontWeight: '600',
    fontSize: 14,
    '&:disabled': {
      backgroundColor: color.grey,
      color: color.white
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  buttonHover: {
    backgroundColor: color.buttonBlue,
    '&:hover': {
      backgroundColor: color.buttonBlue,
      color: color.white
    },
    fontWeight: '600',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  circularProgress: {
    color: color.white,
    padding: 3
  }
}))
