import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useStyles } from './styles'

export const TextSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <Grid
        item
        xs={12}
        md={4}
        className={classes.container}
        alignItems="center"
        justify="center"
        direction="column">
        <div className={classes.titleSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'80px'} />
          </SkeletonTheme>
        </div>

        <div className={classes.midTitle}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'50px'} />
          </SkeletonTheme>
        </div>

        <div className={classes.subTitleSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'30px'} />
          </SkeletonTheme>
        </div>
      </Grid>
    </div>
  )
}
