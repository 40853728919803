import { makeStyles } from '@material-ui/core'

import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  goToHome: {
    width: '200px',
    height: '40px',
    backgroundColor: color.buttonBlue,
    color: color.white,
    fontWeight: '400',
    fontSize: '14px',
    borderRadius: 6,
    fontFamily: 'Rubik'
  },
  pageNotFoundContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: color.backgroundPurple,
    flexDirection: 'column',
    padding: '10px'
  },
  pageNotFoundMainText: {
    fontSize: '40px',
    color: 'white',
    textAlign: 'center'
  },

  pageNotFoundSubText: {
    fontSize: '16px',
    color: 'white',
    textAlign: 'center',
    padding: '10px 0px 30px 0px'
  },

  pageNotFoundError: {
    color: 'white',
    fontSize: '100px'
  }
}))
