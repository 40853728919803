import React from 'react'
import { Modal, makeStyles, Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { color } from '../../../../../styles/color'

export const RentalTermsModal = ({ isVisible, closeModal, plan }) => {
  const classes = useStyles()
  const {
    country,
    currency,
    planName,
    expireAfterPurchase,
    price,
    nDaysDownloadToExpire,
    isDownloadable,
    videoTitle,
    expireAfterStart,
    nWatches
  } = plan

  return (
    <Modal
      open={isVisible && plan}
      onClose={closeModal}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <Typography className={classes.header}>Rental Terms</Typography>
          <IconButton size="small" onClick={closeModal} className={classes.close}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>

        <div className={classes.detailContainer}>
          <Typography className={classes.planDetailItem}>Plan Name: {planName}</Typography>
          <Typography className={classes.planDetailItem}>Video: {videoTitle}</Typography>
          <Typography className={classes.planDetailItem}>
            Price: {price} {currency}
          </Typography>
          <Typography className={classes.planDetailItem}>Country: {country}</Typography>

          <Typography className={classes.watchDetailItem}>
            Rental includes <span className={classes.bold}>{expireAfterPurchase}</span> days to
            start watching this video and <span className={classes.bold}>{expireAfterStart}</span>{' '}
            hours to finish once started. Watch the FULL video up to{' '}
            <span className={classes.bold}>{nWatches}</span> time{nWatches > 1 ? '(s)' : ''}. Please
            note that you are able to fast forward/rewind as often as you'd like.
          </Typography>
          {isDownloadable && (
            <Typography className={classes.watchDetailItem}>
              * Downloaded videos can be rewatched unlimted times over a{' '}
              <span className={classes.bold}>{nDaysDownloadToExpire}</span> day period <br />
              ** A successful download counts as 1 watch
            </Typography>
          )}
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    outline: 'none',
    background: color.modalColor,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    width: 330,
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      width: 290
    }
  },
  headerContainer: {
    display: 'flex'
  },
  header: {
    flex: 1,
    color: color.white,
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  close: {
    borderRadius: 3,
    height: 20,
    width: 20,
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(13px)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(13px)'
    }
  },
  closeIcon: {
    color: color.white,
    fontSize: 16
  },
  detailContainer: {
    padding: '10px 0px'
  },
  planDetailItem: {
    color: color.grey,
    fontSize: 15,
    fontWeight: '500'
  },
  watchDetailItem: {
    color: color.grey,
    fontSize: 14,
    fontWeight: '500',
    paddingTop: 20
  },
  bold: {
    fontWeight: 'bold',
    color: color.lightsilver
  }
}))
