import React, { useEffect, useState } from 'react'
import { Typography, IconButton, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { ContentViewBox } from '.'
import { MY_CONTENT_TABS } from '../../../lib/constant'
import { useContentListStyles } from './styles'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import moment from 'moment'

export const ContentList = ({ selectedTab }) => {
  const { orders } = useSelector((state) => state.myContent)
  const [activeOrders, setActiveOrders] = useState([])
  const [expiredOrders, setExpiredOrders] = useState([])

  useEffect(() => {
    let active = []
    let expired = []
    orders.forEach((order) => {
      if (order.expired) expired.push(order)
      else active.push(order)
    })
    active = active.sort((first, second) => {
      if (first.released && !second.expired) {
        if (moment(second.expireAt).isBefore(first.expireAt)) return 1
        return -1
      } else if (!first.released) return -1
      return -1
    })
    setActiveOrders(active)
    setExpiredOrders(expired)
  }, [orders, setActiveOrders, setExpiredOrders, selectedTab])

  return (
    <div>
      {selectedTab === 0 && <List orders={activeOrders} selectedTab={selectedTab} />}
      {selectedTab === 1 && <List orders={expiredOrders} selectedTab={selectedTab} />}
      {selectedTab === 2 && (
        <List orders={[...activeOrders, ...expiredOrders]} selectedTab={selectedTab} />
      )}
    </div>
  )
}

const List = ({ orders, selectedTab }) => {
  const classes = useContentListStyles()

  if (orders.length === 0)
    return (
      <div className={classes.emptyListContainer}>
        <IconButton className={classes.iconButton}>
          <LiveTvIcon className={classes.tvIcon} />
        </IconButton>
        <Typography className={classes.emptyText}>
          {`${MY_CONTENT_TABS[selectedTab].name} content will show up here.`}
        </Typography>
      </div>
    )
  else
    return (
      <Grid container>
        <Grid item lg={1} md={1} sm={1} />
        <Grid item xs={12} md={10} lg={10} xl={10} direction="column">
          <div className={classes.list}>
            {orders.map((obj) => obj.video && <ContentViewBox {...obj} key={obj.id} />)}
          </div>
        </Grid>
      </Grid>
    )
}
