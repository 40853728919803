import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import { HOW_TO_WATCH_STEPS_SET1, HOW_TO_WATCH_STEPS_SET2 } from '../../../lib/constant'
import { Container } from './container'

export const WatchSteps = () => {
  const classes = useStyles()
  return (
    <Container title={'How to watch'}>
      <div className={classes.mainContainer}>
        <div className={classes.flex}>
          {HOW_TO_WATCH_STEPS_SET1.map((item, index) => {
            return <Step item={item} index={index + 1} />
          })}
        </div>

        <div className={classes.flex}>
          {HOW_TO_WATCH_STEPS_SET2.map((item, index) => {
            return <Step item={item} index={index + 3} />
          })}
        </div>
      </div>
    </Container>
  )
}

const Step = ({ item, index }) => {
  const classes = useStyles()
  return (
    <div key={item} className={classes.step}>
      <div className={classes.indexContainer}>
        <Typography className={classes.index}>0{index}</Typography>
      </div>
      <div className={classes.content}>
        <Typography className={classes.contentText}>{item}</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  step: {
    display: 'flex',
    height: 65,
    padding: '10px 10px 0px 10px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: '10px 0px 10px 0px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      padding: '20px 0px 10px 0px'
    }
  },
  indexContainer: {
    marginRight: 10
  },
  index: {
    fontFamily: 'Poppins',
    fontSize: '30px',
    marginTop: -7,
    background:
      '-webkit-linear-gradient(rgba(211,211,211,0.7),rgba(211,211,211,0.6),rgba(211,211,211,0.5),rgba(211,211,211,0.4), rgba(211,211,211,0.3),rgba(211,211,211,0.2),rgba(211,211,211,0.1))',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'rgba(255,255,255,0.2)',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '0.1em'
  },
  content: {
    flex: 1
  },
  contentText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '200',
    textAlign: 'left',
    paddingTop: '5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  mainContainer: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  flex: {
    flex: 1
  }
}))
