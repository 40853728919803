import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  planSelectorMain: {
    padding: '40px 0px'
  },
  planItemContainer: {
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    backgroundColor: '#282ddb',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  priceContainer: {
    color: 'white',
    flex: 1,
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  price: {
    paddingLeft: 5,
    paddingRight: 5
  },
  withPromoCode: {
    textDecoration: 'line-through'
  },
  planName: {
    color: 'white',
    flex: 1,
    textAlign: 'center',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  popoverContainer: {
    width: '100%',
    marginTop: 20,
    flex: 1
  },
  popoverPlansListContainer: {
    flex: 1,
    backgroundColor: 'white'
  },
  planTitleContainer: {
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },

  selected: {
    color: 'white'
  },
  selectedIconContainer: {
    color: 'white',
    width: 80,
    fontWeight: '600',
    textAlign: 'right',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 20
    }
  },
  selectedIcon: {
    color: 'white',
    fontSize: 18
  },
  planPriceContainer: {
    margin: '0px',
    width: 80,
    fontWeight: 600,
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  selectedDropDownMenu: {
    borderBottom: '1px solid lightgray',
    paddingTop: '5px',
    paddingBottom: '5px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#282ddb'
  },
  dropDownMenu: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid lightgray',
    paddingTop: '5px',
    paddingBottom: '5px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))
