import { Typography, IconButton } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import { CHECKOUT_STEPS } from '../../../lib/constant'
import { useHeaderStyles } from './styles'
import { PaymentIcon } from '.'
import { useLocation } from 'react-router-dom'

export const Header = ({ title, step, handleStep, closeCheckoutModal }) => {
  const classes = useHeaderStyles()
  const { paymentGateway } = useSelector((state) => state.checkout)
  const location = useLocation()

  const handleGoBack = () => {
    let prevStep = CHECKOUT_STEPS.SELECT_PLAN
    if (step === CHECKOUT_STEPS.CHECKOUT) prevStep = CHECKOUT_STEPS.SELECT_PAYMENT_METHOD
    handleStep(prevStep)
  }

  if (step === CHECKOUT_STEPS.POST_CHECKOUT) return null
  return (
    <div className={classes.headerMainContainer}>
      <div className={classes.content}>
        <Typography className={classes.headerTitle}>{title}</Typography>

        {step !== CHECKOUT_STEPS.SELECT_PLAN && step !== CHECKOUT_STEPS.POST_CHECKOUT && (
          <IconButton onClick={handleGoBack} className={classes.backIconContainer}>
            <ArrowBackIcon className={classes.backIcon} />
          </IconButton>
        )}

        {location.pathname !== '/mobile-checkout' && (
          <IconButton onClick={closeCheckoutModal} className={classes.closeIconButton}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </div>
      {step === CHECKOUT_STEPS.CHECKOUT && paymentGateway && (
        <div className={classes.headerIcons}>
          <PaymentIcon name={paymentGateway.name} header={true} />
        </div>
      )}
    </div>
  )
}
