import React from 'react'
import { Grid } from '@material-ui/core'
import { useMediaPredicate } from 'react-media-hook'

import { MobileViewPlanItem } from './mobileViewPlanItem'
import { DesktopTabletViewPlanItem } from './desktopViewPlanItem'
import { usePlanItemStyles } from './styles'

const PlanItem = ({ item, promoCode }) => {
  const lessThan700 = useMediaPredicate('(max-width: 650px)')
  const classes = usePlanItemStyles()

  const { supportedDevices } = item
  const devices = Object.keys(supportedDevices)
    .map((key) => ({ key, value: supportedDevices[key] }))
    .filter((item) => item.value)

  return (
    <Grid item className={classes.mainContainer}>
      {lessThan700 ? (
        <MobileViewPlanItem item={item} devices={devices} promoCode={promoCode} />
      ) : (
        <DesktopTabletViewPlanItem item={item} devices={devices} promoCode={promoCode} />
      )}
    </Grid>
  )
}

export default PlanItem
