import { makeStyles } from '@material-ui/core'
import { color } from '../../../../styles/color'

export const resetPasswordStyles = makeStyles((theme) => ({
  input: {
    color: color.white,
    border: 'none',
    borderRadius: 4,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: '2em',
    height: 36
  },
  initialFormContainer: {
    justifyContent: 'center',
    display: 'flex'
  },

  verificationCodeContainer: {
    fontSize: 14,
    Color: 'white',
    padding: '15px 5px',
    textAlign: 'right',
    width: '100%'
  },
  resendText: {
    cursor: 'pointer',
    '&:active': {
      color: 'lightgray'
    }
  },
  goBack: {
    flex: 0.3
  },
  continueButton: {
    flex: 0.7
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    paddingTop: 30
  }
}))
