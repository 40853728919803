import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  gridWrapper: {
    padding: '0px 5px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
}))
