import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  codeInput: {
    backgroundColor: 'transparent',
    marginTop: 20,
    '& input': {
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: 'white',
      fontSize: 18,
      fontFamily: 'Rubik'
    }
  },
  resendCodeContainer: {
    textAlign: 'right',
    paddingTop: 10,
    fontSize: 14
  },
  resendCodeText: {
    color: 'white',
    cursor: 'pointer',
    '&:active': {
      color: 'lightgray'
    }
  }
})
