import React from 'react'

import { Loading, EmptyList } from '..'
import { PlansList } from './components'

export const Plans = ({ videoId, handleStep, plans, loading, login, accessToken }) => {
  if (loading) return <Loading />
  if (!loading && plans.length === 0)
    return <EmptyList text={'This movie is not available in your country'} />

  return (
    <PlansList
      plans={plans}
      videoId={videoId}
      handleStep={handleStep}
      login={login}
      accessToken={accessToken}
    />
  )
}
