import { makeStyles } from '@material-ui/styles'
import { isFirefox } from 'react-device-detect'

import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  movieBannerContainer: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flex: 1,
    backgroundColor: color.greypurple,
    overflow: 'auto',
    display: 'flex',
    padding: '15% 0px 30px 0px'
  },
  mobileMovieBannerContainer: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flex: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  mobileImageBanner: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    height: '30vh'
  },
  MobileContent: {
    flex: 1,
    display: 'flex',
    backdropFilter: 'blur(5px)',
    overflow: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    marginTop: '-40px'
  },
  mobilePreviewContainer: {
    padding: '0px 10px',
    paddingBottom: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  movieContent: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  previewContainer: {
    padding: '20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  loadingProgressBar: {
    marginBottom: 10
  },
  loadingText: {
    color: 'white'
  },
  checkoutModal: {
    background: 'rgba(255,255,255,0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  checkoutContainer: {
    outline: 'none',
    background: isFirefox ? 'rgb(23,22,46)' : 'rgba(23,22,46,0.8)',
    backdropFilter: 'blur(10px)',
    height: '85%',
    width: '90%',
    color: 'white',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      borderRadius: 0
    }
  }
}))
