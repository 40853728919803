export default class ViewLogs {
  constructor({ updateViewLogs, updateWatchTime, playMovie, pauseMovie, stallMovie, close }) {
    let logsInterval = null
    let streamInterval = null
    let totalStream = 0
    const videoPlayer = document.getElementById('bitmovinplayer-video-player')

    const updateLogs = () => {
      if (document.getElementById('bitmovinplayer-video-player'))
        updateViewLogs(videoPlayer.currentTime)
      else if (logsInterval) clearInterval(logsInterval)
    }

    const incrementStreaming = () => {
      if (document.getElementById('bitmovinplayer-video-player')) {
        totalStream += 2
        updateWatchTime(totalStream)
      } else if (streamInterval) {
        clearInterval(streamInterval)
        streamInterval = null
        totalStream = 0
      }
    }

    videoPlayer.onwaiting = () => {
      stallMovie(videoPlayer.currentTime)
      if (streamInterval) {
        clearInterval(streamInterval)
        streamInterval = null
      }
    }

    videoPlayer.onplaying = () => {
      if (!streamInterval) {
        streamInterval = setInterval(incrementStreaming, 2000)
      }
    }

    videoPlayer.onplay = function () {
      logsInterval = setInterval(updateLogs, 2000)
      playMovie(videoPlayer.currentTime)
    }

    videoPlayer.onpause = function () {
      pauseMovie(videoPlayer.currentTime)
      if (streamInterval) {
        clearInterval(streamInterval)
        streamInterval = null
      }
      if (logsInterval) clearInterval(logsInterval)
      updateViewLogs(videoPlayer.currentTime)
    }

    videoPlayer.onended = function () {
      if (document.getElementById('bitmovinplayer-video-player'))
        updateViewLogs(videoPlayer.duration)
      if (streamInterval) clearInterval(streamInterval)
      close()
    }
  }
}
