import React from 'react'
import { CircularProgress, Modal, Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { useSelector } from 'react-redux'

export const FullScreenSpinner = ({}) => {
  const { loading, loadingText } = useSelector((state) => state.globalModals)
  const classes = useStyles()
  return (
    <Modal
      open={loading}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <CircularProgress size={25} className={classes.spinner} />
        {loadingText && <Typography className={classes.loadingText}>{loadingText}</Typography>}
      </div>
    </Modal>
  )
}
