import { Drawer, IconButton, List, Typography } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { LOGIN_MENUS } from '../../../lib/constant'
import {
  setTransactionHistoryModal,
  setUpdateProfileModal
} from '../../../redux/modules/globalModals'
import { postLogoutUser } from '../../../redux/modules/user'

import { useSideDrawerStyles } from './styles'
import { logo } from '../../../assets'

export const SideDrawer = ({ navLinks }) => {
  const [state, setState] = useState({ right: false })
  const { login } = useSelector((state) => state.user)

  const classes = useSideDrawerStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ [anchor]: open })
  }

  const handleOption = async (path) => {
    if (path === 'payment-history') dispatch(setTransactionHistoryModal(true))
    else if (path === 'sign-out') await dispatch(postLogoutUser())
    else if (path === 'profile-info') dispatch(setUpdateProfileModal(true))
    else if (path === 'help') history.push('/help')
  }

  const sideDrawerList = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className={classes.menuIconContainer}>
        <IconButton edge="start" aria-label="menu" onClick={toggleDrawer('right', false)}>
          <MenuIcon fontSize="large" className={classes.menuIcon} />
        </IconButton>
      </div>
      <List component="nav">
        {navLinks.map(({ title, path }) => (
          <div className={classes.listItem}>
            <Typography onClick={() => history.push(path)} className={classes.linkText}>
              {title}
            </Typography>
          </div>
        ))}
        {login &&
          LOGIN_MENUS.map(({ title, path }) => (
            <div className={classes.listItem}>
              <Typography className={classes.linkText} onClick={() => handleOption(path)}>
                {title}
              </Typography>
            </div>
          ))}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer('right', true)}>
        <MenuIcon fontSize="large" className={classes.fixedMenu} />
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer('right', true)}
        onClose={toggleDrawer('right', false)}>
        {sideDrawerList('right')}
        <div className={classes.drawerFooter}>
          <img src={logo} alt="Mintrio" style={{ height: '26px', paddingRight: '0.4em' }} />
        </div>
      </Drawer>
    </React.Fragment>
  )
}
