import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { Collapse } from 'react-collapse'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { Cancel, CheckCircle } from '@material-ui/icons'

import { useMobileViewPlanItemStyles } from './styles'
import { SupportedDevices } from './supportedDevices'
import { setPlan } from '../../../../../redux/modules/checkout'
import { RentalTermsModal } from './rentalTermsModal'

export const MobileViewPlanItem = ({ item, promoCode, devices }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { plan } = useSelector((state) => state.checkout)
  const { countryCode } = useSelector((state) => state.location)
  const classes = useMobileViewPlanItemStyles()
  const dispatch = useDispatch()

  const handlePlanSelection = () => dispatch(setPlan(item))
  const openModal = () => setIsVisible(true)
  const closeModal = () => setIsVisible(false)

  return (
    <div className={classes.mainContainer}>
      <div
        role="presentation"
        className={
          plan && plan.id === item.id
            ? clsx(classes.planContainer, classes.selectedPlanBorder)
            : clsx(classes.planContainer, classes.unSelectedPlanBorder)
        }
        onClick={handlePlanSelection}>
        {plan && plan.id === item.id ? (
          <Typography
            className={
              plan && plan.id === item.id
                ? clsx(classes.mobilePlanName, classes.planNameContainerSelected)
                : clsx(classes.mobilePlanName, classes.planNameContainer)
            }>
            {item.planName}
          </Typography>
        ) : (
          <div className={classes.closedPlan}>
            <Typography className={classes.closedPlanName}>{item.planName}</Typography>
            {promoCode ? (
              <Typography className={classes.withoutCouponText}>
                {`${item.currency}`}
                <span className={classes.couponAppliedPrice}>{item.price}</span>
                <span>{item.price - item.price * (promoCode.percentage_off / 100)}</span>
              </Typography>
            ) : (
              <Typography
                className={
                  classes.withoutCouponText
                }>{`${item.currency} ${item.price}`}</Typography>
            )}
          </div>
        )}

        <Collapse isOpened={plan.id === item.id ? true : false}>
          <>
            {countryCode !== 'PK' && (
              <Typography
                className={
                  promoCode ? classes.mobilePriceWithPromoContainer : classes.mobilePriceContainer
                }>
                {`${item.currency} ${item.price}`}
              </Typography>
            )}
            {promoCode && (
              <Typography className={classes.mobilePriceContainer}>
                {`${item.currency} ${item.price - item.price * (promoCode.percentage_off / 100)}`}
              </Typography>
            )}

            {!promoCode && countryCode === 'PK' && (
              <>
                <Typography className={classes.mobilePriceWithPromoContainer}>
                  {`${item.currency}`} {item.planName === 'Standard' ? '699' : '499'}
                </Typography>
                <Typography className={classes.mobilePriceContainer}>
                  {`${item.currency} ${item.price}`}
                </Typography>
              </>
            )}
            <Typography className={classes.mobileQualityContainerSelected}>
              {item.description}
            </Typography>

            <Typography className={classes.mobileQualityContainerSelected}>
              {item.maxResolution}
            </Typography>
            <Typography className={classes.watchOnDevices}>Watch on your</Typography>

            <SupportedDevices devices={devices} />

            <div className={classes.centeredContainer}>
              <Typography className={classes.allowDownloadText}>Allows download</Typography>
              {item.isDownloadable ? (
                <CheckCircle className={classes.checkIcon} />
              ) : (
                <Cancel className={classes.closeIcon} />
              )}
            </div>
            <div className={clsx(classes.centeredContainer, classes.paddingBottom)}>
              <Typography className={classes.rentalTerms} role="presentation" onClick={openModal}>
                View Rental Terms
              </Typography>
            </div>
          </>
        </Collapse>
      </div>

      <RentalTermsModal plan={plan} isVisible={isVisible} closeModal={closeModal} />
    </div>
  )
}
