import React, { useRef } from 'react'
import { isFirefox } from 'react-device-detect'

export const DateInput = ({ value, error, ...otherProps }) => {
  const inputRef = useRef(null)

  const handleBlur = () => {
    if (inputRef && inputRef.current && value === '') {
      inputRef.current.type = 'text'
    }
  }

  const handleFocus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.type = 'date'
    }
  }

  return (
    <input
      {...otherProps}
      style={inputStyle}
      ref={inputRef}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type="text"
    />
  )
}

const inputStyle = {
  backgroundColor: isFirefox ? '#373535' : 'rgba(234,234,234,0.2)',
  backdropFilter: 'blur(30px)',
  WebkitBackdropFilter: 'blur(30px)',
  outline: 'none',
  border: 'none',
  fontSize: 16,
  color: '#FFF'
}
