import { makeStyles } from '@material-ui/styles'
import { color } from '../.././../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'center',
    margin: '30px 10px 10px 10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    backgroundColor: color.buttonPrimaryColor,
    color: color.white,
    '&:hover': {
      backgroundColor: color.buttonBlue
    }
  },
  selected: {
    backgroundColor: color.buttonBlue
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  displayText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  }
}))
