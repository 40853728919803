import React from 'react'
import { Modal } from '@material-ui/core'

import { useStyles } from './styles'
import { HeaderUserMissingDetail } from '../headerUserMissingDetail'
import { Form } from './components'

export const UserMissingDetail = ({ open }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <HeaderUserMissingDetail title={'COMPLETE YOUR PROFILE'} />
        <div className={classes.contentContainer}>
          <Form />
        </div>
      </div>
    </Modal>
  )
}
