/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:774127d7-f302-419c-9972-36fafda901b0",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_7o389u5M6",
    "aws_user_pools_web_client_id": "2nbpvfivjucokn965eomhfteh8",
    "oauth": {
        "domain": "mintriobackend5641da28-5641da28-production.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,myapp://,mintriodigital://,https://d3e10un742jyis.cloudfront.net/,https://d3u0vynbdrvcln.cloudfront.net/,https://www.mintrio.com/",
        "redirectSignOut": "http://localhost:3000/,myapp://,mintriodigital://,https://d3u0vynbdrvcln.cloudfront.net/,https://d3e10un742jyis.cloudfront.net/,https://www.mintrio.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://av66fm5wybgr3b5mrulwxpa5uu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gltvrqcvkfh2jgld3kiv5os3si",
    "aws_cloud_logic_custom": [
        {
            "name": "checkout",
            "endpoint": "https://hp3ht6wsa7.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "getSignedUrl",
            "endpoint": "https://v55te8v4ej.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "order",
            "endpoint": "https://hlfj0dums6.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "rateVideo",
            "endpoint": "https://3j3nt3995e.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "promoCode",
            "endpoint": "https://ucdkun118j.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "trailerViewLog",
            "endpoint": "https://ptmnysds35.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "activityLogs",
            "endpoint": "https://i2rotw8p82.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "auth",
            "endpoint": "https://fa7c7s7oi2.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "user",
            "endpoint": "https://6zt9iqrr1h.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "admin",
            "endpoint": "https://0cf6h74dt4.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "thumbnails-and-captions220515-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
