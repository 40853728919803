import React, { useReducer, useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { Modal } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ReactCursorPosition from 'react-cursor-position'

import {
  intializeLogs,
  updateLogs,
  syncLogs,
  updateMintuesStreamed
} from '../../../redux/modules/viewLogs'
import { expireOrder, startMovieFirstTime } from '../../../redux/modules/myContent'
import { useStyles } from './styles'
import { closeVideoPlayer } from '../../../redux/modules/videoPlayer'
import { getSignedUrl } from '../../../api/order'
import { ALERT_TYPES, USER_ACTIVITY_ACTIONS } from '../../../lib/constant'
import { updateQuarterlyFlags } from '../../../api/viewLogs'
import { showAlert } from '../../../redux/modules/alertHandler'
import { useHistory } from 'react-router-dom'
import { addNewLog } from '../../../redux/modules/userActivityLogs'
import errorHandler from '../../../lib/errorHandler'
import { setVolumne } from '../../../redux/modules/userPreferences'
import BitmovinPlayer from '../../players/bitmovinPlayers'

export const VideoPlayerModal = () => {
  const { video, isPlayerModalVisible } = useSelector((state) => state.videoPlayer)
  const {
    player: { volume }
  } = useSelector((state) => state.userPreferences)
  const [orderDetail, setOrderDetail] = useState(null)

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { userId } = useSelector((state) => state.user)
  const { orders } = useSelector((state) => state.myContent)
  const { countryCode } = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)
  const { id: videoId, thumbnail_lg } = video

  const [{ videoOptions }, setData] = useReducer((state, newState) => ({ ...state, ...newState }), {
    videoOptions: null
  })

  useEffect(() => {
    return history.listen((location) => {
      dispatch(syncLogs(true, video.id))
      dispatch(closeVideoPlayer())
    })
  }, [history, dispatch, video])

  useEffect(() => {
    if (orders.length > 0 && videoId) {
      const filterdOrders = orders.filter((order) => order.video_id === videoId)
      setOrderDetail(filterdOrders.length > 0 ? filterdOrders[0] : null)
      const started = filterdOrders[0].started

      // change start flag value and expire timestamp locally
      if (!started) dispatch(startMovieFirstTime(videoId))
    }
  }, [orders, videoId, setOrderDetail, dispatch])

  const updateWatchTime = (minutes_streamed) => {
    dispatch(updateMintuesStreamed(video.id, minutes_streamed))
  }

  const updateViewLogs = (last_timestamp) => {
    const payload = {
      last_timestamp,
      ended_at: moment().format()
      // bandwidth_used: 12
    }
    dispatch(updateLogs(video.id, payload))
  }

  const updateFlags = async (flags) => {
    try {
      if (orderDetail)
        await updateQuarterlyFlags(orderDetail.id, orderDetail.purchased_at, flags, dispatch)
    } catch (err) {
      errorHandler.report(err)
    }
  }

  const handleError = useCallback(
    (message) => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          message,
          isVisible: true
        })
      )
      dispatch(closeVideoPlayer())
    },
    [dispatch]
  )

  useEffect(() => {
    if (userId && countryCode && videoId && isPlayerModalVisible && deviceTypeId) {
      ;(async () => {
        try {
          const response = await getSignedUrl(videoId, countryCode, userId, deviceTypeId, dispatch)

          if (response) {
            dispatch(intializeLogs(videoId, response.viewLogId))

            setData({
              videoOptions: {
                autoplay: true,
                controls: true,
                preload: 'none',
                poster: thumbnail_lg,
                sources: [
                  {
                    src: response.url,
                    type: 'application/x-mpegURL',
                    overrideNative: true
                  }
                ]
                // tracks
              }
            })

            dispatch(
              addNewLog(USER_ACTIVITY_ACTIONS.WATCH_MOVIE, {
                videoId,
                status: 'start',
                date: new Date()
              })
            )
          } else {
            handleError('Something went wrong please try again.')
          }
        } catch (err) {
          errorHandler.report(err)
          if (err.response && err.response.status && err.response.status === 404) {
            dispatch(expireOrder(videoId))
            setOrderDetail(null)
          }
          handleError(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Network issue or somehting else is wrong. Please try again'
          )
        }
      })()
    }
  }, [
    userId,
    countryCode,
    isPlayerModalVisible,
    videoId,
    deviceTypeId,
    thumbnail_lg,
    dispatch,
    handleError
  ])

  const close = (timestamp) => {
    dispatch(syncLogs(true, video.id))
    dispatch(closeVideoPlayer())
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.END_MOVIE, {
        videoId: video.id,
        status: 'end',
        timestamp: timestamp,
        date: new Date()
      })
    )
  }

  const playMovie = (timestamp) => {
    var updated_timestamp = 0
    if (orderDetail && orderDetail.started) {
      var updated_timestamp = timestamp
    }
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.WATCH_MOVIE, {
        videoId: video.id,
        status: 'play',
        timestamp: updated_timestamp,
        date: new Date()
      })
    )
  }

  const pauseMovie = (timestamp) => {
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.WATCH_MOVIE, {
        videoId: video.id,
        status: 'pause',
        timestamp: timestamp,
        date: new Date()
      })
    )
  }

  const stallMovie = (timestamp) => {
    console.log('stall', timestamp)
    dispatch(
      addNewLog(USER_ACTIVITY_ACTIONS.WATCH_MOVIE, {
        videoId: video.id,
        status: 'stall',
        timestamp: timestamp,
        date: new Date()
      })
    )
  }

  const handleVolumeChanges = (value) => dispatch(setVolumne(value))

  return (
    <Modal
      open={isPlayerModalVisible}
      className={classes.modalContainer}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      {!videoOptions || !orderDetail ? (
        <div className="loading-container">
          <div className="loading-container-indicator" role="img" />
          <div className="loading-container-indicator" role="img" />
          <div className="loading-container-indicator" role="img" />
        </div>
      ) : (
        <ReactCursorPosition
          mapChildProps={({ position }) => {
            return {
              point: position
            }
          }}>
          <div className={classes.mainContainer}>
            {videoOptions && videoOptions.sources && videoOptions.sources.length && (
              <BitmovinPlayer
                title={video.title}
                description={video.long_description}
                url={videoOptions.sources[0].src}
                poster={thumbnail_lg}
                close={close}
                lastTimeStamp={orderDetail && orderDetail.started ? orderDetail.last_timestamp : 0}
                isProtected={true}
                updateViewLogs={updateViewLogs}
                updateWatchTime={updateWatchTime}
                updateFlags={updateFlags}
                playMovie={playMovie}
                pauseMovie={pauseMovie}
                stallMovie={stallMovie}
                volume={volume}
                handleVolumeChanges={handleVolumeChanges}
              />
            )}
          </div>
        </ReactCursorPosition>
      )}
    </Modal>
  )
}
