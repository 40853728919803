import { muhibAvatar, hsyAvatar, sanamAvatar, saraAvatar } from '../assets'

export const constant = {
  SECTIONS_WITH_QUESTIONS_ANSWERS: [
    {
      id: 'Subscription',
      name: 'Subscription',
      questions: [
        {
          id: '0_0',
          question: 'How do I cancel Mintrio Subscription from2?',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '0_1',
          question: 'How do I cancel Mintrio Subscription?2',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '0_2',
          question: 'How do I cancel Mintrio Subscription?3',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'subscriptionQuestions'
        }
      ]
    },
    {
      id: 'Registration',
      name: 'Registration',
      questions: [
        {
          id: '1_0',
          question: 'How do I cancel Mintrio Subscription from?',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '1_1',
          question: 'How do I cancel Mintrio Subscription?2',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '1_2',
          question: 'How',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'subscriptionQuestions'
        }
      ]
    },
    {
      id: 'Purchasing_For_Yourself',
      name: 'Purchasing for Yourself',
      questions: [
        {
          id: '2_0',
          question: 'How do I cancel Mintrio Subscription from?',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '2_1',
          question: 'How do I cancel Mintrio Subscription?2',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '2_2',
          question: 'How do I cancel Mintrio Subscription?3',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'subscriptionQuestions'
        }
      ]
    },
    {
      id: 'Gifting_to_a_friend',
      name: 'Gifting to a Friend',
      questions: [
        {
          id: '3_0',
          question: 'How do I cancel Mintrio Subscription from?',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '3_1',
          question: 'How do I cancel Mintrio Subscription?2',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'registrationQuestions'
        },
        {
          id: '3_2',
          question: 'How do I cancel Mintrio Subscription?3',
          answer:
            'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.',
          type: 'subscriptionQuestions'
        }
      ]
    }
  ]
}

export const LOGGED_IN_LINKS = [
  // { title: 'Home', path: '/#' },
  { title: 'Discover', path: '/#discover' },
  { title: 'My Content', path: '/my-content' },
  { title: 'How to Watch', path: '/how-to-watch' }
]

export const LINKS = [
  // { title: 'Home', path: '/#' },
  { title: 'Discover', path: '/#discover' },
  { title: 'How to Watch', path: '/how-to-watch' },
  { title: 'Sign In', path: '/sign-in' }
]

export const GENDER_OPTIONS = ['Male', 'Female', 'Other / Prefer not to say']

export const CASTS = [
  {
    id: '0',
    image: muhibAvatar
  },
  {
    id: '1',
    image: sanamAvatar
  },
  {
    id: '2',
    image: saraAvatar
  },
  {
    id: '3',
    image: hsyAvatar
  }
]

export const LANGUAGES_CODE = {
  English: 'en',
  Urdu: 'ur',
  Hindi: 'hi',
  Punjabi: 'pa',
  Pushto: 'ps',
  Mandarin: 'man'
}

export const CODE_TO_LANGUAGE = {
  en: 'English',
  ur: 'Urdu',
  hi: 'Hindi',
  pa: 'Punjabi',
  ps: 'Pushto',
  man: 'Mandarin'
}

export const MY_CONTENT_TABS = [
  {
    id: 0,
    name: 'Active'
  },
  {
    id: 1,
    name: 'Expired'
  },
  {
    id: 2,
    name: 'All'
  }
]

export const AUTHENTICATION_PAGES = ['/sign-in', '/sign-up', '/reset-password']

export const FOOTER_TABS = [
  {
    name: 'privacy policy',
    path: 'https://mintrio-legal-docs.s3.amazonaws.com/MINTRIO+Privacy+Policy.pdf',
    newTab: true
  },
  {
    name: 'terms and conditions',
    path: 'https://mintrio-legal-docs.s3.amazonaws.com/Mintrio+Viewer+Terms+of+Service+Agreement.pdf',
    newTab: true
  },
  {
    name: 'Help center',
    path: '/help'
  }
]

export const CHECKOUT_STEPS = {
  SELECT_PLAN: 'SELECT_PLAN',
  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
  CHECKOUT: 'CHECKOUT',
  POST_CHECKOUT: 'POST_CHECKOUT'
}

export const PAYMENT_METHODS = {
  STRIPE: 'Card',
  EASY_PAISA: 'easypaisa',
  JAZZ_CASH: 'jazz cash',
  PAYPAL: 'paypal',
  CRYPTO: 'Crypto'
}

export const ALERT_TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

export const PROFILE_DROPDOWN_MENUS = [
  {
    id: '0',
    title: 'Profile Info'
  },
  {
    id: '1',
    title: 'PAYMENT HISTORY'
  },
  {
    id: '2',
    title: 'HELP CENTER'
  },
  {
    id: '3',
    title: 'Sign Out'
  }
]

export const DUMMY_NOTIFICATIONS = [
  {
    id: '0',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '20 mints'
  },
  {
    id: '1',
    title: 'Pre Book with code ISHRAT20 for 20% off and other perks',
    time: '30 mints'
  }
]

export const LOGIN_MENUS = [
  { title: 'Profile Info', path: 'profile-info' },
  { title: 'Payment History', path: 'payment-history' },
  { title: 'Help Center', path: 'help' },
  { title: 'Sign Out', path: 'sign-out' }
]

export const TRANSACTION_HISTORY_SOLUMNS = [
  {
    id: '0',
    name: 'Refrence Number'
  },
  {
    id: '1',
    name: 'Title'
  },
  {
    id: '2',
    name: 'For'
  },
  {
    id: '3',
    name: 'Method'
  },
  {
    id: '4',
    name: 'Type'
  },
  {
    id: '5',
    name: 'Date'
  },
  {
    id: '6',
    name: 'Total'
  }
]

export const PLAYER_STATES = {
  PAUSED: 'PAUSED',
  PLAYING: 'PLAYING'
}

export const HOW_TO_WATCH_STEPS_SET1 = [
  'Sign up for a free Mintrio account',
  'Rent your favourite content'
]

export const HOW_TO_WATCH_STEPS_SET2 = [
  'Sit back and enjoy the show',
  'Share the love with your friends and family'
]

export const WATCHING_WAYS = [
  {
    id: '0',
    title: 'ad-free experience',
    detail:
      'Watch Mintrio Originals and other premium content without any ads, available only on Mintrio.'
  },
  {
    id: '0',
    title: 'Save your data',
    detail: 'Download available titles and watch offline, wherever you are.'
  },
  {
    id: '0',
    title: 'multi-screening',
    detail: 'Stream on up to four devices at the same time.'
  }
]

export const SUPPORT_EMAIL = 'support@mintrio.com'

export const USER_ACTIVITY_ACTIONS = {
  SIGN_UP: '2b25c7c2-4261-4ec8-92aa-27984b61e08d',
  SIGN_IN: '571506c2-fd25-4547-ade1-7fd4e06a232a',
  SIGN_OUT: 'a1c9110d-827c-4ea5-b16c-ee448c953312',
  WATCH_TRAILER: '57944baa-e62c-4f6b-87e9-d92f5ae519d3',
  WATCH_MOVIE: '3be1d2ee-8183-4f88-8e48-47b019166642',
  END_MOVIE: '3a7a1441-7b3f-4590-955d-9a2a78279969',
  PREVIEW: 'b2b3183f-1d21-4419-8a81-937c2565b259',
  START_CHECKOUT: '4d87ce9e-40ff-4444-95bb-d931cc70b942',
  SELECT_PLAN: 'acd235e9-d458-44d7-89ca-cda898b6a7bb',
  SELECT_PAYMENT_GATEWAY: '0144a48d-5e29-48ec-8c76-4e11cf7c9dbf',
  CHECKOUT: '87f77160-7213-4064-875a-25cf608eda4a',
  PAGE_VISIT: '721a449a-fb3a-4c45-92a3-868a00a07b18',
  SHARE_PAGE: '2a198f01-c992-4990-a057-78cf0bd867e2',
  INVITE_LINK: '52389dd6-8022-4ebd-8b85-6bd8362c5526'
}

export const SHARE_OPTIONS = [
  {
    id: 'fab fa-facebook-f',
    color: '#5177D0',
    name: 'Facebook'
  },
  {
    id: 'fab fa-twitter',
    color: '#00acee',
    name: 'Twitter'
  },
  // {
  //   id: 'fab fa-instagram',
  //   color: '#F56040',
  //   name: 'Instagram'
  // },
  {
    id: 'fab fa-linkedin-in',
    color: '#0e76a8',
    name: 'Linkedin'
  },
  {
    id: 'far fa-envelope',
    color: '#4285F4',
    name: 'Email'
  },
  {
    id: 'fas fa-link',
    color: '#fff',
    name: 'Link'
  }
]

export const PLAYER_KEYS_OPERATIONS = {
  PLAY_PAUSE: 'PLAY_PAUSE',
  REWIND: 'REWIND',
  FORWARD: 'FORWARD',
  VOLUME_UP: 'VOLUME_UP',
  VOLUME_DOWN: 'VOLUME_DOWN'
}

export const EVENT_CATEGORIES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  PREVIEW: 'PREVIEW'
}

export const SOCIAL_ICONS = [
  {
    id: 'facebook',
    icon: 'fa fa-facebook footer-icon',
    // navigateTo: 'https://www.facebook.com/mintriodigital'
    navigateTo: 'https://www.facebook.com/Ishratmadeinchina2022'
  },
  {
    id: 'twitter',
    icon: 'fa fa-twitter footer-icon',
    // navigateTo: 'https://twitter.com/mintriodigital'
    navigateTo: 'https://twitter.com/Ishratfilm2022'
  },
  {
    id: 'instagram',
    icon: 'fa fa-instagram footer-icon',
    // navigateTo: 'https://www.instagram.com/mintriodigital'
    navigateTo: 'https://www.instagram.com/ishratmadeinchina2022/'
  },
  {
    id: 'youtube',
    icon: 'fa fa-youtube-play footer-icon',
    navigateTo: 'https://www.youtube.com/channel/UCvpDDjtbWRVw_-Y2psoQ4Xg'
  }
]

export const APP_STORE_LINK = 'https://apps.apple.com/app/1583610799'
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.levitatedata.mintrio'

export const GEO_IP_LOOKUP_DEV_URL = 'https://ipwho.is'
export const GEO_IP_LOOKUP_PROD_URL = 'https://ipwhois.pro'
