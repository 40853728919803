import React, { useState, useReducer, useEffect } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'

import { TextInput } from '../../../../../../components/TextInput'
import { Button } from '../../../../../../components/Button'
import { StripeLayout } from './stripeLayout'
import { useStyles } from './styles'
import { checkoutStripe, resetCheckout } from '../../../../../../redux/modules/checkout'
import { showAlert } from '../../../../../../redux/modules/alertHandler'
import { ALERT_TYPES, CHECKOUT_STEPS } from '../../../../../../lib/constant'
import { color } from '../../../../../../styles/color'
import clsx from 'clsx'
import errorHandler from '../../../../../../lib/errorHandler'
import { setFullScreenSpinner } from '../../../../../../redux/modules/globalModals'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#FFFFFF',
      '&::placeholder': {
        color: '#8e8e99'
      }
    },
    invalid: {
      color: '#FFFFFF'
    }
  }
}
const Form = ({ videoId, handleStep, accessToken }) => {
  const classes = useStyles()

  const [isDisabled, setIsDisabled] = useState(true)
  const [cardValue, setCardValue] = useState('')

  const [{ cardNumberError, cvcError, expiryError }, setError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cardNumberError: false,
      cvcError: false,
      expiryError: false
    }
  )

  const stripe = useStripe()
  const elements = useElements()

  const dispatch = useDispatch()

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  })

  const [{ firstName, lastName }, setInputValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: '',
      lastName: ''
    }
  )

  const handleInput = (event) => {
    const { name, value } = event.target

    setInputValue({ [name]: value })
  }

  const handleCardElementOnChange = (e) => {
    setCardValue(e.value)
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  const setLoader = (loading, loadingText) => {
    dispatch(setFullScreenSpinner({ loading, loadingText }))
  }

  const onSubmit = async (cardValid) => {
    if (!cardValid) {
      const payload = { message: 'Please fill the form', type: ALERT_TYPES.ERROR, isVisible: true }
      dispatch(showAlert(payload))
      return
    }
    setLoader(true, 'Checkout in progress...')

    try {
      const cardElement = elements.getElement(CardNumberElement)

      await dispatch(
        checkoutStripe(accessToken, { firstName, lastName }, stripe, cardElement, videoId)
      )
      dispatch(resetCheckout())
      setLoader(false, '')
      handleStep(CHECKOUT_STEPS.POST_CHECKOUT)
    } catch (error) {
      errorHandler.report(error)
      setLoader(false, '')
      const payload = {
        message: error.response
          ? error.response.data.message
          : 'Something went wrong please try again',
        type: ALERT_TYPES.ERROR,
        isVisible: true
      }
      dispatch(showAlert(payload))
    }
  }

  useEffect(() => {
    if (cardComplete.cardCvc && cardComplete.cardExpiry && cardComplete.cardNumber)
      setIsDisabled(false)
    else setIsDisabled(true)
  }, [cardComplete.cardCvc, cardComplete.cardExpiry, cardComplete.cardNumber, setIsDisabled])

  const cardValid = cardComplete.cardCvc && cardComplete.cardExpiry && cardComplete.cardNumber

  return (
    <>
      <div>
        <TextInput
          name="firstName"
          value={firstName}
          onChange={handleInput}
          placeholder="First Name"
          className={classes.inputField}
        />
        <TextInput
          name="lastName"
          value={lastName}
          onChange={handleInput}
          placeholder="Last Name"
          className={classes.inputField}
        />

        <div
          className={
            cardNumberError
              ? clsx(classes.stripInputContainer, classes.redBorder)
              : classes.stripInputContainer
          }>
          <CardNumberElement
            onBlur={() => {
              setError({ cardNumberError: !cardComplete.cardNumber })
            }}
            onFocus={() => {
              setError({ cardNumberError: false })
            }}
            options={ELEMENT_OPTIONS}
            id="cardNumber"
            value={cardValue}
            onChange={handleCardElementOnChange}
          />
        </div>
        <div
          className={
            expiryError
              ? clsx(classes.stripInputContainer, classes.redBorder)
              : classes.stripInputContainer
          }>
          <CardExpiryElement
            onBlur={() => {
              setError({ expiryError: !cardComplete.cardExpiry })
            }}
            onFocus={() => {
              setError({ expiryError: false })
            }}
            options={ELEMENT_OPTIONS}
            id="expiry"
            onChange={handleCardElementOnChange}
          />
        </div>
        <div
          className={
            cvcError
              ? clsx(classes.stripInputContainer, classes.redBorder)
              : classes.stripInputContainer
          }>
          <CardCvcElement
            onBlur={() => {
              setError({ cvcError: !cardComplete.cardCvc })
            }}
            onFocus={() => {
              setError({ cvcError: false })
            }}
            options={ELEMENT_OPTIONS}
            id="cvc"
            onChange={handleCardElementOnChange}
          />
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button
          onClick={() => onSubmit(cardValid)}
          title="Continue"
          className={classes.button}
          style={{ backgroundColor: isDisabled ? 'gray' : color.buttonBlue, color: '#FFFFFF' }}
          disabled={isDisabled}
        />
      </div>
    </>
  )
}

export const StripeForm = ({ videoId, handleStep, accessToken }) => {
  return (
    <StripeLayout>
      <Form accessToken={accessToken} videoId={videoId} handleStep={handleStep} />
    </StripeLayout>
  )
}
