import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    padding: '40px',
    textAlign: 'center',
    height: '100vh',
    position: 'relative'
  },
  mainIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  titleSkeleton: {
    marginBottom: 30,
    width: 900,
    [theme.breakpoints.down('xs')]: {
      width: 300
    }
  },
  midTitle: {
    width: 400,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 280
    }
  },
  subTitleSkeleton: {
    width: 390,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 280
    }
  },

  socialIcons: {
    width: '150px'
  },
  logoSkeleton: {
    width: '300px'
  }
}))
