import React from 'react'
import { IconButton, Modal, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'

import { setWatchOnMobile } from '../../../redux/modules/globalModals'
import { useStyles } from './styles'
import { StoreButton } from '../../storeButton'
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../../../lib/constant'

export const WatchOnMobileModal = () => {
  const { isWatchOnMobileModalVisible } = useSelector((state) => state.globalModals)
  const dispatch = useDispatch()
  const classes = useStyles()

  const close = () => dispatch(setWatchOnMobile(false))

  return (
    <Modal
      open={isWatchOnMobileModalVisible}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.mainContainer}>
        <div className={classes.textContainer}>
          <Typography
            className={classes.text}>{`WATCH THE MOVIE ON\nYOUR PHONE OR TABLET`}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <div style={{ padding: '5px 0px' }}>
            <StoreButton width={160} height={50} store={'ios'} link={APP_STORE_LINK} />
          </div>
          <div style={{ padding: '5px 0px' }}>
            <StoreButton width={160} height={50} store={'android'} link={PLAY_STORE_LINK} />
          </div>
        </div>

        <div className={classes.closeButton}>
          <IconButton onClick={close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}
