import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { makeStyles } from '@material-ui/styles'

export const SkeletonList = () => {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.titleSkeleton}>
          <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
            <Skeleton height={'5vh'} />
          </SkeletonTheme>
        </div>
      </div>

      {[1, 2, 3, 4].map((item) => {
        return (
          <div key={item} className={classes.questionContainer}>
            <div className={classes.questionSkeleton}>
              <SkeletonTheme color={'rgba(35, 35, 65, 1)'} highlightColor={'rgba(55, 55, 80, 1)'}>
                <Skeleton height={'7vh'} />
              </SkeletonTheme>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: 'rgba(43, 42, 66, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    padding: '30px 40px',
    margin: '20px 0px'
  },
  titleContainer: {
    padding: '0px 0px 10px 0px',
    display: 'flex'
  },
  questionContainer: {
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  questionSkeleton: {
    width: '100%'
  },
  titleSkeleton: {
    width: '35%',
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  }
}))
