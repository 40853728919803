import awsConfig from './aws-exports'
import Amplify from 'aws-amplify'

/**
 * The sign in and signout redirect url is in the format
 * ['http://localhost:3000/,myapp://,https://staging.mintrio.com/,https://www.mintrio.com/']
 * We are looking for the relevent domain if exists will use that otherwise will use localhost
 */

const filteredRedirectSignIn = awsConfig.oauth.redirectSignIn
  .split(',')
  .filter((item) => item.includes(window.location.hostname))
const redirectSignIn =
  filteredRedirectSignIn.length > 0 ? filteredRedirectSignIn[0] : 'http://localhost:3000/'

const filteredRedirectSignOut = awsConfig.oauth.redirectSignOut
  .split(',')
  .filter((item) => item.includes(window.location.hostname))

const redirectSignOut =
  filteredRedirectSignOut.length > 0 ? filteredRedirectSignOut[0] : 'http://localhost:3000/'

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut
  }
}

Amplify.configure(updatedAwsConfig)
