import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '0px 15px'
  },
  accordion: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid gray',
    borderRadius: '0px !important'
  },
  expandableIcon: {
    color: 'white',
    padding: 0
  },
  accordionSummary: {
    padding: 0
  },
  headerTitle: {
    color: 'white',
    flex: 1,
    fontSize: 15
  },
  headerDate: {
    color: 'white',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center'
  },
  accordionDetail: {
    padding: '0px 10px 10px 10px',
    flexDirection: 'column',
    display: 'flex'
  },
  item: {
    color: 'white',
    fontSize: 13,
    fontWeight: '300'
  }
}))
