import { helpPageBackground } from '../../assets'
import { makeStyles } from '@material-ui/core'
import { color } from '../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundImage: `url(${helpPageBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  gridContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: ({ hideNavBars }) => `${hideNavBars ? '0px' : '50px'} 20px 0px 20px`
  },
  inputField: {
    color: 'white',
    background: 'rgba(76, 75, 104, 0.5)',
    borderRadius: '5px',
    border: '1px solid #42415E',
    flex: '80%',
    paddingLeft: 12,
    paddingRight: 12,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputContainer: {
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  helpTextContainer: {
    paddingTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  helpText: {
    color: 'white',
    fontSize: 23,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  searchButtonContainer: {
    width: '30%',
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: 0
    }
  },
  searchButton: {
    width: '100%',
    height: 40,
    backgroundColor: color.primaryColor,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      height: 36
    },
    '&:disabled': {
      backgroundColor: color.lightGrey,
      color: 'lightgray'
    },
    '&:hover': {
      backgroundColor: color.primaryColor
    },
    '&:active': {
      backgroundColor: color.primaryColor
    }
  }
}))
