import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

import { verificationStyles } from './styles'
import { CodeInput, GridWRapper, Button } from '../../../../components'
import { validateVerificationCode } from '../../../../lib/utils'
import { postLoginUser } from '../../../../redux/modules/user'
import { ALERT_TYPES, USER_ACTIVITY_ACTIONS, EVENT_CATEGORIES } from '../../../../lib/constant'
import { addNewLog } from '../../../../redux/modules/userActivityLogs'
import errorHandler from '../../../../lib/errorHandler'
import { showAlert } from '../../../../redux/modules/alertHandler'

export const VerificationForm = () => {
  const classes = verificationStyles()
  const [code, setCode] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const locationData = useSelector((state) => state.location)
  const { id: deviceTypeId } = useSelector((state) => state.deviceType)

  const {
    registration: { password }
  } = useSelector((state) => state.user)

  const dispatch = useDispatch()
  const { email } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (validateVerificationCode(code)) setIsValid(true)
    else setIsValid(false)
  }, [code])

  const handleChange = (value) => setCode(value)

  const loginUser = async () => {
    try {
      await dispatch(postLoginUser(email, password, true))
      dispatch(
        addNewLog(USER_ACTIVITY_ACTIONS.SIGN_UP, {
          email_address: email,
          device_type_id: deviceTypeId,
          ip_address: locationData.query,
          country_code: locationData.countryCode
        })
      )
      history.replace('/')

      window.gtag('event', 'account_creation', {
        event_label: 'Email Address',
        event_category: EVENT_CATEGORIES.SIGN_UP,
        event_value: email,
        device_type_id: deviceTypeId,
        ip_address: locationData.query
      })
    } catch (err) {
      errorHandler.report(err)
      history.push('/sign-in')
    }
  }

  const verifyEmail = async (event) => {
    if (event) event.preventDefault()
    if (loading) return

    try {
      setLoading(true)
      const status = await Auth.confirmSignUp(email, code)
      if (status === 'SUCCESS') {
        await loginUser()

        window.gtag('event', 'confirmed_code', {
          event_label: 'Email Address',
          event_category: EVENT_CATEGORIES.SIGN_UP,
          event_value: email
        })
      } else {
        setLoading(false)
      }
    } catch (err) {
      errorHandler.report(err)
      dispatch(
        showAlert({
          message: err.message || 'Invalide verification code',
          type: ALERT_TYPES.ERROR,
          isVisible: true
        })
      )
      setLoading(false)
    }
  }

  return (
    <form onSubmit={verifyEmail} className={classes.initialFormContainer}>
      <Grid container md={5} lg={5} xs={10} sm={10}>
        <GridWRapper>
          <CodeInput email={email} handleChange={handleChange} />
        </GridWRapper>
        <div className={classes.buttonsContainer}>
          <GridWRapper>
            <Button
              disabled={!isValid}
              title="Continue"
              color="primary"
              hover
              loading={loading}
              onClick={verifyEmail}
              style={{ width: '70%', height: 36 }}
              type={'submit'}
            />
          </GridWRapper>
        </div>
      </Grid>
    </form>
  )
}
