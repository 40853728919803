export const listVideosByStatus = /* GraphQL */ `
  query ListVideosByStatus(
    $statusValue: status!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByStatus(
      statusValue: $statusValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        duration
        short_description
        long_description
        banner_message
        release_date
        is_orignal
        language
        supported_captions {
          items {
            id
            language
            is_orignal
          }
          nextToken
        }
        audio_tracks {
          items {
            id
            language
            is_orignal
          }
        }
        cast_crew {
          items {
            id
            cast_crew_type {
              id
              title
            }
            cast_crew {
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
            }
          }
          nextToken
        }
        content_rating {
          items {
            content_rating {
              title
            }
          }
        }
        trailers(filter: { status: { eq: true } }) {
          items {
            id
            hls_url
            additional_attributes
            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            type
          }
          nextToken
        }

        genres {
          items {
            id
            genre {
              id
              title
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      duration
      short_description
      long_description
      banner_message
      status
      release_date
      is_orignal
      language
      supported_captions {
        items {
          id
          language
          is_orignal
        }
        nextToken
      }
      audio_tracks {
        items {
          id
          language
          is_orignal
        }
      }
      content_rating {
        items {
          content_rating {
            title
          }
        }
      }
      cast_crew {
        items {
          id
          cast_crew_type {
            id
            title
          }
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
          }
        }
        nextToken
      }
      trailers(filter: { status: { eq: true } }) {
        items {
          id
          hls_url
          additional_attributes
          status
          type
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          is_main
        }
        nextToken
      }
      genres {
        items {
          id
          updatedAt
          genre {
            id
            title
          }
        }
        nextToken
      }
    }
  }
`
