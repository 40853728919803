//constants
const SET_WATCH_ON_MOBILE = 'SET_WATCH_ON_MOBILE'
const SET_TRANSACTION_HISTORY_MODAL = 'SET_TRANSACTION_HISTORY_MODAL'
const SET_UPDATE_PRIFILE_MODAL = 'SET_UPDATE_PRIFILE_MODAL'
const SET_FULL_SCREEN_SPINNER = 'SET_FULL_SCREEN_SPINNER'

export const setWatchOnMobile = (value) => ({
  type: SET_WATCH_ON_MOBILE,
  payload: { isWatchOnMobileModalVisible: value }
})

export const setTransactionHistoryModal = (value) => ({
  type: SET_TRANSACTION_HISTORY_MODAL,
  payload: { isTransactionHistoryVisbile: value }
})

export const setUpdateProfileModal = (value) => ({
  type: SET_UPDATE_PRIFILE_MODAL,
  payload: { isUpdateProfileVisible: value }
})

export const setFullScreenSpinner = (payload) => ({
  type: SET_FULL_SCREEN_SPINNER,
  payload: payload
})

//reducer
const initialState = {
  isWatchOnMobileModalVisible: false,
  isTransactionHistoryVisbile: false,
  isUpdateProfileVisible: false,
  loading: false,
  loadingText: ''
}

const reducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SET_WATCH_ON_MOBILE:
      return {
        ...state,
        isWatchOnMobileModalVisible: payload.isWatchOnMobileModalVisible
      }
    case SET_TRANSACTION_HISTORY_MODAL:
      return {
        ...state,
        isTransactionHistoryVisbile: payload.isTransactionHistoryVisbile
      }
    case SET_UPDATE_PRIFILE_MODAL:
      return {
        ...state,
        isUpdateProfileVisible: payload.isUpdateProfileVisible
      }
    case SET_FULL_SCREEN_SPINNER:
      return {
        ...state,
        loading: payload.loading,
        loadingText: payload.loadingText
      }
    default:
      return state
  }
}

export default reducer
