import { makeStyles } from '@material-ui/core'
import { color } from '../../../../styles/color'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100vh',
    backgroundColor: color.primaryColor,
    display: 'flex'
  },
  leftcontainer: {
    display: 'flex',
    backgroundColor: 'rgba(55, 55, 80, 1)',
    backdropFilter: 'blur(30px)',
    padding: 20,
    textAlign: 'center',
    height: '100vh',
    position: 'relative'
  },
  bannerMessageContainer: {
    position: 'absolute',
    top: 60,
    left: 20,
    right: 20,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerSkeleton: {
    height: 10,
    width: 200
  },
  titleSkeleton: {
    width: 290,
    [theme.breakpoints.down('xs')]: {
      width: 230
    }
  },
  subTitleSkeleton: {
    width: 320,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 280
    }
  },
  buttonSkeleton: {
    width: '200px',
    marginTop: 20
  },
  logoSkeleton: {
    width: '300px'
  },
  rightContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))
