import React, { useState } from 'react'

import { useStyles } from './styles'
import { EmailForm, ResetPasswordForm } from './components'
import { FormHeader } from '../../../components/formHeader'
import { AuthenticationPagesWrapper, HelmetMetaData } from '../../../components'

export const ResetPassword = () => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')

  const handleStep = (value) => setStep(value)

  const handleChange = ({ target: { value } }) => setEmail(value)

  return (
    <>
      <HelmetMetaData
        title={'Mintrio - Reset Password'}
        description={'Password reset'}
        hashtag={'#reset #password'}
      />
      <AuthenticationPagesWrapper>
        <div className={classes.formContainer}>
          <FormHeader
            title={'Reset your password'}
            subTitle={
              step === 0
                ? 'Enter your email address and check for verification code'
                : 'Enter your verification code and new password'
            }
          />
          {step === 0 ? (
            <EmailForm handleStep={handleStep} handleChange={handleChange} email={email} />
          ) : (
            <ResetPasswordForm handleStep={handleStep} email={email} />
          )}
        </div>
      </AuthenticationPagesWrapper>
    </>
  )
}
