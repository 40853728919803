import React from 'react'
import { Typography, Link } from '@material-ui/core'

import { APP_STORE_LINK, FOOTER_TABS, PLAY_STORE_LINK } from '../../lib/constant'
import { useStyles } from './styles'
import { useHistory } from 'react-router'
import { StoreButton } from '../storeButton'
import { SocialIcons } from '../socialIcons'

export const Footer = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleNavigation = (path, newTab) => {
    if (newTab) window.open(path, '_blank')
    else history.push(path)
  }
  return (
    <div className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.footerTabs}>
          {FOOTER_TABS.map(({ name, path, newTab }) => (
            <Typography
              role="presentation"
              onClick={() => handleNavigation(path, newTab)}
              key={name}
              className={classes.button}>
              {name}
            </Typography>
          ))}
        </div>
        <Typography className={classes.copyrightText}>
          Copyright © 2022 MINTRIO. All rights reserved.
        </Typography>
        <div className={classes.storeButtonInnerContainer}>
          <div className={classes.storeButtonContainer}>
            <StoreButton width={130} height={40} store={'ios'} link={APP_STORE_LINK} />
          </div>
          <div className={classes.storeButtonContainer}>
            <StoreButton width={130} height={40} store={'android'} link={PLAY_STORE_LINK} />
          </div>
        </div>
        <Typography className={classes.brandingText}>
          Engineered with ❤️ at&nbsp;
          <Link target="_blank" href="https://levitatedata.com" className={classes.brandingLink}>
            levitate data
          </Link>
        </Typography>

        <SocialIcons footer={true} position="static" />
      </div>
    </div>
  )
}
