import React, { useState } from 'react'
import { InputBase, makeStyles, IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff, Search } from '@material-ui/icons'
import { isFirefox } from 'react-device-detect'

export function TextInput({
  endAdornment = null,
  type = 'text',
  error = null,
  name,
  placeholder,
  style,
  search = false,
  ...otherProps
}) {
  const [showPassword, setShowPassword] = useState(false)

  const classes = useStyles()

  return (
    <InputBase
      name={name}
      id={name}
      type={showPassword ? 'text' : type}
      placeholder={placeholder}
      variant="outlined"
      className={classes.input}
      style={{
        border: error ? '1px solid red' : '0',
        width: '100%',
        ...style,
        backgroundColor: isFirefox ? '#373535' : 'rgba(234,234,234,0.2)',
        backdropFilter: 'blur(30px)',
        WebkitBackdropFilter: 'blur(30px)'
      }}
      {...otherProps}
      endAdornment={
        type === 'password' ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((preValue) => !preValue)}>
              {showPassword ? (
                <Visibility className={classes.passwordIcon} />
              ) : (
                <VisibilityOff className={classes.passwordIcon} />
              )}
            </IconButton>
          </InputAdornment>
        ) : null
      }
      startAdornment={
        search ? (
          <InputAdornment position="start">
            <Search className={classes.searchIcon} />
          </InputAdornment>
        ) : null
      }
    />
  )
}
const useStyles = makeStyles({
  input: {
    width: '460px',
    marginTop: '0.5em',
    padding: '10px',
    borderRadius: '10px',
    '&::placeholder': {
      color: '#8e8e99'
    }
  },
  passwordIcon: {
    color: '#969696',
    height: 18,
    width: 18
  },
  searchIcon: {
    color: 'white',
    height: 20,
    width: 20
  }
})
