import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { Button } from '../../components/Button'
import { USER_ACTIVITY_ACTIONS } from '../../lib/constant'
import { addNewLog } from '../../redux/modules/userActivityLogs'
import { useStyles } from './styles'

export const PageNotFound = () => {
  const history = useHistory()

  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const navigateToHome = () => history.push('/')

  useEffect(() => {
    dispatch(addNewLog(USER_ACTIVITY_ACTIONS.PAGE_VISIT, { page: '404', path: location.pathname }))
  }, [dispatch, location])

  return (
    <div className={classes.pageNotFoundContainer}>
      <div className={classes.pageNotFoundError}>4 O 4</div>
      <div className={classes.pageNotFoundMainText}>There is nothing here...</div>
      <div className={classes.pageNotFoundSubText}>
        ...maybe the page you're looking for is not found or never existed.
      </div>

      <Button title="Go To Discover" className={classes.goToHome} hover onClick={navigateToHome} />
    </div>
  )
}
