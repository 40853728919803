/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listKnowledgeBaseCategories = /* GraphQL */ `
  query ListKnowledgeBaseCategories(
    $filter: ModelKnowledgeBaseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeBaseCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Knowledge_base {
          items {
            id
            question
            answer
            precedence
            category_id
            status
            created_by
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        title
        precedence
        status
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getOrdersByUser = /* GraphQL */ `
  query GetOrdersByUser(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrdersByUser(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ref_no
        payment_total
        payment_gateway_id
        user_id
        purchased_at
        is_gift
        video_plan_id
        video_id
        additional_attributes
        country_code
        country {
          id
          country_code
          currency
        }
        video_plan {
          id
          plan_id
          plan {
            id
            title
          }
        }
        payment_gateway {
          id
          name
        }
        video {
          id
          title
        }
      }
      nextToken
    }
  }
`
export const listDeviceTypes = /* GraphQL */ `
  query ListDeviceTypes($filter: ModelDeviceTypeFilterInput, $limit: Int, $nextToken: String) {
    listDeviceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
      }
      nextToken
    }
  }
`
export const listPricings = /* GraphQL */ `
  query ListPricings($filter: ModelPricingFilterInput, $limit: Int, $nextToken: String) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        country {
          id
          country_name
          currency
          currency_symbol
        }
        video_plan {
          id
          plan {
            id
            title
            is_ad_supported
            n_watches
            n_days_download_to_expire
            is_downloadable
            expire_max_mins_after_purchase
            expire_max_min_after_start
            device_types {
              items {
                id
                plan_id
                device_type_id
                additional_attributes
                createdAt
                updatedAt
                device_types {
                  id
                  title
                  status
                  createdAt
                  updatedAt
                }
                plans {
                  id
                  title
                  status
                  video_quality_id
                  additional_attributes
                  is_ad_supported
                  expire_max_mins_after_purchase
                  expire_max_min_after_start
                  createdAt
                  updatedAt
                }
              }
              nextToken
            }
            video_quality {
              vertical_resolution {
                title
              }
            }
          }
        }
        video {
          id
          title
        }
      }
      nextToken
    }
  }
`
export const listPaymentGateways = /* GraphQL */ `
  query ListPaymentGateways(
    $filter: ModelPaymentGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        disabled
        country_code
        additional_attributes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      sub_title
      main_title
      thumbnail_sm
      thumbnail_md
      thumbnail_lg
      duration
      intro_start_time
      intro_end_time
      short_description
      long_description
      banner_message
      status
      release_date
      credits_start_timestamp
      is_orignal
      language
      video_type_id
      additional_attributes
      createdAt
      updatedAt
      supported_captions {
        items {
          id
          language
          url
          status
          video_id
          is_orignal
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      audio_tracks {
        items {
          id
          language
          url
          status
          is_orignal
          video_id
          additional_attributes
          createdAt
          updatedAt
        }
        nextToken
      }
      cast_crew {
        items {
          id
          video_id
          cast_crew_id
          status
          cast_crew_type_id
          display
          additional_attributes
          createdAt
          updatedAt
          cast_crew_type {
            id
            title
            status
            additional_attributes
            createdAt
            updatedAt
          }
          cast_crew {
            id
            first_name
            last_name
            nick_name
            description
            image_sm
            image_md
            image_lg
            status
            additional_attributes
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      content_rating {
        items {
          id
          video_id
          content_rating_id
          createdAt
          updatedAt
          content_rating {
            id
            title
            status
            additional_attributes
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      trailers(filter: { status: { eq: true } }) {
        items {
          id
          hls_url
          status
          thumbnail_sm
          thumbnail_md
          thumbnail_lg
          title
          type
          is_main
          video_id
          additional_attributes
          createdAt
          updatedAt
          supported_captions {
            items {
              id
              language
              url
              status
              video_id
              is_orignal
              additional_attributes
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }

      genres {
        items {
          id
          genre_id
          video_id
          createdAt
          updatedAt
          genre {
            id
            title
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`

export const listVideosByStatus = /* GraphQL */ `
  query ListVideosByStatus(
    $statusValue: status!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosByStatus(
      statusValue: $statusValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        download_url
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message
        status
        statusValue
        release_date
        credits_start_timestamp
        is_orignal
        non_mp4_group_outputs_sync_status
        mp4_group_outputs_sync_status
        language
        video_type_id
        additional_attributes
        createdAt
        updatedAt
        supported_captions {
          items {
            id
            language
            url
            jsonUrl
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            status
            cast_crew_type_id
            display
            additional_attributes
            createdAt
            updatedAt
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            createdAt
            updatedAt
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        trailers(filter: { status: { eq: true } }) {
          items {
            id
            hls_url
            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            is_main
            video_id
            type
            additional_attributes
            createdAt
            updatedAt
            supported_captions {
              nextToken
            }
          }
          nextToken
        }
        genres {
          items {
            id
            genre_id
            video_id
            createdAt
            updatedAt
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const listVideos = /* GraphQL */ `
  query ListVideos($filter: ModelVideoFilterInput, $limit: Int, $nextToken: String) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sub_title
        main_title
        thumbnail_sm
        thumbnail_md
        thumbnail_lg
        duration
        intro_start_time
        intro_end_time
        short_description
        long_description
        banner_message
        status
        release_date
        credits_start_timestamp
        is_orignal
        language
        video_type_id
        additional_attributes
        createdAt
        updatedAt
        supported_captions {
          items {
            id
            language
            url
            status
            video_id
            is_orignal
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        audio_tracks {
          items {
            id
            language
            url
            status
            is_orignal
            video_id
            additional_attributes
            createdAt
            updatedAt
          }
          nextToken
        }
        cast_crew {
          items {
            id
            video_id
            cast_crew_id
            status
            cast_crew_type_id
            display
            additional_attributes
            createdAt
            updatedAt
            cast_crew_type {
              id
              title
              status
              additional_attributes
              createdAt
              updatedAt
            }
            cast_crew {
              id
              first_name
              last_name
              nick_name
              description
              image_sm
              image_md
              image_lg
              status
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        content_rating {
          items {
            id
            video_id
            content_rating_id
            createdAt
            updatedAt
            content_rating {
              id
              title
              status
              additional_attributes
              description
              createdAt
              updatedAt
            }
            video {
              id
              title
              sub_title
              main_title
              thumbnail_sm
              thumbnail_md
              thumbnail_lg
              duration
              intro_start_time
              intro_end_time
              short_description
              long_description
              banner_message
              status
              release_date
              credits_start_timestamp
              is_orignal
              language
              video_type_id
              additional_attributes
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        trailers {
          items {
            id
            hls_url
            status
            thumbnail_sm
            thumbnail_md
            thumbnail_lg
            title
            type
            is_main
            video_id
            additional_attributes
            createdAt
            updatedAt
            supported_captions {
              nextToken
            }
          }
          nextToken
        }
        genres {
          items {
            id
            genre_id
            video_id
            createdAt
            updatedAt
            genre {
              id
              title
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const getLandingPageData = /* GraphQL */ `
  query GetLandingPageData($device_name: String!) {
    getLandingPageData(device_name: $device_name) {
      device_name
      title {
        text
        font_size
        color
        background_color
        background_opacity
        background_blurriness
      }
      sub_title {
        text
        font_size
        color
        background_color
        background_opacity
        background_blurriness
      }
      banner {
        text
        font_size
        color
        background_color
        background_opacity
        background_blurriness
      }
      social_icons {
        bottom_offset
        color
        background_color
        background_opacity
        background_blurriness
        disabled
      }
      background {
        url
        type
      }
    }
  }
`
