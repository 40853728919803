import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IconButton, makeStyles, Typography } from '@material-ui/core'

export const HeaderUserMissingDetail = ({ title, closeModal, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.modalHeaderContainer}>
      <Typography>
        <h2>{title}</h2>
      </Typography>
      <div className={classes.backIconContainer}>
        {closeModal && (
          <IconButton size={'small'} onClick={closeModal}>
            <ArrowBackIcon className={classes.backIconColor} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  modalHeaderContainer: {
    position: 'relative',
    padding: '3% 0px 3% 0px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '900',
    fontSize: '40px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      padding: '50% 0px 7% 0px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12% 0 12px 0'
    }
  },
  backIconContainer: {
    position: 'absolute',
    top: '20%',
    left: '4%',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      top: '30%'
    }
  },
  backIconColor: {
    color: 'white'
  }
}))
