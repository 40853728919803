import axios from 'axios'
import amplifyConfig from '../aws-exports'

export const sendAxiosRequest = async (accessToken, payload) => {
  try {
    const { data } = await axios({
      url: amplifyConfig.aws_appsync_graphqlEndpoint,
      method: 'post',
      headers: {
        authorization: `${accessToken}`
      },
      data: payload
    })

    return data
  } catch (err) {
    throw err
  }
}
