import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { comingSoon } from '../../../assets'

export const ComingSoonBanner = () => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      className={classes.container}
      alignItems="center"
      justify="center"
      direction="column"
      style={{ height: '100vh', position: 'relative' }}>
      <img src={comingSoon} style={{ height: '120px' }} alt="" />
      <Typography color="secondary" className={classes.comingSoonText}>
        Coming Soon
      </Typography>
      <Typography align="right" className={classes.subtitle1}>
        More content is on its way!
      </Typography>
    </Grid>
  )
}
