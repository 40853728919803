import { makeStyles } from '@material-ui/core'

export const formHeaderStyles = makeStyles((theme) => ({
  h2: {
    fontSize: '2em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em'
    }
  },
  subtitle1: {
    fontSize: '0.7em',
    fontWeight: '300',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.5em',
      fontWeight: '200'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5em',
      fontWeight: '200'
    }
  },
  formHeaderContainer: {
    padding: '10px 0px'
  }
}))
