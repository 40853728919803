// errorHandlerUtility.js
import StackdriverErrorReporter from 'stackdriver-errors-js'

let errorHandler

if (process.env.REACT_APP_ENV !== 'development') {
  errorHandler = new StackdriverErrorReporter()
  errorHandler.start({
    key: process.env.REACT_APP_STACK_ERROR_API_KEY,
    projectId: process.env.REACT_APP_GCP_PROJECT
    // Other optional arguments can be supplied, see above.
  })
} else {
  errorHandler = { report: console.error }
}

export default errorHandler
